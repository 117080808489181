import randomstring from 'randomstring';
import axios from './api';


const updateProfile = async (profileData) => {
    const response = await axios.put(`${window._env_.REACT_APP_USERS_API}/sessions`, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        phoneNumber: profileData.phoneNumber,
        company: profileData.company,
        notes: profileData.notes,
    });

    return response.data;
};

const updatePassword = async (passwordData) => {
    const response = await axios.put(`${window._env_.REACT_APP_USERS_API}/sessions/password`, {
        newPassword1: passwordData.new_password,
        newPassword2: passwordData.new_password_confirmation,
        oldPassword: passwordData.current_password,
    });

    return response.data;
};

const listUsers = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_USERS_API}/users/user/active`);
    return response.data;
};
const listUsersByURL = async (url) => {
    const response = await axios.get(`https://user.${url}/users/user/active`);
    return response.data;
};

const getUser = async (userId) => {
    const response = await axios.get(`${window._env_.REACT_APP_USERS_API}/users/user/${userId}`);
    return response.data;
};

const getUserByURL = async (url, userId) => {
    const response = await axios.get(`https://user.${url}/users/user/${userId}`);
    return response.data;
};

const createUser = async (userData) => {
    const password = `${randomstring.generate({
        length: 8,
        charset: 'alphanumeric',
    })}Gm4$`;
    console.log(password);
    const response = await axios.post(`${window._env_.REACT_APP_USERS_API}/users/user`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        company: userData.company,
        password,
        jobId: userData.job,
    });

    return response.data;
};

const updateUser = async (userId, userData) => {
    const response = await axios.put(`${window._env_.REACT_APP_USERS_API}/users/user/${userId}`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        company: userData.company,
    });

    return response.data;
};

const updateUserJob = async (userId, jobId) => {
    const response = await axios.put(`${window._env_.REACT_APP_USERS_API}/users/user/${userId}/job`, { jobId });

    return response.data;
};

const deleteUser = async (userId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_USERS_API}/users/user/${userId}`);

    return response.data;
};

const updateCurrentPlanning = async (planningId, planningName, url = null) => {
    const response = await axios.put(`${url ? `https://user.${url}`: window._env_.REACT_APP_USERS_API}/program-infos/current-planning`, { planningId, planningName });

    return response.data;
};
const addPlanningToFavorite = async (planningId, planningName) => {
    const response = await axios.post(`${window._env_.REACT_APP_USERS_API}/program-infos/favorite-planning`, { planningId, planningName });

    return response.data;
};
const removePlannigFromfavorite = async (userId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_USERS_API}/program-infos/favorite-planning/${userId}`);

    return response.data;
};

const getSessionByURL = async (url) => {
    const response = await axios.get(`https://user.${url}/sessions`);
    return response.data;
};

const getProgramInfoByURL = async (url) => {
    const response = await axios.get(`https://user.${url}/program-infos`);
    return response.data;
};

const tryAccess = async (url) => {
    const response = await axios.get(`https://user.${url}/users/user/try-access`);
    return response.data;
};

const isFavoritePlanning = async (planningId) => {
    const response = await axios.get(`${window._env_.REACT_APP_USERS_API}/program-infos/${planningId}/is-favorite`);
    return response.data;
};



export default {
    updateProfile,
    updatePassword,
    listUsers,
    getUser,
    createUser,
    updateUser,
    updateUserJob,
    deleteUser,
    listUsersByURL,
    getUserByURL,
    updateCurrentPlanning, 
    getSessionByURL, 
    getProgramInfoByURL,
    addPlanningToFavorite, 
    removePlannigFromfavorite,
    isFavoritePlanning,
    tryAccess
};
