import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, Select } from 'antd';
import { generateString } from '../../helpers/string-helper';
import { TAB_TYPE } from '../../constants/Tabs';

const AddNewViewModal = forwardRef(({ onOk }, ref) => {
    const {t} = useTranslation('translation', {keyPrefix: 'modal_new_view'})
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t;
    const [isVisible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const viewChoices = [
        {
            label: 'Gantt',
            value: TAB_TYPE.GANTT,
        },
        {
            label: t(TAB_TYPE.NEEDS_HISTOGRAM),
            value: TAB_TYPE.RESOURCE,
        },
        {
            label: 'Chemin de fer',
            value: TAB_TYPE.RAILWAY,
        },
        // {
        //     label: 'Calendrier ressources',
        //     value: TAB_TYPE.CALENDAR,
        // },
    ];

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const onFinish = (values) => {
        if (onOk) {
            const newView = { ...values, key: generateString(5) };
            onOk(newView);
            onCancel();
        }
    };

    return (
        <Modal
            title={t('add_new_view')}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item name="type" label={t('view_type')} rules={[{ required: true, message: t('view_type_required') }]}>
                    <Select showSearch options={viewChoices}/>
                </Form.Item>
                <Form.Item name="name" label={t('view_name')} rules={[{ required: true, message: t('view_name_required') }]}>
                    <Input type="text"  />
                </Form.Item>

                <Form.Item className="mb-0 text-right">
                    <Button type="primary" htmlType="submit">
                        {generalTranslation('add')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default AddNewViewModal;
