import React, { createContext, useEffect, useState, useContext, useCallback, useMemo } from 'react';
import moment from 'moment';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import resourceJSON from '../../mocks/resources/resources.json';
import { mergeNeedSlices, sliceJoinedSimple } from 'slicing-gm';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { PlanningContext } from '../app/PlanningContext';
import { NeedsContext } from '../needs/NeedsContext';
import { PlanningNeedsContext } from '../needs/PlanningNeedsContext';
import {
    NEED_CHART_DATA,
    NEED_CHART_TYPE,
    NEED_TYPE,
    NEED_HISTOGRAM_TYPE,
    SLICE_DATA_TYPE,
} from '../../constants/Needs';
import { xAxisDateFormat } from '../../components/charts/CustomHistogramDateXaxis';
import { SliceContext } from '../slicing/SliceContext';
import {
    generateActivityTemporalNeedSliceFromActivityCalendarSlice,
    generatorTimestamp,
    timer,
} from '../../helpers/slicing-helper';
import { dateToTimestamp } from '../../helpers/planning';
import { restoreScrollState } from '../../components/plannings/gantt_events';
import { TabContext } from '../app/TabContext';
// import { TabContext } from '../app/TabContext';

const DEFAULT_CHART_OPTIONS = {
    chartType: NEED_CHART_TYPE.BAR,
    histogramType: NEED_HISTOGRAM_TYPE.STACKED,
    needType: NEED_TYPE.NON_CONSUMMABLE,
    dataType: NEED_CHART_DATA.INSTANTANEOUS,
    sliceDataType: SLICE_DATA_TYPE.QUANTITY,
    isGanttActivityFiltered: true,
    effortTimeUnitId: -9,
    shownNeedsPanel: ['global', 'planning']
};

moment.defineLocale(window.localStorage.getItem('lng') || 'fr', {
    week: {
        dow: 1, // First day of week is Monday
        doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
    },
});
// import { requestError } from '../../helpers/notification';

const NeedChartContext = createContext();

const NeedChartContextProvider = ({ children }) => {
    // const {t} = useTranslation('translation', {keyPrefix: 'resource_context'})
    const [chartData, setChartData] = useState([]);
    // const {activeTab} = useContext(TabContext)

    // const isHistogramTab = useMemo(() => activeTab?.tabType === TAB_TYPE.RESOURCE, [activeTab]);

    const [needList, setNeedList] = useState([]);
    const [chartRef, setChartRef] = useState(null);
    const [shouldUpdateChartData, setShouldUpdateChartData] = useState(false);
    // const [configurations, setConfigurations] = useState({});
    const { updateTabPreferences, tabPreferences } = useContext(TabContext);
    const [chartOptions, setChartOptions] = useState(null);
    const {
        activitiesDictionary,
        processingGantt,
        timeUnits,
        setGanttHistogramChartNeedList,
        planningSelected,
    } = useContext(PlanningContext);
    const [activityCalendarTemporalNeedSliceDictionary, setActivityCalendarTemporalNeedSliceDictionary] =
        useState(null);
    const { activityCalendarSliceDictionary, scale } = useContext(SliceContext);
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;

    const globalNonConsummableNeeds = useMemo(
        () => getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.NON_CONSUMMABLE),
        [globalNeedList]
    );
    const planningNonConsummableNeeds = useMemo(
        () => getArrayInListByCondition(planningNeedList, 'needType', NEED_TYPE.NON_CONSUMMABLE),
        [planningNeedList]
    );
    const globalConsummableNeeds = useMemo(
        () => getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
        [globalNeedList]
    );
    const planningConsummableNeeds = useMemo(
        () => getArrayInListByCondition(planningNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
        [planningNeedList]
    );

    /**
     * Find time Unit of Day Definition
     * @param {*} dayDefinitionId
     * @returns Duration by Unit
     */
    const durationUnit = (dayDefinitionId) => timeUnits.find((timeUnit) => timeUnit.id === Number(dayDefinitionId));

    useEffect(() => {
        if (tabPreferences && tabPreferences?.gantt_histogram_options) {
            console.log(
                '🚀 ~ file: ChartContext.jsx:88 ~ useEffect ~ tabPreferences?.gantt_histogram_options:',
                JSON.parse(JSON.stringify(tabPreferences?.gantt_histogram_options))
            );
            setChartOptions({...DEFAULT_CHART_OPTIONS, ...tabPreferences.gantt_histogram_options});
        } else {
            setNeedList([]);
            setChartOptions(DEFAULT_CHART_OPTIONS);
        }
    }, []);

    useEffect(() => {
        if (chartOptions && tabPreferences) {
            setShouldUpdateChartData(true);
            const needs = (
                chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE
                    ? [...globalNonConsummableNeeds, ...planningNonConsummableNeeds]
                    : [...globalConsummableNeeds, ...planningConsummableNeeds]
            ).map((need) => ({
                ...need,
                selected: tabPreferences.gantt_histogram_needs?.[chartOptions?.needType]
                    ? tabPreferences.gantt_histogram_needs[chartOptions?.needType].indexOf(need.id) !== -1
                    : true,
            }));
            // const configurationsObj = {};
            // nonConsummableNeeds.forEach((need) => {
            //     configurationsObj[need.name] = 1;
            // });
            // setConfigurations(configurationsObj);
            setNeedList(needs);
        }
    }, [
        chartOptions?.needType,
        globalConsummableNeeds,
        globalNonConsummableNeeds,
        planningConsummableNeeds,
        planningNonConsummableNeeds,
    ]);

    // used for printing histogram
    useEffect(() => setGanttHistogramChartNeedList(needList), [needList]);

    const computeActivityCalendarTemporalSliceDictionary = useCallback(
        (timescale) => {
            console.log('STEP 3', activityCalendarSliceDictionary);
            const activityCalendarTemporalNeedSlices = {};
            const temporalityUnit = xAxisDateFormat[timescale].mainScaleUnit;
            const activitiesDictionaryValues = Object.values(activitiesDictionary);
            if (activitiesDictionaryValues.length > 0) {
                activitiesDictionaryValues.forEach((activity) => {
                    if (activityCalendarSliceDictionary[activity.id]?.length > 0) {
                        const currentActivityCalendarTemporalNeedSlices =
                            generateActivityTemporalNeedSliceFromActivityCalendarSlice(
                                activityCalendarSliceDictionary[activity.id],
                                temporalityUnit,
                                activity,
                                durationUnit,
                                chartOptions
                            );

                        activityCalendarTemporalNeedSlices[activity.id] = currentActivityCalendarTemporalNeedSlices;
                    }
                });
                setActivityCalendarTemporalNeedSliceDictionary(activityCalendarTemporalNeedSlices);
            }
        },
        [activitiesDictionary, activityCalendarSliceDictionary, chartOptions]
    );

    const computeChartData = useCallback(() => {
        if (
            activityCalendarTemporalNeedSliceDictionary &&
            Object.values(activityCalendarTemporalNeedSliceDictionary).length > 0
        ) {
            console.log('STEP 4. COMPUTING CHART DATA');
            // Filtrer selon les activités du Gantt
            const activityCalendarTemporalNeedSlices = { ...activityCalendarTemporalNeedSliceDictionary };
            if (chartOptions.isGanttActivityFiltered && window.ganttInstance) {
                Object.keys(activityCalendarTemporalNeedSliceDictionary || {}).forEach((i) => {
                    const ganttTask = window.ganttInstance.getTaskByServerId(+i);
                    if (!ganttTask) {
                        delete activityCalendarTemporalNeedSlices[i];
                    }
                });
            }
            // Ordonne les activity-calendar-temporal-need-slice par leur id de besoin et leur date de debut.
            const mapNeedStart = {};
            timer('creation de la map :', () =>
                Object.values(activityCalendarTemporalNeedSlices).forEach((temporalNeedSlices) => {
                    temporalNeedSlices.forEach((tns) => {
                        const { needId } = tns.extraData;
                        mapNeedStart[needId] = mapNeedStart[needId] ?? {};
                        mapNeedStart[needId][tns.start] = mapNeedStart[needId][tns.start] ?? [];
                        mapNeedStart[needId][tns.start].push(tns);
                    });
                })
            );

            const timeUnit = xAxisDateFormat[scale].mainScaleUnit;
            // TODO change with filtering
            const rootActivity = activitiesDictionary[planningSelected.rootActivityId];
            const startTime = window.ganttInstance
                ? dateToTimestamp(window.ganttInstance.getState().min_date)
                : rootActivity.startDate;
            const endTime = window.ganttInstance
                ? dateToTimestamp(
                      window.ganttInstance.date.add(
                          window.ganttInstance.getState().max_date,
                          0,
                          timeUnit.replace('s', '')
                      )
                  )
                : moment(rootActivity.endDate).utc().add(2, timeUnit);

            const [dates] = timer('config date type 1', () => generatorTimestamp(startTime, endTime, timeUnit));
            console.log('🚀 ~ file: ChartContext.jsx:174 ~ NeedChartContextProvider ~ dates:', dates);

            return timer('histogramChartDataNeedStartMap', () => {
                const result = dates.map((date) => {
                    const timeData = { date };
                    // Recuperation et suppression des slices correspondant a la date
                    //  du tableau des allocatedNeedSlice => O(n)
                    needList.forEach((need) => {
                        try {
                            timeData[need.name] = !mapNeedStart[need.id]?.[date]
                            ? 0
                            : sliceJoinedSimple(mapNeedStart[need.id][date], mergeNeedSlices)[0]?.quantity;
                            
                        } catch (error) {
                            console.log("🚀 ~ needList.forEach ~ error:", error)
                            
                        }
                    });
                    return timeData; 
                })
                return result;
            }
            )[0];
        }
        return [];
    }, [scale, activityCalendarTemporalNeedSliceDictionary, needList, chartOptions]);

    useEffect(() => {
        if (activityCalendarSliceDictionary && scale && processingGantt === false) {
            timer('computeActivityCalendarTemporalSliceDictionary', () => {
                computeActivityCalendarTemporalSliceDictionary(scale);
            });
        }
    }, [activityCalendarSliceDictionary, processingGantt, chartOptions?.sliceDataType, chartOptions?.effortTimeUnitId]);



    const autoScroll = useCallback(() => {
        if (tabPreferences) {
            restoreScrollState(tabPreferences);
        }
    }, [tabPreferences]);

    useEffect(() => {
        if (activityCalendarTemporalNeedSliceDictionary && shouldUpdateChartData && chartOptions) {
            console.log('COMPUTE 1');

            let charData;
            timer('computeChartData', () => {
                charData = computeChartData();
                console.log('🚀 ~ file: ChartContext.jsx:211 ~ timer ~ charData:', charData);
            });
            timer('setChartData', () => {
                setChartData(charData);
                console.log('AUTO SCROLLING CHART');
                autoScroll();
            });
        }
    }, [
        activityCalendarTemporalNeedSliceDictionary,
        needList,
        shouldUpdateChartData,
        chartOptions?.isGanttActivityFiltered,
    ]);

    useEffect(() => {
        if (chartOptions) {
            console.log('🚀 ~ file: ChartContext.jsx:244 ~ useEffect ~ chartOptions:', chartOptions);
            updateTabPreferences({
                gantt_histogram_options: chartOptions,
            });
        }
    }, [chartOptions]);

    useEffect(() => {
        if (needList.length > 0) {
            console.log('pref, need list');
            const selectedNeeds = needList.filter((i) => i.selected).map((i) => i.id);
            if (selectedNeeds.length > 0) {
                updateTabPreferences({
                    gantt_histogram_needs: {
                        ...tabPreferences.gantt_histogram_needs,
                        [chartOptions.needType]: selectedNeeds,
                    },
                });
            }
        }
    }, [needList]);

    return (
        <NeedChartContext.Provider
            value={{
                chartData,
                scale,
                needList,
                setNeedList,
                chartRef,
                setChartRef,
                setChartOptions,
                chartOptions,
                durationUnit, 
            }}
        >
            {children}
        </NeedChartContext.Provider>
    );
};

NeedChartContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { NeedChartContext, NeedChartContextProvider };
