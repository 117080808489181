import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, DatePicker, Select, Spin } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import collapseAllImg from '../../assets/icons/gantt/collapse-all.png';
import expandAllImg from '../../assets/icons/gantt/expand-all.png';
import ColumnDisplayFilter from './ColumnDisplayFilter';
import fullscreenImg from '../../assets/icons/gantt/fullscreen.png';
import zoomToFitImg from '../../assets/icons/gantt/zoom-to-fit.png';
import zoomOut from '../../assets/icons/gantt/zoom-out.png';
import zoomIn from '../../assets/icons/gantt/zoom-in.png';
import resetSort from '../../assets/icons/gantt/reset-sort.png';
import GanttConfigurations from './gantt_config';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { TabContext } from '../../contexts/app/TabContext';
import { zoomGanttToFit } from '../../helpers/zoom';
// import { notificationError } from '../../helpers/notification';
import { TAB_TYPE } from '../../constants/Tabs';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { scaleConfigs, GANTT_MIN_COLUMN_WIDTH } = GanttConfigurations;
// const dateFormat = 'DD/MM/YYYY HH:mm';

const TASK_ZOOM_RATIO = 0.1;

const Wrapper = styled.div`
    border: 1px solid #cecece;

    .gantt-controls {
        background-color: #f5f5f5;
        font-size: 14px;
        list-style-type: none;
        margin: 0;
        overflow: visible;
        padding: 0;
        text-align: left;
        user-select: none;
    }

    .gantt-menu-item {
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        overflow: visible;
        padding: 0;
    }

    .gantt-menu-item-last {
        border-right: 1px solid #cecece;
        vertical-align: middle;
    }

    .gantt-menu-item-right {
        float: right;
    }

    .gantt-menu-item button {
        display: block;
        cursor: pointer;
        color: #3d3d3d;
        text-align: center;
        line-height: 40px;
        padding: 0 10px;
        text-decoration: none;
    }

    .gantt-menu-item button:hover {
        background-color: #e2e2e2;
    }

    .gantt-menu-item img {
        display: inline;
        vertical-align: middle;
    }

    .gantt-menu-item .menu-item-active {
        background-color: #dadada !important;
    }

    .ant-btn {
        line-height: unset !important;
    }

    .ant-picker-input > input {
        text-align: center;
    }
`;

const GanttToolbar = ({ isGantt }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'gantt_toolbar' });
    // const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t
    const scaleTranslation = useTranslation('translation', { keyPrefix: 'scales' }).t;
    const scaleOptions = scaleConfigs.map((i) => {
        const option = { label: scaleTranslation(i.name), value: i.name };
        return option;
    });
    const [zoomToFitEnabled, setZoomToFitEnabled] = useState(false);
    const [cachedSettings, setCachedSettings] = useState({});
    const {
        refreshPlanningSelected,
        processingGantt,
        setReRenderGantt,
        fetchCalendars,
        filteredFlattenActivities,
        activitiesDictionary,
    } = useContext(PlanningContext);
    const {
        tabPreferences,
        updateTabPreferences,
        activeTab,
        enableDateFilter,
        disableDateFilter,
        activeTabFilterData,
    } = useContext(TabContext);
    const [isDateFiltered, setIsDateFiltered] = useState(false);
    const [planningStartDate, setPlanningStartDate] = useState(null);
    const [planningEndDate, setPlanningEndDate] = useState(null);
    const [planningDateRange, setPlanningDateRange] = useState([]);
    const [currentSelectedScaleIndex, setCurrentSelectedScaleIndex] = useState(1);
    const [loadingToolbar, setLoadingToolbar] = useState(false);
    const showHistogram =
        activeTab?.tabType === TAB_TYPE.GANTT
            ? tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
            : activeTab?.tabType === TAB_TYPE.RESOURCE;

    const loadFilter = () => {
        setLoadingToolbar(true);
        setIsDateFiltered(false);
        const isFiltered = activeTabFilterData.dateFilter;

        if (isFiltered && window.ganttInstance) {
            setIsDateFiltered(true);
            // setting default values for begin and end Dates
            const fin = isFiltered.rules[0];
            const debut = isFiltered.rules[1];
            if (debut && fin) {
                window.ganttInstance.config.start_date = new Date(debut.value);
                setPlanningStartDate(moment(debut.value));
                window.ganttInstance.config.end_date = new Date(fin.value);
                setPlanningEndDate(moment(fin.value));
                setPlanningDateRange([moment(debut.value), moment(fin.value)]);
                window.ganttInstance.config.start_date = debut.value;
                window.ganttInstance.config.end_date = fin.value;
            }
            window.ganttInstance.render();
        }
        setLoadingToolbar(false);
    };

    const allActivitiesCount = useMemo(() => Object.keys(activitiesDictionary || {}).length, [activitiesDictionary]);
    const filteredActivitiesCount = useMemo(
        () => (filteredFlattenActivities || []).filter((i) => !i.virtual).length,
        [filteredFlattenActivities]
    );

    useEffect(() => {
        if (processingGantt === false) {
            loadFilter();
            if (tabPreferences.gantt_scales) {
                const index = scaleOptions.findIndex((i) => i.value === tabPreferences.gantt_scales);
                setCurrentSelectedScaleIndex(index);
            }
        }
    }, [processingGantt]);

    const zoomStep = 50;

    const onCollapseAll = () => {
        if (!window.ganttInstance) {
            return;
        }

        window.ganttInstance.eachTask((task) => {
            // eslint-disable-next-line no-param-reassign
            task.$open = false;
        });

        window.ganttInstance.render();
    };

    const onExpandAll = () => {
        if (!window.ganttInstance) {
            return;
        }

        window.ganttInstance.eachTask((task) => {
            // eslint-disable-next-line no-param-reassign
            task.$open = true;
        });

        window.ganttInstance.render();
    };

    const onFullscreen = () => {
        if (!window.ganttInstance) {
            return;
        }

        window.ganttInstance.ext.fullscreen.toggle();
    };
    // let scaleZoomCount = 0;
    const onZoomOut = async () => {
        const parentScaleContainer = document.querySelector('.timeline_cell');
        const scaleContainer = document.querySelector('.gantt_task_scale');
        if (
            (parentScaleContainer.style.width === scaleContainer.style.width ||
                window.ganttInstance.config.min_column_width === 25) &&
            !showHistogram
        ) {
            // si plus de scroll, ajouter des dates au début et fin
            if (!isDateFiltered && planningDateRange === null) {
                setLoadingToolbar(true);
                const currentGanttState = window.ganttInstance.getState();
                const scaleDateCount = moment(currentGanttState.max_date).diff(
                    moment(currentGanttState.min_date),
                    'days'
                );
                const newMinDate = moment(currentGanttState.min_date).subtract(
                    Math.max(Math.trunc(scaleDateCount * TASK_ZOOM_RATIO), 3),
                    'days'
                );
                const newMaxDate = moment(currentGanttState.max_date).add(
                    Math.max(Math.trunc(scaleDateCount * TASK_ZOOM_RATIO), 3),
                    'days'
                );
                window.ganttInstance.config.start_date = newMinDate.valueOf();
                window.ganttInstance.config.end_date = newMaxDate.valueOf();
                window.ganttInstance.render();
                await fetchCalendars(newMinDate.valueOf(), newMaxDate.valueOf());
                setReRenderGantt((val) => val + 1);
                setLoadingToolbar(false);
            }
        }
        const targetWidth = window.ganttInstance.config.min_column_width - zoomStep;
        // block zoom out when less than 40px
        if (targetWidth < GANTT_MIN_COLUMN_WIDTH) {
            window.ganttInstance.config.min_column_width = 25;
            // return;
        } else {
            window.ganttInstance.config.min_column_width -= zoomStep;
        }

        window.ganttInstance.render();
    };

    const onZoomIn = () => {
        const scaleCellElements = document.getElementsByClassName('gantt_scale_cell');
        const scaleCellElement = scaleCellElements[scaleCellElements.length - 1];
        if (scaleCellElement) {
            const scaleCellWidth = +scaleCellElement.style.width.replace('px', '');
            if (scaleCellWidth > GANTT_MIN_COLUMN_WIDTH) {
                window.ganttInstance.config.min_column_width = scaleCellWidth;
            }
        }
        if (window.ganttInstance.config.min_column_width === 25) {
            window.ganttInstance.config.min_column_width = GANTT_MIN_COLUMN_WIDTH;
            // return;
        } else {
            window.ganttInstance.config.min_column_width += zoomStep;
        }
        window.ganttInstance.render();
    };

    const saveConfig = () => {
        const { config } = window.ganttInstance;
        setCachedSettings({
            scales: config.scales,
            scaleName: config.scaleName,
            template: window.ganttInstance.templates.date_scale,
            start_date: config.start_date,
            end_date: config.end_date,
            scaleIndex: currentSelectedScaleIndex,
        });
    };

    const enableZoomToFit = () => {
        setZoomToFitEnabled(true);
        saveConfig();
        const zoomInfo = zoomGanttToFit();
        const scaleName = scaleConfigs[zoomInfo.scaleLevel].name;
        if (scaleName && zoomInfo.hasChanged) {
            updateTabPreferences(
                {
                    gantt_scales: scaleName,
                },
                false
            );
            setCurrentSelectedScaleIndex(zoomInfo.scaleLevel);
        }
    };

    const disableZoomToFit = () => {
        setZoomToFitEnabled(false);
        window.ganttInstance.config.scales = cachedSettings.scales;
        window.ganttInstance.config.scaleName = cachedSettings.scaleName;
        window.ganttInstance.template = cachedSettings.template;
        window.ganttInstance.config.start_date = cachedSettings.start_date;
        window.ganttInstance.config.end_date = cachedSettings.end_date;
        const scaleName = scaleConfigs[cachedSettings.scaleIndex].name;
        if (scaleName) {
            updateTabPreferences(
                {
                    gantt_scales: scaleName,
                },
                false
            );
        }
        setCurrentSelectedScaleIndex(cachedSettings.scaleIndex);
        window.ganttInstance.render();
    };

    const onZoomToFit = () => {
        if (!window.ganttInstance) {
            return;
        }

        if (zoomToFitEnabled) {
            disableZoomToFit();
        } else {
            enableZoomToFit();
        }
    };

    const onResetSort = () => {
        updateTabPreferences({ planning_sort: {} });
        window.ganttInstance._sort = undefined;

        refreshPlanningSelected();
    };

    const handleFilter = async (filtered, range = null) => {
        setLoadingToolbar(true);
        if (!filtered && range) {
            console.log('disable filter entre', filtered, range);
            await disableDateFilter(true);
        }
        if (range && range[0] && range[1] && filtered) {
            // await disableDateFilter(false);
            console.log('enable filter entre', filtered, range);
            const start = range[0].startOf('minute').valueOf();
            const end = range[1].startOf('minute').valueOf();
            await enableDateFilter(start, end);
        }
        setLoadingToolbar(false);
    };

    // handling if date filter shoud be applied
    const handleDateFilter = () => {
        setIsDateFiltered(!isDateFiltered);
        handleFilter(!isDateFiltered, planningDateRange);
    };

    // fire when planning endDate changed
    const handleChangePlanningDateRange = (range) => {
        if (range && range[0] && range[1]) {
            if (range[0] !== planningStartDate) {
                setPlanningStartDate(range[0]);
            }
            if (range[1] !== planningEndDate) {
                setPlanningEndDate(range[1]);
            }
            setPlanningDateRange(range);
            handleFilter(isDateFiltered, range);
        }
    };

    const handleChangeScale = (e) => {
        const scale = scaleConfigs.find((i) => i.name === e);
        if (scale) {
            updateTabPreferences({
                gantt_scales: e,
            });
            const index = scaleOptions.findIndex((i) => i.value === e);
            setCurrentSelectedScaleIndex(index);
            window.ganttInstance.config.min_column_width = GANTT_MIN_COLUMN_WIDTH;
            window.ganttInstance.config.scales = scale.scales;
            window.ganttInstance.config.scaleName = scale.name;
            window.ganttInstance.render();
        }
    };

    return (
        <Spin spinning={loadingToolbar} size="large">
            <Wrapper>
                <ul className="gantt-controls">
                    <li className="gantt-menu-item">
                        <button type="button" onClick={onCollapseAll}>
                            <img src={collapseAllImg} alt="Tout replier" /> {t('collapse_all')}
                        </button>
                    </li>
                    <li className="gantt-menu-item">
                        <button type="button" onClick={onExpandAll}>
                            <img src={expandAllImg} alt="Tout déplier" />
                            {t('uncollapse_all')}
                        </button>
                    </li>
                    {isGantt && (
                        <li className="gantt-menu-item gantt-menu-item-last">
                            <button type="button">
                                <ColumnDisplayFilter />
                            </button>
                        </li>
                    )}
                    <li className="gantt-menu-item gantt-menu-item-last">
                        <button type="button" onClick={onResetSort}>
                            <img src={resetSort} alt="Réinitialiser le tri" /> {t('reset_sort')}
                        </button>
                    </li>
                    <li className="gantt-menu-item ml-3">
                        <Button
                            type={isDateFiltered ? 'primary' : undefined}
                            ghost={!isDateFiltered}
                            onClick={handleDateFilter}
                        >
                            {t('between')}
                        </Button>
                    </li>
                    <li className="gantt-menu-item ml-3">
                        <RangePicker
                            format={window.dateStringFormat.concat(' HH:mm')}
                            value={planningDateRange}
                            showTime={{
                                format: 'HH:mm',
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                            }}
                            onOk={handleChangePlanningDateRange}
                            disabled={!isDateFiltered}
                            allowClear={false}
                            separator={<ArrowRightOutlined />}
                        />
                    </li>
                    <li
                        className={`gantt-menu-item ml-3 ${
                            allActivitiesCount !== filteredActivitiesCount ? 'bg-primary' : ''
                        }`}
                    >
                        <span className="p-1">
                        {t('gantt_activities')}:{' '}
                            {allActivitiesCount === filteredActivitiesCount
                                ? allActivitiesCount
                                : `${filteredActivitiesCount} / ${allActivitiesCount}`}
                        </span>
                    </li>
                    <li className="gantt-menu-item gantt-menu-item-right">
                        <button type="button" onClick={onFullscreen}>
                            <img src={fullscreenImg} alt="Plein écran" />
                        </button>
                    </li>
                    <li className="gantt-menu-item gantt-menu-item-right gantt-menu-item-last">
                        <button
                            type="button"
                            onClick={onZoomToFit}
                            className={zoomToFitEnabled ? 'menu-item-active' : null}
                        >
                            <img src={zoomToFitImg} alt="Adapter à la vue" /> {t('fit_view')}
                        </button>
                    </li>
                    <li className="gantt-menu-item gantt-menu-item-right">
                        <button type="button" onClick={onZoomOut} data-action="zoomOut" disabled={zoomToFitEnabled}>
                            <img src={zoomOut} alt="Diminuer" />
                        </button>
                    </li>
                    <li className="gantt-menu-item gantt-menu-item-right">
                        <button type="button" onClick={onZoomIn} data-action="zoomIn" disabled={zoomToFitEnabled}>
                            <img src={zoomIn} alt="Agrandir" />
                        </button>
                    </li>
                    <li className="gantt-menu-item gantt-menu-item-right mt-1">
                        <Select
                            value={scaleOptions[currentSelectedScaleIndex].label}
                            style={{ width: 100 }}
                            onChange={handleChangeScale}
                            disabled={zoomToFitEnabled}
                        >
                            {scaleOptions.map((scale) => (
                                <Option value={scale.value} key={`scale-confg-${scale.value}`}>
                                    {scale.label}
                                </Option>
                            ))}
                        </Select>
                    </li>
                </ul>
            </Wrapper>
        </Spin>
    );
};

export default GanttToolbar;
