import moment from 'moment';
import axios from './api';
import WalletService from './wallet.service';

const login = async (email, password) => {
    const response = await axios.post(`${window._env_.REACT_APP_WALLET_API}/sessions`, { email, password });
    if (response.data.access_token) {
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        localStorage.setItem('expires_date', moment().add(response.data.expires_in, 'seconds').valueOf());
        localStorage.setItem(
            'refresh_expires_date',
            moment().add(response.data.refresh_expires_in, 'seconds').valueOf()
        );
    }

    return response.data;
};

const getSession = async () => {
    if (process.env.REACT_APP_FAKE_LOGIN === '0') {
        const programInfo = JSON.parse(localStorage.getItem('prg'));
        let response;
        // if in Program add additionnal info URL for session changed
        try {
            if (programInfo) {
                const { url } = programInfo;
                window._env_ = {
                    ...window._env_,
                    REACT_APP_USERS_API:
                        window._env_.REACT_APP_USERS_API && window._env_.REACT_APP_FORCE_URL_DEV_MODE
                            ? window._env_.REACT_APP_USERS_API
                            : `https://user.${url}`,
                    REACT_APP_TEAMS_API:
                        window._env_.REACT_APP_TEAMS_API && window._env_.REACT_APP_FORCE_URL_DEV_MODE
                            ? window._env_.REACT_APP_TEAMS_API
                            : `https://team.${url}`,
                    REACT_APP_CALENDAR_API:
                        window._env_.REACT_APP_CALENDAR_API && window._env_.REACT_APP_FORCE_URL_DEV_MODE
                            ? window._env_.REACT_APP_CALENDAR_API
                            : `https://calendar.${url}`,
                    REACT_APP_FOLDER_API:
                        window._env_.REACT_APP_FOLDER_API && window._env_.REACT_APP_FORCE_URL_DEV_MODE
                            ? window._env_.REACT_APP_FOLDER_API
                            : `https://folder.${url}`,
                    REACT_APP_ACTIVITY_API:
                        window._env_.REACT_APP_ACTIVITY_API && window._env_.REACT_APP_FORCE_URL_DEV_MODE
                            ? window._env_.REACT_APP_ACTIVITY_API
                            : `https://activity.${url}`,
                    REACT_APP_TEMPLATE_API:
                        window._env_.REACT_APP_TEMPLATE_API && window._env_.REACT_APP_FORCE_URL_DEV_MODE
                            ? window._env_.REACT_APP_TEMPLATE_API
                            : `https://template.${url}`,
                };
                response = await axios.get(`${window._env_.REACT_APP_WALLET_API}/sessions/profile`);
                const user = response.data;
                const isAdmin = await axios.get(`${window._env_.REACT_APP_TEAMS_API}/teams/team/is-admin`);
                localStorage.setItem('user', JSON.stringify(Object.assign(user, { isAdmin: isAdmin.data })));
            } else {
                response = await axios.get(`${window._env_.REACT_APP_WALLET_API}/sessions/profile`);
                const isPlAdmin = await WalletService.isPlAdmin();
                const user = { ...response.data, isPlAdmin: isPlAdmin.isPLAdmin };
                localStorage.setItem('user', JSON.stringify(Object.assign(user, {})));
            }
            return response.data;
        } catch (e) {
            console.log('err', e)
        }
    }

    const fakeData = {
        firstName: 'John',
        lastName: 'Doe',
        email: 'john@mail.com',
        phoneNumber: '060000000',
        company: 'ACME',
    };
    localStorage.setItem('user', JSON.stringify(fakeData));
    return fakeData;
};

const forgottenPassword = async (email) => {
    const response = await axios.post(`${window._env_.REACT_APP_WALLET_API}/sessions/password/forgotten`, { email });

    return response.data;
};

const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expires_date');
    localStorage.removeItem('refresh_expires_date');
    localStorage.removeItem('prg');
    localStorage.removeItem('user');
};

const getCurrentUser = () => {
    const userData = localStorage.getItem('user');

    return userData ? JSON.parse(userData) : null;
};

const getAccessToken = () => localStorage.getItem('accessToken');

// Tab Preferences

const getPreferences = async (url = null) => {
    const response = await axios.get(
        `${url ? `https://user.${url}` : window._env_.REACT_APP_USERS_API}/tab-preferences/preferences`
    );

    return response.data;
};

const updateUserPreferences = async (userPreferences, url = null) => {
    const response = await axios.put(
        `${url ? `https://user.${url}` : window._env_.REACT_APP_USERS_API}/tab-preferences/preferences`,
        userPreferences
    );

    return response.data;
};

const getPlanningTabs = async (planningId) => {
    const response = await axios.get(`${window._env_.REACT_APP_USERS_API}/tab-preferences/by-planning/${planningId}`);

    return response.data;
};
const createPlanningTab = async (planningId, tabType, name) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_USERS_API}/tab-preferences/${planningId}/new-tab-preference`,
        { tabType, name }
    );

    return response.data;
};
const getPlanningTab = async (tabPreferenceId) => {
    const response = await axios.get(`${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}`);

    return response.data;
};
const updatePlanningTab = async (tabPreferenceId, data) => {
    const response = await axios.put(`${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}/update`, {
        ...data,
    });

    return response.data;
};
const updatePlanningTabWithPreferences = async (tabPreferenceId, preferences, tabData = {}) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}/edit-tab-pref-blob`,
        { tabData, preferences }
    );

    return response.data;
};
const updatePlanningTabFilterData = async (tabPreferenceId, filters) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}/edit-tab-pref-blob`,
        { filters }
    );

    return response.data;
};

const deletePlanningTab = async (tabPreferenceId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}`);

    return response.data;
};

const duplicatePlanningTab = async (tabPreferenceId, name) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}/duplicate`,
        { name }
    );

    return response.data;
};

const sharePlanningTab = async (tabPreferenceId, teamId) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}/share-tab-preference/${teamId}`,
        {}
    );

    return response.data;
};

const getSharedPlanningTabs = async (planningId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_USERS_API}/tab-preferences/shared-prefs-by-planning/${planningId}`
    );

    return response.data;
};

const acceptSharedPlanningTab = async (tabPreferenceId, value) => {
    const response = await axios.put(`${window._env_.REACT_APP_USERS_API}/tab-preferences/${tabPreferenceId}/accept`, {
        value,
    });

    return response.data;
};

const version = async (url) => {
    const response = await axios.get(`${url}/version`);
    return response.data;
};

const lastVersionRegistryPage = async (prod, serviceName, branch) => {
    const suffix = prod?"com":"fr";
    const response = await axios.get(`https://registry.ganttmanager.${suffix}/v2/gantt-manager-${serviceName.toLowerCase()}/manifests/${branch}`);
    return response;
};

const lastTagsPage = async (prod, serviceName) => {
    const suffix = prod?"com":"fr";
    const response = await axios.get(`https://registry.ganttmanager.${suffix}/v2/gantt-manager-${serviceName.toLowerCase()}/tags/list`);
    return response;
};

const frontLastVersionJenkinsPage = async (type)=> axios.get(`http://192.168.8.115:8080/view/gantt-manager-build-${type}/job/gantt-manager-front-build-${type}/ws/public/version.txt`);

const deployement = async (suffix, domain, domainArea, tag, service) => {
    await axios.get(`http://192.168.8.115:8080/view/gantt-manager-dpl-${suffix}/job/04%20-%20gantt-manager-service-dpl-${suffix}/buildWithParameters?token=gantt-manager-dpl-${suffix}&DOMAIN_NAME=${domain}&DOMAIN_AREA=${domainArea}&TAG=${tag}&SERVICE=${service}`);
};

const walletDeployement = async (suffix, domainArea, tag) => {
    await axios.get(`http://192.168.8.115:8080/view/gantt-manager-dpl-${suffix}/job/04%20-%20gantt-manager-wallet-dpl-${suffix}/buildWithParameters?token=gantt-manager-wallet-dpl-${suffix}&DOMAIN_AREA=${domainArea}&TAG=${tag}`);
};

const frontDeployement = async (suffix, tag) => {
    await axios.get(`http://192.168.8.115:8080/view/gantt-manager-dpl-${suffix}/job/05%20-%20gantt-manager-front-dpl-${suffix}/buildWithParameters?token=gantt-manager-front-dpl-${suffix}&TAG=${tag}`);
}

export default {
    login,
    getSession,
    forgottenPassword,
    logout,
    getCurrentUser,
    getAccessToken,
    getPreferences,
    updateUserPreferences,
    version,
    lastVersionRegistryPage,
    lastTagsPage,
    frontLastVersionJenkinsPage,
    deployement,
    walletDeployement,
    frontDeployement,
    createPlanningTab,
    getPlanningTabs,
    getPlanningTab,
    updatePlanningTab,
    updatePlanningTabWithPreferences,
    deletePlanningTab,
    duplicatePlanningTab,
    sharePlanningTab,
    getSharedPlanningTabs,
    acceptSharedPlanningTab,
    updatePlanningTabFilterData
};
