import moment from 'moment';
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import { isNaN } from 'lodash';
import { Empty, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Customized,
    ResponsiveContainer,
    Brush,
    // Area,
} from 'recharts';
// import { PlanningContext } from '../../contexts/needs/PlanningContext';
import { generateString } from '../../helpers/string-helper';
import { NeedChartContext } from '../../contexts/chart/ChartContext';

import { dateToTimestamp, dateToUTCDate } from '../../helpers/planning';
import { NEED_CHART_DATA, NEED_CHART_TYPE, NEED_HISTOGRAM_TYPE, SLICE_DATA_TYPE } from '../../constants/Needs';
import { xAxisDateFormat, xAxisDateScaleFunction } from '../charts/CustomHistogramDateXaxis';
import CustomizedDot from '../charts/customized/CustomizedDot';
import CustomizedActiveDot from '../charts/customized/CustomizedActiveDot';
import CustomizedLines from '../charts/customized/CustomizedLines';
import CustomDateRectangles from '../charts/customized/CustomDateRectangles';
import { TooltipContent, TooltipLabelDate } from '../charts/customized/CustomizedTooltip';
import CustomizedBar from '../charts/customized/CustomizedBar';

moment.defineLocale(window.localStorage.getItem('lng') || 'fr', {
    week: {
        dow: 1, // First day of week is Monday
        doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
    },
});
const StyledContainer = styled.div`
    .recharts-curve.recharts-line-curve {
        display: none;
    }
`;

const SliderContainer = styled.div`
    .ant-slider-dot {
        border: none;
        background-color: unset;
    }
`;

const SCALE_HEIGHT = 35;
const SCALE_FONT_SIZE = 11;
const YAXIS_WIDTH = 80;
const MIN_BAR_SIZE = 30;
const BRUSH_STEP = 1;
const MAX_CHART_DATA = 100;

const SliderMarksGenerator = ({ label, style }) => (
    <label
        className="text-primary"
        style={{ position: 'absolute', top: '30px', width: '100px', textAlign: 'center', right: '-50px', ...style }}
    >
        {label}
    </label>
);

const HistogramTabHistogram = () => {
    let showDefaultTooltip;
    let activeDotDataKey;

    const { chartData, needList, chartOptions, scale, durationUnit } = useContext(NeedChartContext);
    const needTranslation = useTranslation('translation', { keyPrefix: 'needs' }).t;
    const histogramTranslation = useTranslation('translation', { keyPrefix: 'histogram' }).t;
    const [refreshChart, setRefreshChart] = useState(0);
    const [brushStartIndex, setBrushStartIndex] = useState(0);
    const [brushEndIndex, setBrushEndIndex] = useState(0);
    // const [showDefaultTooltip, setShowDefaultTooltip] = useState(true);
    const isHourScale = scale === 'hours';

    useEffect(() => {
        if (chartData.length > 0) {
            const chartWidth = document.getElementById('gantt-histogram-container').offsetWidth - YAXIS_WIDTH;
            const dataCountByChartWidth = Math.floor(chartWidth / MIN_BAR_SIZE);
            console.log(
                '🚀 ~ file: HistogramTabHistogram.jsx:60 ~ useEffect ~ dataCountByChartWidth:',
                dataCountByChartWidth
            );
            if (chartData.length > dataCountByChartWidth) {
                setBrushStartIndex(0);
                setBrushEndIndex(dataCountByChartWidth - 1);
            } else {
                setBrushStartIndex(0);
                setBrushEndIndex(chartData.length - 1);
            }
        }
    }, [chartData]);

    const cumulativeChartData = useMemo(() => {
        const result = [];
        chartData.forEach((data, index) => {
            const cumulativeData = { ...data };
            if (index > 0) {
                Object.keys(data).forEach((key) => {
                    if (key !== 'date') {
                        cumulativeData[key] += result[index - 1][key];
                    }
                });
            }
            result.push(cumulativeData);
        });
        return result;
    }, [chartData]);

    const displayedData = useMemo(
        () =>
            chartOptions?.dataType === NEED_CHART_DATA.INSTANTANEOUS
                ? chartData.slice(brushStartIndex, brushEndIndex)
                : cumulativeChartData.slice(brushStartIndex, brushEndIndex),
        [brushStartIndex, brushEndIndex, chartOptions?.dataType, cumulativeChartData]
    );

    const getXAxisArgsForTimeBasedGraph = useCallback(
        (scaleFunction, isSubScale) => {
            if (chartData.length > 0 && scale) {
                const timeUnit = isSubScale
                    ? xAxisDateFormat[scale].subScaleUnit
                    : xAxisDateFormat[scale].mainScaleUnit;
                const startTime = isHourScale
                    ? dateToUTCDate(moment.utc(chartData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf())
                    : moment.utc(chartData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf();
                const endTime = isHourScale
                    ? dateToUTCDate(
                          moment
                              .utc(chartData[chartData.length - 1].date)
                              .endOf(timeUnit)
                              .valueOf()
                      )
                    : moment
                          .utc(chartData[chartData.length - 1].date)
                          .endOf(timeUnit)
                          .valueOf();
                const timeScale = d3.scaleTime().domain([startTime, endTime]);
                const dateFormat = isSubScale ? xAxisDateFormat[scale].subScale : xAxisDateFormat[scale].mainScale;
                return {
                    // domain: [startTime, endTime],
                    // type: 'number',
                    tickFormatter: (date) =>
                        isHourScale ? moment.utc(date).format(dateFormat) : moment(date).format(dateFormat),
                    ticks: timeScale.ticks(scaleFunction()).map((date) => dateToTimestamp(date)),
                };
            }
            return {};
        },
        [chartData, scale]
    );

    const mainAxisConfig = useMemo(() => {
        if (chartData.length > 0) {
            return getXAxisArgsForTimeBasedGraph(xAxisDateScaleFunction[xAxisDateFormat[scale]?.mainScaleUnit], false);
        }
        return {};
    }, [chartData]);

    

    const handleActiveDotMouseHover = (lineDataKey) => {
        showDefaultTooltip = false;
        activeDotDataKey = lineDataKey;
    };
    const handleActiveDotMouseLeave = () => {
        showDefaultTooltip = true;
        activeDotDataKey = null;
    };

    
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && activeDotDataKey && !showDefaultTooltip) {
            const activeDotPayload = payload.find((i) => i.name === activeDotDataKey);
            return (
                <div className="bg-white border p-2">
                    <TooltipLabelDate label={label} />
                    <TooltipContent payload={activeDotPayload} />
                </div>
            );
        }
        return (
            <div className="bg-white border p-2">
                <TooltipLabelDate label={label} />
                {(payload || []).map((currentPayload) => {
                    const payloadToShow = {...currentPayload};
                    if (!isNaN(currentPayload?.value)) {
                        payloadToShow.value = Number(currentPayload?.value).toFixed(2);
                        payloadToShow.realValue = +Number(currentPayload?.value).toFixed(2);
                    } else {
                        payloadToShow.realValue = 0;
                    }
                    if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
                        payloadToShow.value = ''.concat(payloadToShow.value).concat('/').concat(durationUnit(chartOptions.effortTimeUnitId).name);
                    }
                    return <TooltipContent key={generateString(5)} payload={payloadToShow} />;
                })}
            </div>
        );
    };

    const computeScaleDates = (data, tickFunction, scaleUnit) => {
        if (data.length > 0) {
            const currentData = data;
            const timeUnit = xAxisDateFormat[scale][scaleUnit];
            const startTime =
                timeUnit === 'hours'
                    ? dateToUTCDate(moment.utc(currentData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf())
                    : currentData[0].date;
            const endTime = moment
                .utc(currentData[currentData.length - 1].date)
                .endOf(xAxisDateFormat[scale].mainScaleUnit)
                .valueOf();
            const domain = [startTime, endTime];
            const timeScale = d3.scaleTime().domain(domain);
            const dates = [currentData[0].date];
            // const dates = [];
            dates.push(...timeScale.ticks(tickFunction()).map((date) => dateToTimestamp(date)));
            dates.push(
                moment
                    .utc(currentData[currentData.length - 1].date)
                    .endOf(xAxisDateFormat[scale].mainScaleUnit)
                    .valueOf()
            );
            return dates;
        }
        return [];
    };

    const mainScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat[scale]?.mainScaleUnit],
                'mainScaleUnit'
            ),
        [displayedData, refreshChart]
    );

    const subScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat[scale]?.subScaleUnit],
                'subScaleUnit'
            ),
        [displayedData, refreshChart]
    );

    const getTimeScaleByDomainAndRange = (domain, range) => {
        const timeScale = d3.scaleTime().domain(domain).range(range);
        return timeScale;
    };

    const computeScaleCoordinates = (dates, scaleType) => {
        if (dates.length > 0) {
            const dateFormat = xAxisDateFormat[scale][scaleType];
            const timeUnit = xAxisDateFormat[scale][`${scaleType}Unit`];
            let initialX = YAXIS_WIDTH;
            const xAxisWidth = document.getElementById('gantt-histogram-container').offsetWidth - YAXIS_WIDTH;
            const timeScale = getTimeScaleByDomainAndRange([dates[0], dates[dates.length - 1]], [0, xAxisWidth]);
            const rectCoordinates = dates.flatMap((date, index) => {
                if (index > 0) {
                    const lastDatePosition = timeScale(dates[index - 1]);
                    const datePosition = timeScale(date);
                    const widthRect = datePosition - lastDatePosition;
                    if ((timeUnit === 'hours' && widthRect <= 0) || widthRect < 0) {
                        return [];
                    }
                    const res = {
                        widthRect,
                        heightRect: SCALE_HEIGHT,
                        text:
                            timeUnit === 'hours'
                                ? moment.utc(date).format(dateFormat)
                                : moment(dates[index - 1]).format(dateFormat),
                        x: initialX,
                        isCentered: true,
                        // isCentered: index !== 1 && index !== dates.length - 1,
                    };
                    initialX += widthRect;
                    return res;
                }
                return [];
            });
            return rectCoordinates;
        }
        return [];
    };

    const mainScaleCoordinates = useMemo(() => computeScaleCoordinates(mainScaleDates, 'mainScale'), [mainScaleDates]);

    const subScaleCoordinates = useMemo(() => computeScaleCoordinates(subScaleDates, 'subScale'), [subScaleDates]);

    

    const handleBrushIndexChange = ([startIndex, endIndex]) => {
        // prevent max visible data
        if (endIndex - startIndex > MAX_CHART_DATA) {
            setBrushStartIndex(startIndex);
            setBrushEndIndex(startIndex + MAX_CHART_DATA - 1);
        } else {
            setBrushStartIndex(startIndex);
            setBrushEndIndex(endIndex);
        }
    };

    const brushTooltipFormatter = (index) => {
        let indexToCheck = index;
        if (index > chartData.length - 1) {
            indexToCheck = chartData.length - 1;
        } else if (index === brushEndIndex && index > 0) {
            indexToCheck -= 1;
        }
        const dateValue = chartData[indexToCheck].date;
        return moment.utc(dateValue).format(window.dateStringFormat);
    };

    const marks = useMemo(() => {
        const currentMarks = {};
        if (chartData.length > 0) {
            currentMarks[0] = (
                <SliderMarksGenerator label={moment.utc(chartData[0].date).format(window.dateStringFormat)} />
            );
            if (brushStartIndex !== 0) {
                currentMarks[brushStartIndex] = (
                    <SliderMarksGenerator
                        label={moment.utc(chartData[brushStartIndex].date).format(window.dateStringFormat)}
                    />
                );
            }
            if (brushEndIndex < chartData.length - 1 && brushEndIndex > 0) {
                currentMarks[brushEndIndex] = (
                    <SliderMarksGenerator
                        label={moment.utc(chartData[brushEndIndex - 1].date).format(window.dateStringFormat)}
                    />
                );
            }
            currentMarks[Math.ceil((chartData.length - 1) / BRUSH_STEP) * BRUSH_STEP] = (
                <SliderMarksGenerator
                    label={moment.utc(chartData[chartData.length - 1].date).format(window.dateStringFormat)}
                    style={{ width: 'auto', right: '0px' }}
                />
            );
        }
        return currentMarks;
    }, [brushStartIndex, brushEndIndex]);

    const labelValue = useMemo(() => {
        if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
            const timeUnit = durationUnit(chartOptions.effortTimeUnitId);
            return `${histogramTranslation('shown_data_effort')}: ${needTranslation('needs')} / ${timeUnit.name}`
        }
        return '';
    }, [chartOptions])

    return chartOptions && displayedData.length > 0 ? (
        <StyledContainer className="flex-col w-full h-full">

            <div className="w-full" style={{ height: `calc(100% - ${`${2 * SCALE_HEIGHT}px`})` }}>
                <ResponsiveContainer height="100%" width="100%" onResize={() => setRefreshChart((old) => old + 1)}>
                    <ComposedChart
                        width={1000}
                        barCategoryGap={0}
                        barGap={0}
                        margin={{ bottom: 2 * SCALE_HEIGHT }}
                        data={
                            displayedData
                        }
                    >
                        <CartesianGrid stroke="#828282" id="gantt-histogram-grid" 
                            verticalCoordinatesGenerator={() => mainScaleCoordinates.map((c) => c.x)}
                        
                        />
                        <YAxis  label={{value: labelValue, angle: -90, fontSize: 16, position: 'outsideBottomLeft'}} fontSize={SCALE_FONT_SIZE} width={YAXIS_WIDTH} domain={['auto', 'dataMax + 10']} />
                        <XAxis
                            dataKey="date"
                            scale={`${chartOptions.chartType === NEED_CHART_TYPE.BAR ? 'band' : null}`}
                            interval={0}
                            fontSize={SCALE_FONT_SIZE}
                            {...mainAxisConfig}
                            height={SCALE_HEIGHT}
                            padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                            tickSize={SCALE_HEIGHT}
                            hide
                        />

                        <Tooltip cursor={false} isAnimationActive={false} content={CustomTooltip} offset={20} />
                        {chartOptions.chartType === NEED_CHART_TYPE.BAR &&
                            needList
                                .filter((i) => i.selected)
                                .map((need, index, filteredList) => (
                                    <Bar
                                        key={generateString(2)}
                                        dataKey={need.name}
                                        stackId={
                                            chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED ? 'bar' : null
                                        }
                                        name={need.name}
                                        isAnimationActive={false}
                                        fill={need.color}
                                        shape={
                                            <CustomizedBar
                                                scaleCoordinates={mainScaleCoordinates}
                                                dataOrder={index}
                                                dataCount={filteredList.length}
                                                isStacked={chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED}
                                            />
                                        }
                                    />
                                ))}
                        {chartOptions.chartType === NEED_CHART_TYPE.LINE &&
                            needList
                                .filter((i) => i.selected)
                                .map((need) => (
                                    <Line
                                        key={generateString(2)}
                                        activeDot={<CustomizedActiveDot
                                            scaleCoordinates={mainScaleCoordinates}
                                            handleActiveDotMouseHover={() => handleActiveDotMouseHover()}
                                            handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                        />}
                                        dot={
                                            <CustomizedDot
                                                scaleCoordinates={mainScaleCoordinates}
                                                handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                            />
                                        }
                                        onMouseLeave={() => handleActiveDotMouseLeave(need.name)}
                                        dataKey={need.name}
                                        stroke={need.color}
                                        strokeWidth={0}
                                        name={need.name}
                                        isAnimationActive={false}
                                    />
                                ))}
                                {chartOptions.chartType === NEED_CHART_TYPE.LINE && (
                            <Customized component={<CustomizedLines coordinates={mainScaleCoordinates} />} />
                        )}
                        <Customized component={<CustomDateRectangles coordinates={subScaleCoordinates} />} />
                        <Customized
                            component={<CustomDateRectangles isMainScale coordinates={mainScaleCoordinates} />}
                        />
                        {/* {showBrush && (
                        <Brush
                            dataKey="date"
                            startIndex={brushStartIndex}
                            endIndex={brushEndIndex}
                            onChange={handleBrushIndexChange}
                            gap={5}
                            tickFormatter={(val) => moment.utc(val).format(window.dateStringFormat)}
                        />
                    )} */}
                        {/* {showBrush && <Customized component={<CustomizedSlider />} />} */}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>

            <SliderContainer className="w-full" style={{ height: `${SCALE_HEIGHT}px` }}>
                <Slider
                    className="m-0"
                    style={{ marginLeft: `${YAXIS_WIDTH}px`, marginRight: '5px' }}
                    range={{
                        draggableTrack: true,
                    }}
                    value={[brushStartIndex, brushEndIndex]}
                    onChange={handleBrushIndexChange}
                    marks={marks}
                    min={0}
                    tooltip={{ formatter: brushTooltipFormatter }}
                    max={Math.ceil((chartData.length - 1) / BRUSH_STEP) * BRUSH_STEP}
                    step={BRUSH_STEP}
                    railStyle={{ height: `${SCALE_HEIGHT}px`, background: '#eeeeee' }}
                    trackStyle={{ height: `${SCALE_HEIGHT}px`, background: '#555' }}
                    handleStyle={{
                        height: `${SCALE_HEIGHT}px`,
                        width: '5px',
                        borderRadius: 'unset',
                        margin: 0,
                        border: 'solid #000',
                        cursor: 'col-resize',
                    }}
                />
            </SliderContainer>
        </StyledContainer>
    ) : (
        <Empty />
    );
};

export default HistogramTabHistogram;
