import React, { useRef, useState, useEffect, useContext } from 'react';
import { Col, Row, Select, Tree, Popconfirm, Button, Tooltip } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import {
    FileOutlined,
    FolderOpenOutlined,
    EditOutlined,
    EyeOutlined,
    LockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import AddProjectModal from './modals/AddProjectModal';
import EditProjectModal from './modals/EditProjectModal';
import AddProjectPermissionModal from './modals/AddProjectPermissionModal';
import FolderService from '../../services/folder.service';
import { requestError } from '../../helpers/notification';
import LoadingSpin from '../utils/LoadingSpin';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { USER_ACCESS } from '../../helpers/planning';
import { generateString } from '../../helpers/string-helper';
import activityService from '../../services/activity.service';
import customFieldService from '../../services/custom-field.service';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';

const PanelWrapper = styled.div`
    background: #f8f8f8;
`;

const PanelTitle = styled.div`
    font-size: 13px;
    line-height: 16px;
`;

const PanelContent = styled.div``;

const AddButton = styled.button`
    font-size: 13px;
    line-height: 16px;
    height: 32px;
`;

const PlusIcon = styled(PlusSvg)`
    left: 8px;
`;

const CloseIcon = styled(CloseSvg)`
    width: 16px;
    height: 16px;
`;

const SectionHeader = styled.header`
    font-size: 13px;
`;

const SectionTitle = styled.span`
    line-height: 16px;
`;

const SectionContent = styled.div`
    background: #f8f8f8;
`;

const Table = styled.table`
    color: #828282;
    font-size: 12px;
`;

const TableRow = styled.tr`
    border-top: 1px solid #f8f8f8;
`;

const TableData = styled.td`
    padding: 8px 9px;
`;

const TreeStyle = createGlobalStyle`
    .ant-tree {
        height: 100%;
    }
    
    .ant-tree,
    .ant-tree-show-line .ant-tree-switcher {
        background-color: transparent;
    }
    
    .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
        line-height: 19px;    
    }
`;

const ProjectsList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'projects' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [treeData, setTreeData] = useState([]);
    const [allFolders, setAllFolders] = useState(null);
    const [selected, setSelected] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState([]);
    const addProjectModalRef = useRef();
    const editProjectModalRef = useRef();
    const addProjectPermissionModalRef = useRef();
    const { rootFolders, updatedFolderBroadcast, subFoldersList, updateSubFoldersList } = useContext(PlanningContext);
    const [planningJobs, setPlanningJobs] = useState([]);

    const updateChildrenFolders = (list, key, children) =>
        list.map((node) => {
            if (node.key === key) {
                return { ...node, children };
            }

            if (node.children) {
                return { ...node, children: updateChildrenFolders(node.children, key, children) };
            }

            return node;
        });

    const treeLoadData = async (item, folderIdManual = null) => {
        let idFolder = folderIdManual;
        if (item) {
            const [, folderId] = item?.key.split('-');
            idFolder = folderId;
        }
        if (subFoldersList[idFolder]) {
            console.log('=> ProjectList USING CACHE DATA');
            const data = subFoldersList[idFolder];
            const plannings = await FolderService.listPlannings(idFolder);

            setTreeData((origin) =>
                updateChildrenFolders(origin, `folder-${idFolder}`, [
                    ...data.childFolders.map((node) => ({
                        key: `folder-${node.id}`,
                        title: node.name,
                        id: node.id,
                        icon: <FolderOpenOutlined />,
                        parentId: Number(idFolder),
                    })),
                    ...plannings.map((planning) => ({
                        key: `planning-${planning.id}`,
                        title: planning.name,
                        isLeaf: true,
                        id: planning.id,
                        rootActivityId: planning.rootActivityId,
                        icon: <FileOutlined />,
                        parentId: planning.folderId,
                    })),
                ])
            );
        } else {
            FolderService.listSubFolders(idFolder)
                .then(async (data) => {
                    updateSubFoldersList(idFolder, data);
                    const plannings = await FolderService.listPlannings(idFolder);

                    setTreeData((origin) =>
                        updateChildrenFolders(origin, `folder-${idFolder}`, [
                            ...data.childFolders.map((node) => ({
                                key: `folder-${node.id}`,
                                title: node.name,
                                id: node.id,
                                icon: <FolderOpenOutlined />,
                                parentId: Number(idFolder),
                            })),
                            ...plannings.map((planning) => ({
                                key: `planning-${planning.id}`,
                                title: planning.name,
                                isLeaf: true,
                                id: planning.id,
                                rootActivityId: planning.rootActivityId,
                                icon: <FileOutlined />,
                                parentId: planning.folderId,
                            })),
                        ])
                    );
                })
                .catch((error) => {
                    requestError(error, t('folder_loading_error'));
                });
        }
    };

    const removeFolderInTreeData = (list, key) => {
        list.forEach((node, index) => {
            if (node.key === key) {
                list.splice(index, 1);

                return;
            }

            if (node.children) {
                removeFolderInTreeData(node.children, key);
            }
        });

        return list;
    };

    const flattenFolders = (folderList) =>
        folderList.reduce((agg, folder) => {
            agg.push({
                id: folder.id,
                name: folder.name,
                parentId: folder.parentId,
            });

            if (folder.childFolders.length) {
                const children = flattenFolders(folder.childFolders);

                children.forEach((childFolder) => {
                    agg.push({
                        id: childFolder.id,
                        name: childFolder.name,
                    });
                });
            }

            return agg;
        }, []);

    const loadRootFolders = () => {
        setLoading(true);
        setTreeData([
            ...rootFolders.map((node) => ({
                id: node.id,
                key: `folder-${node.id}`,
                title: node.name,
                icon: <FolderOpenOutlined />,
            })),
        ]);

        setAllFolders(flattenFolders(rootFolders));

        setLoading(false);
    };
    // updating list of folder in addproject modal
    useEffect(() => {
        if (rootFolders.length) {
            setAllFolders(flattenFolders(rootFolders));
        }
    }, [rootFolders]);

    const loadFolderPermissions = (id) => {
        FolderService.listFolderPermissions(id)
            .then((data) => {
                setPermissions(data);
            })
            .catch((error) => {
                requestError(error, t('permission_loading_error'));
            });
    };

    const updateFolderPermission = (permissionId, teamId, access) => {
        const [, folderId] = selected.key.split('-');

        FolderService.deleteFolderPermission(folderId, permissionId)
            .then(() =>
                FolderService.createFolderPermission(folderId, teamId, access).then(() => {
                    loadFolderPermissions(folderId);
                })
            )
            .catch((error) => {
                requestError(error, t('permission_update_error'));
            });
    };

    const deleteFolderPermission = (folderId, permissionId) => {
        FolderService.deleteFolderPermission(folderId, permissionId)
            .then(() => {
                loadFolderPermissions(folderId);
            })
            .catch((error) => {
                requestError(error, t('permission_remove_error'));
            });
    };

    const deleteFolder = (key) => {
        const [, folderId] = selected.key.split('-');
        setSelected(null);
        FolderService.deleteFolder(folderId)
            .then(() => {
                setTreeData((origin) => removeFolderInTreeData([...origin], key));
            })
            .catch((error) => {
                if (error.response.data.code === 422)
                    requestError(error, t('folder_contain_warning').concat(' \n ', error.response.data.value));
                else requestError(error, t('folder_delete_error'));
            });
    };

    const loadPlanningPermissions = (selectedItem) => {
        activityService.listPlanningTeamJobs(selectedItem.rootActivityId).then((teamJobs) =>
            FolderService.listPlanningPermissions(selectedItem.id)
                .then((response) => {
                    const permissionsJobs = response.map((permission) =>
                        Object.assign(permission, {
                            jobNames: teamJobs.find((team) => team.teamId === permission.team.id)?.jobNames || [],
                            teamId: permission.team.id,
                        })
                    );
                    setPermissions(permissionsJobs);
                })
                .catch((error) => {
                    requestError(error, t('permission_loading_error'));
                })
        );
    };

    const updatePlanningPermission = (permissionId, teamId, access) => {
        const [, planningId] = selected.key.split('-');

        FolderService.deletePlanningPermission(planningId, permissionId)
            .then(() =>
                FolderService.createPlanningPermission(planningId, teamId, access).then(() => {
                    loadPlanningPermissions(selected);
                })
            )
            .catch((error) => {
                requestError(error, t('permission_update_error'));
            });
    };

    const deletePlanningPermission = (planningId, permissionId) => {
        FolderService.deletePlanningPermission(planningId, permissionId)
            .then(() => {
                loadPlanningPermissions(selected);
            })
            .catch((error) => {
                requestError(error, t('permission_remove_error'));
            });
    };

    const updatePermission = (permissionId, teamId, access) => {
        const [type] = selected.key.split('-');

        switch (type) {
            case 'folder':
                updateFolderPermission(permissionId, teamId, access);
                break;
            case 'planning':
                updatePlanningPermission(permissionId, teamId, access);
                break;
            default:
        }
    };

    const deletePermission = (permissionId) => {
        const [type, id] = selected.key.split('-');

        switch (type) {
            case 'folder':
                deleteFolderPermission(id, permissionId);
                break;
            case 'planning':
                deletePlanningPermission(id, permissionId);
                break;
            default:
        }
    };

    //--------------------------------------------------
    // Handlers
    //--------------------------------------------------

    const onSelectTreeData = (selectedKeys) => {
        if (selectedKeys.length <= 0) {
            setSelected(null);

            return;
        }

        const [type, id] = selectedKeys[0].split('-');
        setPermissions([]);

        const selectedItem = treeData.flatten().find((node) => node.key === selectedKeys[0]);
        setSelected(selectedItem);

        switch (type) {
            case 'folder':
                loadFolderPermissions(id);
                break;
            case 'planning':
                loadPlanningPermissions(selectedItem);
                break;
            default:
        }
    };

    const onFolderAdded = (folder) => {
        treeLoadData(null, folder.parentId)
        console.log('folder added');
    };

    const isFolder = () => selected?.key?.split('-')[0] === 'folder';
    const isPlanning = () => !isFolder();

    useEffect(() => {
        const getPlanningJobs = async () => {
            if (selected && isPlanning()) {
                const planningFields = await customFieldService.planningCustomFields(selected?.id);
                const jobsList = planningFields
                    .filter((unifiedfield) => unifiedfield.name === JOB_FIELD)
                    .map((unifiedfield) => unifiedfield.type.choices)
                    .flatMap((choices) => Object.keys(choices));
                setPlanningJobs(jobsList);
            }
        };
        getPlanningJobs();
    }, [selected]);

    useEffect(() => {
        loadRootFolders();
    }, []);
    // broadcast
    const updateFoldersData = (list, nodeObjectUpdated) =>
        list.map((node) => {
            if (node.key === nodeObjectUpdated.key) {
                return { ...node, title: nodeObjectUpdated.title };
            }

            if (node.children) {
                return { ...node, children: updateFoldersData(node.children, nodeObjectUpdated) };
            }

            return node;
        });
    const addNewFolderToTree = (list, parentKey, updatedData) =>
        list.map((node) => {
            if (node.key === parentKey) {
                const children = node.children || [];
                return { ...node, children: [...children, updatedData] };
            }
            if (node.children) {
                return { ...node, children: addNewFolderToTree(node.children, parentKey, updatedData) };
            }
            return node;
        });

    const handleAutoUpdate = (updatedObject) => {
        setLoading(true);
        if (updatedObject.title) {
            // test if existing in treeData
            const currentTreeData = treeData.flatten();
            const indexObject = currentTreeData.findIndex((i) => i.id === updatedObject.id);
            if (indexObject !== -1) {
                setTreeData((origin) => updateFoldersData([...origin], updatedObject));
            }
            // if add in root folder
            if (indexObject === -1 && updatedObject.parentId === null) {
                const cloneTreeData = [...treeData];
                cloneTreeData.push(updatedObject);
                setTreeData(cloneTreeData);
            }
            // if add in subFolder
            if (indexObject === -1 && updatedObject.parentId !== null) {
                const parentKey = `folder-${updatedObject.parentId}`;
                setTreeData((origin) => addNewFolderToTree(origin, parentKey, updatedObject));
            }
            setLoading(false);
        } else {
            setTreeData((origin) => removeFolderInTreeData([...origin], updatedObject.key));
            setSelected(null);
            setPermissions([]);
            setLoading(false);
        }
    };

    const getPermissionIcon = (planningAccess, access) => {
        let icon = <></>;
        const color =
            access === planningAccess || (planningAccess === USER_ACCESS.FULLACCESS && access === USER_ACCESS.READWRITE)
                ? '#00ff00'
                : '#ff0000';
        switch (planningAccess) {
            case USER_ACCESS.READ:
                icon = <EyeOutlined className="mr-2" style={{ color }} />;
                break;
            case USER_ACCESS.READWRITE:
                icon = <EditOutlined className="mr-2" style={{ color }} />;
                break;
            case USER_ACCESS.NONE:
                icon = <LockOutlined className="mr-2" style={{ color }} />;
                break;
            case USER_ACCESS.FULLACCESS:
                icon = <UserOutlined className="mr-2" style={{ color }} />;
                break;
            default:
                break;
        }
        return icon;
    };

    useEffect(() => {
        if (allFolders && updatedFolderBroadcast && updatedFolderBroadcast.type === 'Folder') {
            const updatedFolderObject = {
                id: +updatedFolderBroadcast.id,
                key: `folder-${updatedFolderBroadcast.id}`,
                title: updatedFolderBroadcast.updatedData ? updatedFolderBroadcast.updatedData.name : null,
                parentId: updatedFolderBroadcast.updatedData ? updatedFolderBroadcast.updatedData.parentId : null,
                icon: <FolderOpenOutlined />,
            };
            if (selected && updatedFolderObject.title && selected.id === updatedFolderObject.id) {
                setSelected({ ...selected, title: updatedFolderObject.title });
            }
            handleAutoUpdate(updatedFolderObject);
        }
        if (allFolders && updatedFolderBroadcast && updatedFolderBroadcast.type === 'Planning') {
            const updatedPlanningObject = {
                id: +updatedFolderBroadcast.id,
                key: `planning-${updatedFolderBroadcast.id}`,
                title: updatedFolderBroadcast.updatedData ? updatedFolderBroadcast.updatedData.name : null,
                parentId: updatedFolderBroadcast.updatedData ? updatedFolderBroadcast.updatedData.folderId : null,
                icon: <FileOutlined />,
                isLeaf: true,
            };
            if (selected && updatedPlanningObject.title && selected.id === updatedPlanningObject.id) {
                setSelected({ ...selected, title: updatedPlanningObject.title });
            }
            handleAutoUpdate(updatedPlanningObject);
        }
    }, [updatedFolderBroadcast]);

    const handleJobsChange = (value, permission) => {
        // if (value.length === 0) {
        //     return;
        // }
        let jobsToBeSelected = [];

        if (permission.jobNames.indexOf(ALL_JOBS) === -1 && value.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = [ALL_JOBS];
        } else {
            jobsToBeSelected = [...value];
        }
        if (permission.jobNames.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = value.filter((i) => i !== ALL_JOBS);
        }
        if (permission.access === USER_ACCESS.READWRITE && jobsToBeSelected.length > 0) {
            activityService.updateJob(selected.rootActivityId, permission.team.id, jobsToBeSelected).then(() => loadPlanningPermissions(selected));
        }
    };

    return (
        <>
            <TreeStyle />
            <Row gutter={[0, 0]} className="h-full flex-col">
                <Col className="h-full">
                    <PanelWrapper className="rounded p-2 h-full">
                        <PanelTitle className="text-primary font-uniSansBold">{t('shared_folder_list')}</PanelTitle>
                        <PanelContent className="flex flex-col h-full">
                            {loading ? (
                                <LoadingSpin />
                            ) : (
                                <Tree
                                    treeData={treeData}
                                    loadData={treeLoadData}
                                    showLine={{ showLeafIcon: false }}
                                    showIcon
                                    onSelect={onSelectTreeData}
                                    className="overflow-y-auto pb-12"
                                />
                            )}
                            <div className="absolute bottom-0 left-0 right-0 w-full px-3 z-10">
                                <div className="bg-secondary-light px-2 pb-2">
                                    <Button
                                        type="secondary"
                                        className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                                        onClick={
                                            addProjectModalRef.current ? addProjectModalRef.current.openModal : null
                                        }
                                    >
                                        <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                                    </Button>
                                </div>
                            </div>
                        </PanelContent>
                    </PanelWrapper>
                </Col>
                <Col style={{ marginLeft: 20 }} className="h-full">
                    <div className="flex flex-col h-full">
                        <SectionHeader className="mb-3 flex items-center">
                            <SectionTitle className="font-uniSansBold text-primary" style={{ cursor: 'pointer' }}>
                                {selected ? (
                                    <Tooltip
                                        className="flex items-center"
                                        title={generalTranslation('rename')}
                                        onClick={editProjectModalRef.current && editProjectModalRef.current.openModal}
                                    >
                                        <EditOutlined className="mr-1" /> {selected.title}
                                    </Tooltip>
                                ) : (
                                    t('no_project_selected')
                                )}
                            </SectionTitle>
                            <span className="text-gray-500 ml-2">{t('concerned_teams')}</span>
                        </SectionHeader>
                        <SectionContent className="flex-1 rounded p-2" style={{ height: '90%' }}>
                            <div className="h-full flex flex-col">
                                <div style={{ overflowY: 'scroll' }}>
                                    <Table className="w-full">
                                        <thead>
                                            <tr>
                                                <th className="font-sans" style={{ width: 15 }}>
                                                    &nbsp;
                                                </th>
                                                <th className="font-sans">{generalTranslation('name')}</th>
                                                <th className="font-sans">{generalTranslation('rights')}</th>
                                                {isPlanning() && (
                                                    <th className="font-sans">{generalTranslation('jobs')}</th>
                                                )}
                                                <th className="font-sans" style={{ width: 32 }}>
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        {selected ? (
                                            <tbody>
                                                {permissions.map((permission) => (
                                                    <TableRow key={generateString(4)}>
                                                        <TableData className="bg-white">
                                                            {getPermissionIcon(
                                                                permission.realAccess,
                                                                permission.access
                                                            )}
                                                        </TableData>
                                                        <TableData className="bg-white">
                                                            {permission.team.name}
                                                        </TableData>
                                                        <TableData className="bg-white">
                                                            <Select
                                                                defaultValue={permission.access}
                                                                style={{ width: 150 }}
                                                                onChange={(value) =>
                                                                    updatePermission(
                                                                        permission.id,
                                                                        permission.team.id,
                                                                        value
                                                                    )
                                                                }
                                                            >
                                                                <Select.Option value="NONE">
                                                                    {generalTranslation('access_none')}
                                                                </Select.Option>
                                                                <Select.Option value="READ">
                                                                    {generalTranslation('access_read')}
                                                                </Select.Option>
                                                                <Select.Option value="WRITE">
                                                                    {generalTranslation('access_write')}
                                                                </Select.Option>
                                                            </Select>
                                                        </TableData>
                                                        {isPlanning() && (
                                                            <TableData className="bg-white">
                                                                <Select
                                                                    mode="multiple"
                                                                    value={permission.jobNames}
                                                                    disabled={
                                                                        permission.access !== USER_ACCESS.READWRITE
                                                                    }
                                                                    status={permission.jobNames.length === 0 && permission.access === USER_ACCESS.READWRITE ? 'error' : ''}
                                                                    style={{ width: 150 }}
                                                                    onChange={(val) => handleJobsChange(val, permission)}
                                                                >
                                                                    {planningJobs.map((choice) => (
                                                                        <Select.Option
                                                                            key={generateString(6)}
                                                                            value={choice}
                                                                        >
                                                                            {choice}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </TableData>
                                                        )}
                                                        <TableData>
                                                            <Popconfirm
                                                                title={t('permission_remove_warning')}
                                                                okText={generalTranslation('yes')}
                                                                cancelText={generalTranslation('no')}
                                                                onConfirm={() => deletePermission(permission.id)}
                                                            >
                                                                <CloseIcon className="cursor-pointer" />
                                                            </Popconfirm>
                                                        </TableData>
                                                    </TableRow>
                                                ))}
                                            </tbody>
                                        ) : null}
                                    </Table>
                                </div>
                                <div className="mt-auto">
                                    {selected && (
                                        <>
                                            {addProjectPermissionModalRef.current && (
                                                <AddButton
                                                    className="relative text-primary font-uniSansBold bg-white rounded-sm px-16"
                                                    onClick={addProjectPermissionModalRef.current.openModal}
                                                >
                                                    {t('add_team')}
                                                    <PlusIcon className="absolute top-1/2 transform -translate-y-1/2" />
                                                </AddButton>
                                            )}

                                            {isFolder() && (
                                                <Popconfirm
                                                    title={t('project_delete_warning').concat(` ${selected.title} ?`)}
                                                    okText={generalTranslation('yes')}
                                                    cancelText={generalTranslation('no')}
                                                    onConfirm={() => deleteFolder(selected.key)}
                                                >
                                                    <AddButton className="relative text-red-500 font-uniSansBold border border-red-500 rounded-sm px-16 ml-4 hover:text-white hover:bg-red-500 transition-colors">
                                                        {t('delete_folder')}
                                                    </AddButton>
                                                </Popconfirm>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </SectionContent>
                    </div>
                </Col>
            </Row>
            <AddProjectModal
                parent={selected && selected.key.includes('folder') ? selected?.id : selected?.parentId}
                ref={addProjectModalRef}
                items={allFolders || []}
                onFolderAdded={onFolderAdded}
            />
            <EditProjectModal data={selected} ref={editProjectModalRef} onEditFinished={setSelected} />
            <AddProjectPermissionModal
                ref={addProjectPermissionModalRef}
                selected={selected}
                permissions={permissions}
                planningJobs={planningJobs}
                onPermissionAdded={() => onSelectTreeData([selected.key])}
            />
        </>
    );
};

export default ProjectsList;
