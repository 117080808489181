import axios from './api';

/*
 ACTIVITIES
 */

const treeActivities = async (activityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/reduced-content`
    );

    return response.data;
};

const showActivity = async (activityId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}`);

    return response.data;
};
const showActivityWithColors = async (activityId, customFieldId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/color-field/${customFieldId}`);

    return response.data;
};

const createActivity = async (data) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity`, data);

    return response.data;
};

const updateActivityIdentity = async (activityId, identity) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/identity`,
        { identity }
    );

    return response.data;
};

const updateActivityName = async (activityId, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/name`, {
        name,
    });

    return response.data;
};

const updateActivityDescription = async (activityId, description) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/description`,
        { description }
    );

    return response.data;
};

const updateMultipleActivityInfo = async (activityIdList, field, value) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${encodeURIComponent(field)}`, {
        value,
        activityIdList,
    });

    return response.data;
};

const updateActivityJobId = async (activityId, job) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/job`, {
        value: job,
    });

    return response.data;
};

const updateActivityCalendarId = async (activityId, calendarId) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/calendar-id`,
        { calendarId }
    );

    return response.data;
};

const updateActivityParent = async (parentActivityId, activities) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${parentActivityId}/move-into`,
        { activities }
    );
    return response.data;
};

const updateActivityQuantity = async (activityId, quantity) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/quantity`,
        { quantity }
    );

    return response.data;
};

const updateActivityQuantityUnit = async (activityId, quantityUnit) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/quantity-unit`,
        { quantityUnit }
    );

    return response.data;
};

const updateActivityYield = async (activityId, activityYield) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/yield`, {
        yield: activityYield,
    });

    return response.data;
};

const updateActivityChampPm = async (activityId, unifiedFieldId, roundedDuration) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/champ-pm-auto/${unifiedFieldId}/?isRoundedDuration=${roundedDuration}`
    );

    return response.data;
};
const updateMultipleActivityChampPm = async (activityIdList, unifiedFieldId) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/champ-pm-auto/${unifiedFieldId}`,
        { activityIdList }
    );

    return response.data;
};

const updateActivityStartDate = async (activityId, startDate) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/start-date`,
        {
            startDate,
        }
    );

    return response.data;
};

const updateActivityAvancement = async (activityId, avancement) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/avancement`,
        {
            avancement,
        }
    );

    return response.data;
};

/** Met a jour la date de d�but sur la premiere heure travaill� de la journ�e. */
const updateActivityFirstWorkedTime = async (activityId, startDate) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/first-worked-time`,
        {
            startDate,
        }
    );

    return response.data;
};

/** Met a jour la date de fin sur la derniere heure travaill� de la journ�e. */
const updateActivityLastWorkedTime = async (activityId, endDate) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/last-worked-time`,
        {
            endDate,
        }
    );

    return response.data;
};

const updateActivityMoveStart = async (activityId, startDate) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/move-start-date`,
        {
            startDate,
        }
    );

    return response.data;
};

const updateActivityEndDate = async (activityId, endDate) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/end-date`,
        {
            endDate,
        }
    );

    return response.data;
};

const updateActivityTimeUnit = async (activityId, dayDefinitionId) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/time-unit`,
        { dayDefinitionId }
    );

    return response.data;
};

const updateActivityDuration = async (activityId, duration) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/duration`,
        {
            duration,
        }
    );

    return response.data;
};

const updateActivityState = async (activityId, state) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/update-state`,
        { state }
    );

    return response.data;
};
const updateActivityNeededSkillQuantity = async (activityId, neededSkillQuantity) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/needed-skill-quantity`,
        { neededSkillQuantity }
    );

    return response.data;
};

const duplicateActivity = async (activityId) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/duplicate`,
        {}
    );

    return response.data;
};

const duplicateMultipleActivity = async (idList) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/duplicate-multiple`,
        {idList}
    );

    return response.data;
};

const checkInvalidActivities = async (planning) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${planning.rootActivityId}/check-invalid-activities`
    );

    return response.data;
};

const deleteActivity = async (activityId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}`);

    return response.data;
};

const deleteMultipleActivity = async (activityIdList) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity`, {
        data: {
            activityIdList,
        },
    });

    return response.data;
};

/*
 GANTT
 */

const createFilteredGroup = async (activityId, unifiedFieldId, activitySimpleOnly, sortOptions, filters) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityId}/create-filtered-group`,
        {
            activitySimpleOnly,
            sortOptions,
            unifiedFieldId,
            filters
        }
    );

    return response.data;
};

const applyFilter = async (rootActivityId, customFieldColorId, filters) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${rootActivityId}/filter`,
        {
            unifiedFieldId: customFieldColorId,
            filters
        }
    );

    return response.data;
};

const generateFlatten = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${rootActivityId}/generate-flatten`
    );

    return response.data;
};

/*
 PLANIFY
 */

const reschedulePlanning = async (rootActivityId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/planify/${rootActivityId}`, {});

    return response.data;
};

/*
 GANTT LINK
 */

const listLinks = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link`);

    return response.data;
};

const listLinksByPlanning = async (planningRootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link/planning/${planningRootActivityId}`
    );

    return response.data;
};

const showLink = async (id) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link/${id}`);

    return response.data;
};

const createLink = async (
    activityPredecessorId,
    activitySuccessorId,
    decalage,
    linkType,
    dayDefinitionId,
    linkCategoryId
) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link`, {
        activityPredecessorId,
        activitySuccessorId,
        decalage,
        linkType,
        dayDefinitionId,
        linkCategoryId,
    });

    return response.data;
};

const createMultipleLinks = async (activityIdList, decalage, linkType, dayDefinitionId, linkMode, used) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link/link-activities`, {
        activityIdList,
        decalage,
        linkType,
        dayDefinitionId,
        linkMode,
        used
    });

    return response.data;
};

const deleteLink = async (ganttLinkId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link/${ganttLinkId}`);

    return response.data;
};

const listLinkCategories = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/link-category`);

    return response.data;
};

const updateLink = async (id, uriParameter, field, value) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/gantt-link/${id}/${uriParameter}`,
        { [field]: value }
    );

    return response.data;
};

const moveAfter = async (id, activityIds) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${id}/move-after`, {
        activities: activityIds,
    });

    return response.data;
};
// import
const importActivities = async (activityRootId, data) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/import/${activityRootId}`,
        data
    );

    return response.data;
};
// Modification du job d'un utilisateur
const updateJob = async (activityRootId, teamId, jobName) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/team/${teamId}/planning/${activityRootId}/job`,
        { value: jobName }
    );
    return response.data;
};
// Suppression du job d'un utilisateur
const deleteJob = async (activityRootId, teamId) => {
    const response = await axios.delete(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/team/${teamId}/planning/${activityRootId}/job`
    );
    return response.data;
};

const listPlanningTeamJobs = async (activityRootId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/team/planning/${activityRootId}`
    );
    return response.data;
};

const listUserJobs = async (activityRootId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/user/planning/${activityRootId}/job`
    );
    return response.data;
};

// id prefix

const changeIdPrefix = async (activityRootId, prefixId) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityRootId}/prefix-identity`,
        { prefixId }
    );

    return response.data;
};

const getIdPrefix = async (activityRootId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/${activityRootId}/prefix-identity`
    );

    return response.data;
};

const getRootActivityConfig = async (activityRootId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/activity/conf-root-activity/${activityRootId}`
    );

    return response.data;
};
export default {
    // Activities
    treeActivities,
    showActivity,
    showActivityWithColors,
    createActivity,
    updateActivityIdentity,
    updateActivityName,
    updateActivityDescription,
    updateActivityJobId,
    updateActivityParent,
    updateActivityQuantity,
    updateActivityQuantityUnit,
    updateActivityYield,
    updateActivityChampPm,
    updateMultipleActivityChampPm,
    updateActivityCalendarId,
    updateActivityStartDate,
    updateActivityEndDate,
    updateActivityFirstWorkedTime,
    updateActivityLastWorkedTime,
    updateActivityMoveStart,
    updateActivityTimeUnit,
    updateActivityDuration,
    updateActivityAvancement,
    updateActivityState,
    updateActivityNeededSkillQuantity,
    checkInvalidActivities,
    deleteActivity,
    duplicateActivity,
    duplicateMultipleActivity,
    // Gantt
    createFilteredGroup,
    applyFilter,
    generateFlatten,
    // Planify
    reschedulePlanning,
    // Gantt link
    listLinks,
    listLinksByPlanning,
    createLink,
    createMultipleLinks,
    deleteLink,
    showLink,
    listLinkCategories,
    updateLink,
    // move after
    moveAfter,
    // import
    importActivities,
    updateJob,
    deleteJob,
    listPlanningTeamJobs,
    listUserJobs,
    // multiple
    updateMultipleActivityInfo,
    deleteMultipleActivity,
    // prefix
    getIdPrefix,
    changeIdPrefix,
    getRootActivityConfig
};
