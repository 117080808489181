import React, { forwardRef, useCallback, useContext, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Popconfirm, Row } from 'antd';
import FolderService from '../../services/folder.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { getPlanningAccess, USER_ACCESS } from '../../helpers/planning';
import { UserContext } from '../../contexts/app/UserContext';
import FolderExplorer from './FolderExplorer';

const ExplorerModal = forwardRef((props, ref) => {
    const [isVisible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const { setFolderSelected, updatePlanning, modeSelected } = useContext(PlanningContext);
    const { updateUserPreferences, currentUser } = useContext(UserContext);
    const { t } = useTranslation('translation', { keyPrefix: 'explorer_modal' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = useCallback(async () => {
        if (selectedItem) {
            if (!selectedItem.rootActivityId) {
                setFolderSelected(selectedItem);
                await updateUserPreferences({ open_folder: selectedItem, open_planning: null });
                const planningWithAccess = selectedItem.plannings.filter(
                    (p) => getPlanningAccess(p, currentUser) !== USER_ACCESS.NONE
                );
                if (planningWithAccess.length) {
                    await updatePlanning(planningWithAccess.first());
                } else {
                    await updatePlanning(null);
                }
                setVisible(false);
            } else {
                const selectedFolder = await FolderService.showFolder(selectedItem.folderId);
                setFolderSelected(selectedFolder);
                await updateUserPreferences({ open_folder: selectedFolder});
                await updatePlanning(selectedItem);
                setVisible(false);
            }
        }
    }, [selectedItem]);
    const handleDeletePlanning = (id) => {
        const deletePlanning =
            modeSelected === 'sandbox'
                ? FolderService.deleteSandboxPlanning(id)
                : FolderService.deletePlanning(id);
        deletePlanning
            .then(() => {
                console.log('deleted')
            })
    }

    return (
        <Modal
            title={t('folder_explorer')}
            className="explorer-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            width={912}
            footer={null}
        >
            <FolderExplorer
                mode={modeSelected}
                onSelected={(item) => {
                    setSelectedItem(item);
                }}
            />
            <Row className="flex justify-between">
                <div>
                    {selectedItem?.rootActivityId &&
                        [USER_ACCESS.NONE, USER_ACCESS.READ].indexOf(selectedItem?.access) === -1 && (
                            <Popconfirm
                                title={t('are_you_sure_you_want_to_delete')}
                                okText={generalTranslation('yes')}
                                cancelText={generalTranslation('no')}
                                onConfirm={() => handleDeletePlanning(selectedItem.id)}
                            >
                                <Button type="danger">{t('delete_planning')}</Button>
                            </Popconfirm>
                        )}
                </div>
                <div>
                    <Button type="secondary" className="mr-2" onClick={onCancel}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button type="primary" onClick={onOk}>
                        {generalTranslation('ok')}
                    </Button>
                </div>
            </Row>
        </Modal>
    );
});

export default ExplorerModal;
