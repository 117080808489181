import * as Sentry from '@sentry/browser';
import { useHotkeys } from 'react-hotkeys-hook';
import { isNil } from 'lodash';
import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ActivityService from '../../../services/activity.service';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import { TabContext } from '../../../contexts/app/TabContext';
import { UserContext } from '../../../contexts/app/UserContext';
import { CustomFieldsMenuContextProvider } from '../../../contexts/custom-fields/CustomFieldsMenuContext';
import CustomFieldsModal from '../../modal/CustomFieldsModal';
import DeletePlanningModal from '../../plannings/DeletePlanningModal';
import EditPlanningModal from '../../plannings/EditPlanningModal';
import GroupingModal from '../../grouping/GroupingModal';
import SharePlanningModal from '../../plannings/SharePlanningModal';
import ValidatePlanningModal from '../../modal/ValidatePlanningModal';
import { notificationError, notificationSuccess, requestError } from '../../../helpers/notification';
import { ReactComponent as FileTreeSvg } from '../../../assets/icons/file-tree.svg';
import { ReactComponent as SettingsSvg } from '../../../assets/icons/settings.svg';
import { ReactComponent as TrashSvg } from '../../../assets/icons/trash.svg';
import { ReactComponent as ShareSvg } from '../../../assets/icons/share.svg';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as RenameSvg } from '../../../assets/icons/rename.svg';
import { ReactComponent as RefreshSvg } from '../../../assets/icons/refresh.svg';
import PrintMenu from '../../menu/PrintMenu';
import ImportMenu from '../../menu/ImportMenu';
import FilterModal from '../../filters/FilterModal';
import { PrintMenuContextProvider } from '../../../contexts/app/PrintMenuContext';
import GanttSetting from '../../plannings/GanttSetting';
import FavoriteToggle from '../../plannings/FavoriteToggle';
import { getPlanningAccess, USER_ACCESS } from '../../../helpers/planning';
import BaseLineDropdown from '../../dropdown/BaseLineDropdown';
import ExportMenu from '../../menu/ExportMenu';
import NeedsMenu from '../../needs/NeedsMenu';
import JobsInfo from '../../extra/JobsInfo';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
    &:disabled {
        opacity: 0.5;
    }
`;

const TopMenuContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 4px;
    overflow: visible;
    height: 43px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    margin-top: 16px;
    p {
        position: relative;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        font-size: 11px;
        top: -24px;
        left: -5px;
        padding: 2px 4px;
        width: fit-content;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 500ms;
    }
    .button-container {
        top: -19px;
    }
    &:hover {
        border: 1px solid #000;
        p {
            background: rgba(0, 0, 0, 1);
        }
    }
`;
const TopMenu = () => {
    const {
        planningSelected,
        planningCustomFields,
        refreshPlanningSelected,
        modeSelected,
        updatePlanning,
        setRefreshGantt,
        disableReloading,
        setDisableReloading,
        processingGantt,
        loadingGantt,
        canAccess,
    } = useContext(PlanningContext);
    const { currentUser } = useContext(UserContext);
    const { tabPreferences, updateTabPreferences } = useContext(TabContext);
    const [loadingPlanify, setLoadingPlanify] = useState(false);
    const DeletePlanningModalRef = useRef();
    const SharePlanningModelRef = useRef();
    const GroupingModalRef = useRef();
    const EditPlanningModalRef = useRef();
    const [disableReloadingButton, setDisableReloadingButton] = useState(disableReloading);
    const { t } = useTranslation('translation', { keyPrefix: 'top_menu' });

    const planningAccess = getPlanningAccess(planningSelected, currentUser);

    useEffect(() => {
        setDisableReloadingButton(disableReloading);
    }, [disableReloading]);

    const planifyPlanning = () => {
        setLoadingPlanify(true);
        ActivityService.reschedulePlanning(planningSelected.rootActivityId)
            .then(() => {
                setLoadingPlanify(false);
                notificationSuccess(t('rescheduling_success'));
                const newPlanning = {
                    ...planningSelected,
                };
                // if (newPlanning.summaryId) {
                //     delete newPlanning.summaryId
                // }
                // window.ganttInstance.clearAll();
                updatePlanning(newPlanning, false);
            })
            .catch((error) => {
                setLoadingPlanify(false);
                Sentry.captureException(error);

                if (error?.response?.data?.type === 'forbiden_access_operation') {
                    notificationError(t('rescheduling_failed'), t('rescheduling_failed_forbidden'));
                } else {
                    requestError(error, t('rescheduling_failed'));
                }
            });
    };

    // KEYBOARD SHORTCUT REPLANIFIER
    useHotkeys('f9', () => planifyPlanning());

    const updatePlanningColor = (value) => {
        updateTabPreferences({
            planning_color:
                value === null ? null : {id: planningCustomFields.find((customField) => customField.id === value).id},
        }).then(() => {
            refreshPlanningSelected();
        });
    };

    const doRefreshGantt = () => {
        setRefreshGantt((old) => old + 1);
        if (!disableReloadingButton) {
            setDisableReloading(true);
        }
    };


    const canEditGeneral =
        (planningAccess === USER_ACCESS.OWNER || planningAccess === USER_ACCESS.ADMIN) && !currentUser.readOnly;
    const isPlanningColorFieldExist = useMemo(() => {
        if (
            !isNil(tabPreferences?.planning_color) &&
            planningCustomFields.find((field) => 
            (field.id === tabPreferences?.planning_color?.id)
            ) === undefined
        ) {
            return 'error';
        }
            return ''
    }, [planningCustomFields, tabPreferences]);
    return (
        <div className="w-full flex justify-between items-center bg-secondary-light rounded-md p-1">
            <JobsInfo />
            <div className="flex items-center">
                <TopMenuContainer>
                    <p>{t('general')}</p>
                    <div
                        className="button-container relative flex items-center justify-evenly"
                        style={{ width: '174px' }}
                    >
                        <Tooltip title={t('share_planning')}>
                            <QuickButton
                                type="button"
                                className={`inline-block ${
                                    modeSelected !== 'sandbox' ? 'bg-primary' : 'bg-secondary cursor-not-allowed'
                                }`}
                                onClick={SharePlanningModelRef.current && SharePlanningModelRef.current.openModal}
                                disabled={!canEditGeneral || modeSelected === 'sandbox'}
                            >
                                <ShareSvg className="mx-auto" />
                            </QuickButton>
                        </Tooltip>

                        <FavoriteToggle />

                        <Tooltip title={t('rename_planning')}>
                            <QuickButton
                                type="button"
                                className={`inline-block  ${
                                    modeSelected !== 'sandbox' ? 'bg-primary' : 'bg-secondary cursor-not-allowed'
                                }`}
                                onClick={EditPlanningModalRef.current && EditPlanningModalRef.current.openModal}
                                disabled={!canEditGeneral || modeSelected === 'sandbox'}
                            >
                                <RenameSvg style={{ width: '24px', height: '24px' }} className="mx-auto" />
                            </QuickButton>
                        </Tooltip>

                        <Tooltip title={t('delete_planning')}>
                            <QuickButton
                                type="button"
                                disabled={!canEditGeneral}
                                className="inline-block bg-primary text-center"
                                title={t('delete_planning')}
                                onClick={DeletePlanningModalRef.current && DeletePlanningModalRef.current.openModal}
                            >
                                <TrashSvg />
                            </QuickButton>
                        </Tooltip>
                    </div>
                </TopMenuContainer>
                {!currentUser.readOnly && (
                    <TopMenuContainer className="ml-3">
                        <p>{t('datas')}</p>
                        <div
                            className="button-container relative flex items-center justify-evenly"
                            style={{ width: '120px' }}
                        >
                            <CustomFieldsMenuContextProvider>
                                <CustomFieldsModal />
                            </CustomFieldsMenuContextProvider>
                            {canAccess() && <NeedsMenu isPlanning isReadOnly={modeSelected === 'archive'} />}
                            {!processingGantt && !loadingGantt && canAccess() && modeSelected !== 'archive' && <ImportMenu />}
                        </div>
                    </TopMenuContainer>
                )}
                <TopMenuContainer className="ml-3">
                    <p>{t('gantt_display')}</p>
                    <div
                        className="button-container relative flex items-center justify-evenly"
                        style={{ width: '464px' }}
                    >
                        <GanttSetting />

                        <Tooltip title={t('activity_organization')}>
                            <QuickButton
                                type="button"
                                className="inline-block bg-primary"
                                title={t('activity_organization')}
                                onClick={GroupingModalRef.current && GroupingModalRef.current.openModal}
                            >
                                <FileTreeSvg className="mx-auto" />
                            </QuickButton>
                        </Tooltip>

                        {!loadingGantt && <FilterModal />}
                        <div className="flex items-center">
                            {tabPreferences?.planning_color && (
                                <CloseSvg onClick={() => updatePlanningColor(null)} className="cursor-pointer" />
                            )}
                            <Select
                                placeholder={t('activity_color')}
                                style={{ width: '200px', boxShadow: 'inset 2px 3px 3px rgba(130, 130, 130, 0.15)' }}
                                options={planningCustomFields
                                    .filter((customField) => customField.type.choice)
                                    .map((customField) => ({ label: customField.name, value: customField.id }))}
                                popupClassName="rounded"
                                value={tabPreferences?.planning_color?.id}
                                onSelect={(value) => updatePlanningColor(value)}
                                className="inline-block"
                                status={isPlanningColorFieldExist}
                            />
                        </div>
                        {!currentUser.readOnly && (
                            <>
                                <Tooltip title={t('reload_planning')}>
                                    <QuickButton
                                        type="button"
                                        className="inline-block bg-primary text-center"
                                        title={t('reload_planning')}
                                        onClick={doRefreshGantt}
                                    >
                                        <RefreshSvg style={{ width: '18px', height: '18px' }} className="mx-auto" />
                                    </QuickButton>
                                </Tooltip>
                                {canAccess() && (
                                    <Tooltip title={t('activity_reschedule')}>
                                        <QuickButton
                                            type="button"
                                            className={`inline-block text-center ${
                                                modeSelected !== 'archive'
                                                    ? 'bg-primary'
                                                    : 'bg-secondary cursor-not-allowed'
                                            }`}
                                            title={t('activity_reschedule')}
                                            onClick={planifyPlanning}
                                            disabled={modeSelected === 'archive'}
                                        >
                                            <SettingsSvg
                                                className={`mx-auto${loadingPlanify ? ' animate-spin' : ''}`}
                                            />
                                        </QuickButton>
                                    </Tooltip>
                                )}
                                <BaseLineDropdown />
                            </>
                        )}
                    </div>
                </TopMenuContainer>
            </div>
            <div className="flex items-center">
                {canAccess() && <ValidatePlanningModal />}
                {!processingGantt && !loadingGantt && <ExportMenu />}
                <PrintMenuContextProvider>
                    <PrintMenu />
                </PrintMenuContextProvider>
            </div>
            {planningSelected && canAccess() && (
                <DeletePlanningModal
                    key={`delete-planning-${planningSelected.id}-modal`}
                    ref={DeletePlanningModalRef}
                />
            )}
            {planningSelected && canAccess() && (
                <SharePlanningModal key={`share-planning-${planningSelected.id}-modal`} ref={SharePlanningModelRef} />
            )}
            {planningSelected && (
                <EditPlanningModal key={`edit-planning-${planningSelected.id}-modal`} ref={EditPlanningModalRef} />
            )}
            <GroupingModal key={`grouping-planning-${planningSelected.id}-modal`} ref={GroupingModalRef} />
        </div>
    );
};

export default TopMenu;
