import { isEqual, isNil } from 'lodash';
import { JOB_FIELD } from '../../constants/Jobs';
import Condition from './converter/model/Condition';
import Filter from './converter/model/Filter';
import Operator from './converter/model/Operator';
// import Condition from "./converter/model/Condition";

export const customFieldsIds = {
    startDate: -4,
    endDate: -5,
    ancestor: -20,
    valid: -23,
    progress: -7,
    calendar: -21,
    duration: -17,
    durationAndUnit: -10,
    durationUnit: -14,
    order: -15,
    impact: -16,
    quantity: -11,
    quantityAndUnit: -18,
    workrateAndUnit: -19,
    workrate: -13,
    quantityUnit: -12,
    needs: -24,
    // TODO change to identity id
    identity: -1
};

export const excludedFields = [customFieldsIds.duration, customFieldsIds.order, customFieldsIds.impact, customFieldsIds.workrateAndUnit, customFieldsIds.needs, customFieldsIds.quantityAndUnit]

export const conditionEnum = Object.freeze({
    DIFFERENT: { label: '!=', name: 'DIFFERENT' },
    EQUAL: { label: '==', name: 'EGAL' },
    LOWER: { label: '<', name: 'INFERIEUR' },
    LOWER_OR_EQUAL: { label: '<=', name: 'INFERIEUROUEGAL' },
    UPPER: { label: '>', name: 'SUPERIEUR' },
    UPPER_OR_EQUAL: { label: '>=', name: 'SUPERIEUROUEGAL' },
    LIKE: { label: 'Contient', name: 'MULTICONTIENT' },
    NOT_LIKE: { label: 'Contient pas', name: 'MULTINECONTIENTPAS' },
    MULTICONTAINS: { label: 'Contient', name: 'MULTICONTIENT' },
    NOT_MULTICONTAINS: { label: 'Contient pas', name: 'MULTINECONTIENTPAS' },
});

export const getCustomFieldInfo = (customField) => {
    // if ([customFieldsIds.startDate, customFieldsIds.endDate].includes(customField.id)) {
    //   return { type: 'date', data: 'date' };
    // }
    if (customField.id === customFieldsIds.startDate) {
        return { type: 'startDate', data: 'date' };
    }
    if (customField.id === customFieldsIds.endDate) {
        return { type: 'endDate', data: 'date' };
    }
    if (customField.id === customFieldsIds.progress) {
        return { type: 'numberMinMax', data: customFieldsIds.progress };
    }
    if (customField.id === customFieldsIds.quantity) {
        return { type: 'numberMinMax', data: customFieldsIds.quantity };
    }
    if (customField.id === customFieldsIds.workrate) {
        return { type: 'numberMinMax', data: customFieldsIds.workrate };
    }
    if (customField.id === customFieldsIds.durationAndUnit) {
        return { type: 'numberMinMaxAndSelect', data: customFieldsIds.durationAndUnit };
    }
    if (customField.id === customFieldsIds.quantityAndUnit) {
        return { type: 'numberMinMaxAndSelect', data: customFieldsIds.quantityAndUnit };
    }
    if (customField.id === customFieldsIds.calendar) {
        return { type: 'select', data: customFieldsIds.calendar };
    }
    if (customField.id === customFieldsIds.quantityUnit) {
        return { type: 'select', data: customFieldsIds.quantityUnit };
    }
    if (customField.id === customFieldsIds.durationUnit) {
        return { type: 'select', data: customFieldsIds.durationUnit };
    }
    if (customField.id === customFieldsIds.ancestor) {
        return { type: 'searchSelect', data: customFieldsIds.ancestor };
    }
    if (customField.id === customFieldsIds.valid) {
        return { type: 'select', data: customFieldsIds.valid };
    }
    if (customField.id === customFieldsIds.identity) {
        return { type: 'text', data: customFieldsIds.identity };
    }
    if (customField.name === JOB_FIELD) {
        return { type: 'selectOrValue', data: JOB_FIELD };
    }
    if (customField.type.choice) {
        return { type: 'select', data: 'choice' };
    }
    if (customField.type.numeric) {
        return { type: 'number' };
    }
    return { type: 'text', data: 'text' };
};
// export const getFieldType = (customField) => {
//   if ([customFieldsIds.startDate, customFieldsIds.endDate].includes(customField.id)) {
//       return {type:'date'};
//   }
//   if (customField.id === customFieldsIds.ancestor || customField.id === customFieldsIds.valid || customField.type.choice) {
//       return 'select';
//   }
//   if (customField.type.numeric) {
//     return 'number';
//   }
//   if (customField.name === JOB_FIELD) {
//     return 'multiselect'
//   }
//   return 'text';
// };

export const getOperatorsOfType = (customField) => {
    switch (getCustomFieldInfo(customField).data) {
        case JOB_FIELD:
            return [conditionEnum.EQUAL, conditionEnum.DIFFERENT, conditionEnum.LIKE, conditionEnum.NOT_LIKE];
        case 'choice':
        case customFieldsIds.valid:
            return [conditionEnum.DIFFERENT, conditionEnum.EQUAL]
        case customFieldsIds.identity:
        case 'text':
            return [conditionEnum.DIFFERENT, conditionEnum.EQUAL, conditionEnum.LIKE, conditionEnum.NOT_LIKE];
        case customFieldsIds.ancestor:
            return [conditionEnum.MULTICONTAINS, conditionEnum.NOT_MULTICONTAINS];
        case customFieldsIds.quantityUnit:
        case customFieldsIds.durationUnit:
        case customFieldsIds.calendar:
            return [conditionEnum.EQUAL, conditionEnum.DIFFERENT];
        case 'number':
        case 'date':
        default:
            // Number or Date
            return [
                conditionEnum.DIFFERENT,
                conditionEnum.EQUAL,
                conditionEnum.LOWER,
                conditionEnum.LOWER_OR_EQUAL,
                conditionEnum.UPPER,
                conditionEnum.UPPER_OR_EQUAL,
            ];
    }
};

export const combinators = (i18n) => [
    { name: 'ET', label: i18n.t('querybuilder.and') },
    { name: 'OU', label: i18n.t('querybuilder.or') },
];

export const getDefaultValue = (choices) => choices[0]?.value;

export const queryBuilderLabels = (i18n, prefix = 'querybuilder') => ({
    fields: {
        title: i18n.t(`${prefix}.fields`),
        placeholderName: '~',
        placeholderLabel: i18n.t(`${prefix}.choose_field`),
        placeholderGroupLabel: '------',
    },
    operators: {
        title: i18n.t(`${prefix}.operators`),
        placeholderName: '~',
        placeholderLabel: '------',
        placeholderGroupLabel: '------',
    },
    value: {
        title: i18n.t(`${prefix}.value`),
    },
    removeRule: {
        label: 'x',
        title: i18n.t(`${prefix}.remove_rule`),
    },
    removeGroup: {
        label: 'x',
        title: i18n.t(`${prefix}.remove_group`),
    },
    addRule: {
        label: i18n.t(`${prefix}.add_rule_plus`),
        title: i18n.t(`${prefix}.add_rule`),
    },
    addGroup: {
        label: i18n.t(`${prefix}.add_group_plus`),
        title: i18n.t(`${prefix}.add_group`),
    },
    combinators: {
        title: i18n.t(`${prefix}.combinators`),
    },
    // "notToggle": {
    //   "label": "Not",
    //   "title": "Invert this group"
    // },
    // "cloneRule": {
    //   "label": "⧉",
    //   "title": "Clone rule"
    // },
    // "cloneRuleGroup": {
    //   "label": "⧉",
    //   "title": "Clone group"
    // },
    // "dragHandle": {
    //   "label": "⁞⁞",
    //   "title": "Drag handle"
    // },
    // "lockRule": {
    //   "label": "🔓",
    //   "title": "Lock rule"
    // },
    // "lockGroup": {
    //   "label": "🔓",
    //   "title": "Lock group"
    // },
    // "lockRuleDisabled": {
    //   "label": "🔒",
    //   "title": "Unlock rule"
    // },
    // "lockGroupDisabled": {
    //   "label": "🔒",
    //   "title": "Unlock group"
    // }
});

export const defaultQuery = {
    combinator: 'ET',
    rules: [],
};

const convertOperatorChildren = (rules, timeUnits, customFields, isArchive) => {
    // Children
    const children = [];
    if (rules.length > 0) {
        rules.forEach((rule) => {
            if (Object.hasOwn(rule, 'combinator')) {
                const operatorChild = new Operator(rule.combinator, []);
                operatorChild.children = convertOperatorChildren(rule.rules, timeUnits, customFields, isArchive);
                children.push(operatorChild);
            }
            if (Object.hasOwn(rule, 'field')) {
                if (!isNil(rule.value)) {
                    let valueToSend = rule.value;
                    if (typeof rule.value === 'object') {
                        valueToSend = [Number(rule.value.value).toFixed(1), rule.value.unit].join(' ');
                        if (rule.field === customFieldsIds.durationAndUnit) {
                            const durationUnit =  timeUnits.find(
                                (timeUnit) => timeUnit.id === Number(rule.value.unit)
                            );
                            valueToSend = rule.value.value * durationUnit.duration
                        }
                    }
                    let fieldId = rule.field;
                    if (isArchive) {
                        // test if customField
                        const customField = customFields.find((i) => i.id === rule.field) 
                        if (customField) {
                            fieldId = customField.realId;
                        }
                    }
                    const condition = new Condition(fieldId, rule.operator, valueToSend);
                    children.push(condition);
                }
            }
        });
    }
    return children;
};

export const queryBuilderToUserFilter = (query, timeUnits, customFields, isArchive) => {
    // Root Operator
    const rootOperator = new Operator(query.combinator, []);
    // Children
    if (query.rules.length > 0) rootOperator.children = convertOperatorChildren(query.rules, timeUnits, customFields, isArchive);
    const filter = new Filter('Filter', rootOperator);
    return filter;
};

export const isSameFilter = (filter1, filter2) => filter1.id === filter2.id && isEqual(filter1.data, filter2.data);

export const genericValidator = (rule) => !!rule.value;
export const dateValidator = (rule) => rule.value !== '';

export const choiceValidator = (rule, list, field = 'name') => !!list.find((i) => i[field] === rule.value);

export const jobValidator = (rule, list) => {
    if (rule.operator === conditionEnum.EQUAL.name  || rule.operator === conditionEnum.DIFFERENT.name) {
        return choiceValidator(rule, list, 'value')
    }
    return true;
};

export const isFieldExist = (fields, field) => !!fields.find((i) => i.name === field);

export const defaultValidator = (query) => {
    const result = {};

    const validateRule = () => {
        // Replace this with your custom implementation.
        // Inside this function, set `result[_rule.id] = true` for a valid
        // rule, or `{ valid: false, reasons: ['your', 'reasons', 'here'] }`
        // for an invalid rule.
    };

    const validateGroup = (rg) => {
        const reasons = [];
        if (rg.rules.length === 0) {
            reasons.push('groupInvalidReasons.empty');
        }
        /* istanbul ignore else */
        if (rg.id) {
            if (reasons.length) {
                result[rg.id] = { valid: false, reasons };
            } else {
                result[rg.id] = true;
            }
        }
        rg.rules.forEach((r) => {
            if (typeof r === 'string') {
                // Validation for this case was done earlier
            } else if ('rules' in r) {
                validateGroup(r);
            } else {
                validateRule(r);
            }
        });
    };

    validateGroup(query);

    return result;
    // You can return the result object itself like above, or if you just
    // want the entire query to be marked invalid if _any_ rules/groups are
    // invalid, return a boolean like this:
    //   return Object.values(result).map(rv => (typeof rv !== 'boolean')).includes(true);
    // That will return `true` if no errors were found.
};

export const externalValidator = (query, fieldsMap) => {
    const result = {};

    const validateRule = (rule) => {
        const field = fieldsMap[rule.field];
        if (!field) {
            result[rule.id] = { valid: false, reasons: 'Field not existing' };
            return;
        }
        if (
            (field.inputType === 'select' ||
            field.inputType === 'multiselect') && choiceValidator(rule, field.values) === false
            ) {
            result[rule.id] = { valid: false, reasons: 'Choice not existing' };
            return;
        }
        result[rule.id] = true;
    };

    const validateGroup = (rg) => {
        const reasons = [];
        if (rg.rules.length === 0) {
            reasons.push('groupInvalidReasons.empty');
        }
        /* istanbul ignore else */
        if (rg.id) {
            if (reasons.length) {
                result[rg.id] = { valid: false, reasons };
            } else {
                result[rg.id] = true;
            }
        }
        rg.rules.forEach((r) => {
            if (typeof r === 'string') {
                // Validation for this case was done earlier
            } else if ('rules' in r) {
                validateGroup(r);
            } else {
                validateRule(r);
            }
        });
    };

    validateGroup(query);

    // return result;
    // You can return the result object itself like above, or if you just
    // want the entire query to be marked invalid if _any_ rules/groups are
    // invalid, return a boolean like this:
      return Object.values(result).map(rv => (typeof rv !== 'boolean')).includes(true);
    // That will return `true` if no errors were found.
};

export default conditionEnum;
