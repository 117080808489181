import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Typography} from 'antd';
import background from '../assets/images/guest-background.jpg';

const { Title } = Typography;
const NotFound = () => (
        <div className="h-screen bg-cover overflow-hidden" style={{ backgroundImage: `url('${background}')` }}>
            <header>
                <Row className="bg-primary justify-between h-12 px-12">
                    <Col span={14} className="flex items-center">
                        <Title level={3} style={{ marginBottom: '0px', color: 'white', fontWeight: 'normal' }}>
                            Gantt Manager
                        </Title>
                    </Col>
                </Row>
            </header>
            <div className="h-screen flex items-center justify-center text-white" style={{ backgroundColor: 'rgba(0,0,0,0.5)', flexDirection: 'column' }}>
                <p style={{fontSize: '90px'}}>404</p>
                <label>La page demandée n&apos;existe pas ou vous n&apos;avez pas les droits.</label>
                <Link to="/" >Cliquez ici pour revenir à la page d&apos;accueil.</Link>
            </div>
        </div>
    );
;

export default NotFound;
