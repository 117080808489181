import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import ProjectsList from '../projects/ProjectsList';

const ProjectMenu = () => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Menu.Item key="project_menu" onClick={showModal}>
                <FontAwesomeIcon icon={faFolder} fixedWidth />
                {t('menu.project_management')}
            </Menu.Item>
            <Modal
                title={t('menu.project_management')}
                className="project-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                width={926}
                height={700}
                footer={null}
                destroyOnClose
            >
                <ProjectsList />
            </Modal>
        </>
    );
};

export default ProjectMenu;
