import React, { useState } from 'react';
import { Tabs } from 'antd';
import DraggableTabNode from './DraggableTabNode';

const DraggableTabs = (props) => {
    const { children, onNewOrder } = props;
    const [order, setOrder] = useState([]);

    const moveTabNode = (dragKey, hoverKey) => {
        const newOrder = order.slice();
        React.Children.forEach(children, (c) => {
            if (c.key && newOrder.indexOf(c.key) === -1) {
                newOrder.push(c.key);
            }
        });
        const dragIndex = newOrder.indexOf(dragKey);
        const hoverIndex = newOrder.indexOf(hoverKey);
        newOrder.splice(dragIndex, 1);
        newOrder.splice(hoverIndex, 0, dragKey);
        if (onNewOrder) onNewOrder(newOrder);
        setOrder(newOrder);
    };
    const renderTabBar = (tabBarProps, DefaultTabBar) => (
        // eslint-disable-next-line
        <DefaultTabBar {...tabBarProps}>
            {(node) => (
                <DraggableTabNode key={node.key} index={node.key} moveNode={moveTabNode}>
                    {node}
                </DraggableTabNode>
            )}
        </DefaultTabBar>
    );

    const tabs = [];
    React.Children.forEach(children, (c) => {
        tabs.push(c);
    });
    const orderTabs = tabs.slice().sort((a, b) => {
        const orderA = order.indexOf(a.key);
        const orderB = order.indexOf(b.key);

        if (orderA !== -1 && orderB !== -1) {
            return orderA - orderB;
        }

        if (orderA !== -1) {
            return -1;
        }

        if (orderB !== -1) {
            return 1;
        }

        const ia = tabs.indexOf(a);
        const ib = tabs.indexOf(b);
        return ia - ib;
    });
    /* eslint-disable */
    return (
        <Tabs renderTabBar={renderTabBar} {...props} type="editable-card">
            {orderTabs}
        </Tabs>
    );
};

export default DraggableTabs;
