import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
/* eslint no-unused-vars: "off" */
/* eslint no-param-reassign: "off" */

import { PropTypes } from 'prop-types';
import { Select, Row, Col, Button, Form, Input, Checkbox } from 'antd';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import DeleteButton from '../../common/tables/DeleteButton';

const { Option } = Select;

const documentFonts = [
    'Lato',
    'Arial',
    'Times New Roman',
    'Courier New',
    'Palatino',
    'Garamond',
    'Bookman',
    'Verdana',
    'Tahoma',
    'Georgia',
    'Comic Sans MS',
    'Lucida Console',
    'Trebuchet MS',
    'Arial Black',
    'Impact',
].sort((a, b) => a.localeCompare(b));

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ColorPicker = styled.div`
    position: absolute;
    z-index: 2;
`;

// This component is used for customizing Gantt levels

const LevelSetting = ({ tabPreferences, setNewPreferences }) => {
    const {t} = useTranslation('translation', {keyPrefix: 'gantt_setting'})
    const [form] = Form.useForm();
    const [fontList] = useState(documentFonts);

    const defaultLevelParam = {
        show: false,
        showPicker: false,
        showPolicePicker: false,
        color: '#ffffff',
        fontColor: '#000000',
        fontSize: '1em',
        fontStyle: 'normal',
    };
    // gantt police
    const defaultGanttParameters = {
        fontFamily: 'Lato',
    };
    const [ganttParameters, setGanttParameters] = useState([]);

    const [levelParameters, setLevelParameters] = useState([]);
    // simple activity police param
    const [simpleActivityParameters, setSimpleActivityParameters] = useState([]);

    const { planningSelected } = useContext(PlanningContext);
    const parametersRef = useRef(null);

    // police choices
    const textSizes = [
        { label: t('font_size_sm'), value: '0.75em' },
        { label:  t('font_size_normal'), value: '1em' },
        { label:  t('font_size_big'), value: '1.5em' },
    ];
    const textStyles = [
        { label: t('font_size_normal'), value: 'normal' },
        { label: t('font_style_bold'), value: 'bold' },
        { label: t('font_style_italic'), value: 'italic' },
        { label: t('font_style_bold_italic'), value: 'bold-italic' },
    ];

    useEffect(() => {
        if (levelParameters && simpleActivityParameters && ganttParameters) {
            parametersRef.current = {
                levelParameters,
                simpleActivityParameters,
                ganttParameters,
            };
            const newPreferences = {
                ...tabPreferences,
                gantt_level_parameters: parametersRef.current.levelParameters,
                
                gantt_simple_activity_parameters: parametersRef.current.simpleActivityParameters,
                
                gantt_parameters: {
                        ...(tabPreferences.gantt_parameters ?? {}),
                        ...parametersRef.current.ganttParameters,
                    }
            };
            setNewPreferences(newPreferences)
        }
    }, [levelParameters, simpleActivityParameters, ganttParameters]);

    useEffect(() => {
        if (tabPreferences?.gantt_level_parameters) {
            setLevelParameters(() => {
                const newValue = [
                    ...tabPreferences?.gantt_level_parameters.map((i) => ({
                        ...defaultLevelParam,
                        ...i,
                    })),
                ];
                return newValue;
            });
        }
        if (tabPreferences?.gantt_parameters) {
            setGanttParameters(() => {
                const newValue = {
                    ...defaultGanttParameters,
                    ...tabPreferences?.gantt_parameters,
                };
                return newValue;
            });
        }
        if (tabPreferences?.gantt_simple_activity_parameters) {
            setSimpleActivityParameters(() => {
                const newValue = {
                    ...defaultLevelParam,
                    ...tabPreferences?.gantt_simple_activity_parameters,
                };
                return newValue;
            });
        }
    }, [planningSelected]);

    return (
        <>
            {ganttParameters && levelParameters && simpleActivityParameters && (
                <Form form={form} layout="horizontal" name="planning_parameter_display" style={{ minWidth: '650px' }}>
                    <div style={{ height: '60vh', overflowY: 'auto' }}>
                        <Row>
                            <Col span={4}>{t('used_font')} </Col>
                            <Col span={17}>
                                <Form.Item className="w-2/3">
                                    <Select
                                        value={ganttParameters.fontFamily}
                                        className="w-full"
                                        onChange={(e) => {
                                            setGanttParameters((old) => ({ ...old, fontFamily: e }));
                                        }}
                                    >
                                        {fontList.map((item) => (
                                            <Option
                                                key={`family${item}`}
                                                value={item}
                                                style={{ fontFamily: `${item} !important` }}
                                            >
                                                <span style={{ fontFamily: item }}>{item}</span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row key="headercolorlevel">
                            <Col span={4} className="flex items-center">
                                &nbsp;
                            </Col>
                            <Col span={17} className="flex justify-between">
                                <Form.Item colon={false} className="m-0" label={t('taskbar')}/>
                                <Form.Item colon={false} className="m-0" label={t('text')} />
                                <Form.Item colon={false} label={t('font_size')} className=" w-1/5 mr-2 m-0" />

                                <Form.Item colon={false} label={t('font_style')}className=" w-1/5 m-0" />
                            </Col>
                            <Col span={3} className="flex items-center">
                                &nbsp;
                            </Col>
                        </Row>
                        <Row key="simplecolorlevel" className="mb-3">
                            <Col span={4} className="flex items-center">
                                <Checkbox
                                    checked={simpleActivityParameters.show}
                                    className="font-thin"
                                    style={{ fontFamily: 'inherit !important' }}
                                    onChange={(e) => {
                                        setSimpleActivityParameters((old) => ({
                                            ...old,
                                            show: e.target.checked,
                                        }));
                                    }}
                                >
                                    {t('simple_activity')}
                                </Checkbox>
                            </Col>
                            <Col span={17} className="flex justify-between items-center">
                                <Form.Item className=" m-0">
                                    <Input
                                        type="color"
                                        value={simpleActivityParameters.color}
                                        style={{ width: '45px' }}
                                        onClick={(event) => {
                                            setSimpleActivityParameters((old) => ({
                                                ...old,
                                                showPicker: true,
                                            }));
                                            event.preventDefault();
                                        }}
                                    />
                                    {simpleActivityParameters.showPicker && (
                                        <ColorPicker>
                                            <ColorCover
                                                onClick={() => {
                                                    setSimpleActivityParameters((old) => ({
                                                        ...old,
                                                        showPicker: false,
                                                    }));
                                                }}
                                            />
                                            <SketchPicker
                                                color={simpleActivityParameters.color}
                                                onChangeComplete={(color) => {
                                                    setSimpleActivityParameters((old) => ({
                                                        ...old,
                                                        color: color.hex,
                                                    }));
                                                }}
                                                disableAlpha
                                            />
                                        </ColorPicker>
                                    )}
                                </Form.Item>
                                <Form.Item className="m-0">
                                    <Input
                                        type="color"
                                        style={{ width: '45px' }}
                                        value={simpleActivityParameters.fontColor}
                                        onClick={(event) => {
                                            setSimpleActivityParameters((old) => ({
                                                ...old,
                                                showPolicePicker: true,
                                            }));
                                            event.preventDefault();
                                        }}
                                    />
                                    {simpleActivityParameters.showPolicePicker && (
                                        <ColorPicker>
                                            <ColorCover
                                                onClick={() => {
                                                    setSimpleActivityParameters((old) => ({
                                                        ...old,
                                                        showPolicePicker: false,
                                                    }));
                                                }}
                                            />
                                            <SketchPicker
                                                color={simpleActivityParameters.fontColor}
                                                onChangeComplete={(color) => {
                                                    setSimpleActivityParameters((old) => ({
                                                        ...old,
                                                        fontColor: color.hex,
                                                    }));
                                                }}
                                                disableAlpha
                                            />
                                        </ColorPicker>
                                    )}
                                </Form.Item>
                                <Form.Item className=" w-1/5 mr-2 mb-0">
                                    <Select
                                        style={{ width: '100px' }}
                                        onChange={(e) => {
                                            setSimpleActivityParameters((old) => ({
                                                ...old,
                                                fontSize: e,
                                            }));
                                        }}
                                        value={simpleActivityParameters.fontSize}
                                        options={textSizes}
                                    />
                                </Form.Item>
                                <Form.Item className="flex w-1/5 mb-0">
                                    <Select
                                        style={{ width: '100px' }}
                                        onChange={(e) => {
                                            setSimpleActivityParameters((old) => ({
                                                ...old,
                                                fontStyle: e,
                                            }));
                                        }}
                                        value={simpleActivityParameters.fontStyle}
                                        options={textStyles}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="flex justify-end items-center">
                                &nbsp;
                            </Col>
                        </Row>
                        {levelParameters &&
                            levelParameters.map((i, index) => (
                                /* eslint-disable-next-line */
                                <Row key={`${index}colorlevel`} className="mb-3">
                                    <Col span={4} className="flex items-center">
                                        <Checkbox
                                            checked={i.show}
                                            className="font-thin"
                                            style={{ fontFamily: 'inherit !important' }}
                                            onChange={(e) => {
                                                setLevelParameters((old) => {
                                                    const newVal = [...old];
                                                    newVal[index].show = e.target.checked;
                                                    return newVal;
                                                });
                                            }}
                                        >
                                            {`Niveau ${index}`}
                                        </Checkbox>
                                    </Col>
                                    <Col span={17} className="flex justify-between items-center">
                                        <Form.Item className=" m-0">
                                            <Input
                                                type="color"
                                                value={i.color}
                                                style={{ width: '45px' }}
                                                onClick={(event) => {
                                                    setLevelParameters((old) => {
                                                        const newVal = [...old];
                                                        newVal[index].showPicker = true;
                                                        return newVal;
                                                    });
                                                    event.preventDefault();
                                                }}
                                            />
                                            {i.showPicker && (
                                                <ColorPicker>
                                                    <ColorCover
                                                        onClick={() => {
                                                            setLevelParameters((old) => {
                                                                const newVal = [...old];
                                                                newVal[index].showPicker = false;
                                                                return newVal;
                                                            });
                                                        }}
                                                    />
                                                    <SketchPicker
                                                        color={i.color}
                                                        onChangeComplete={(color) => {
                                                            setLevelParameters((old) => {
                                                                const newVal = [...old];
                                                                newVal[index].color = color.hex;
                                                                return newVal;
                                                            });
                                                        }}
                                                        disableAlpha
                                                    />
                                                </ColorPicker>
                                            )}
                                        </Form.Item>
                                        <Form.Item className="m-0">
                                            <Input
                                                type="color"
                                                style={{ width: '45px' }}
                                                value={i.fontColor}
                                                onClick={(event) => {
                                                    setLevelParameters((old) => {
                                                        const newVal = [...old];
                                                        newVal[index].showPolicePicker = true;
                                                        return newVal;
                                                    });
                                                    event.preventDefault();
                                                }}
                                            />
                                            {i.showPolicePicker && (
                                                <ColorPicker>
                                                    <ColorCover
                                                        onClick={() => {
                                                            setLevelParameters((old) => {
                                                                const newVal = [...old];
                                                                newVal[index].showPolicePicker = false;
                                                                return newVal;
                                                            });
                                                        }}
                                                    />
                                                    <SketchPicker
                                                        color={i.fontColor}
                                                        onChangeComplete={(color) => {
                                                            setLevelParameters((old) => {
                                                                const newVal = [...old];
                                                                newVal[index].fontColor = color.hex;
                                                                return newVal;
                                                            });
                                                        }}
                                                        disableAlpha
                                                    />
                                                </ColorPicker>
                                            )}
                                        </Form.Item>
                                        <Form.Item className=" w-1/5 mr-2 mb-0">
                                            <Select
                                                style={{ width: '100px' }}
                                                onChange={(e) => {
                                                    setLevelParameters((old) => {
                                                        const newVal = [...old];
                                                        newVal[index].fontSize = e;
                                                        return newVal;
                                                    });
                                                }}
                                                value={i.fontSize}
                                                options={textSizes}
                                            />
                                        </Form.Item>
                                        <Form.Item className="flex w-1/5 mb-0">
                                            <Select
                                                style={{ width: '100px' }}
                                                onChange={(e) => {
                                                    setLevelParameters((old) => {
                                                        const newVal = [...old];
                                                        newVal[index].fontStyle = e;
                                                        return newVal;
                                                    });
                                                }}
                                                value={i.fontStyle}
                                                options={textStyles}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className="flex justify-end items-center">
                                        <DeleteButton
                                            onClick={() =>
                                                setLevelParameters((old) => {
                                                    const newVal = [...old];
                                                    newVal.splice(index, 1);
                                                    return newVal;
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                            ))}
                    </div>

                    <Button
                        type="primary"
                        onClick={() =>
                            setLevelParameters((old) => {
                                const newVal = [...old, { ...defaultLevelParam }];
                                return newVal;
                            })
                        }
                    >
                        {t('add_level')}
                    </Button>
                </Form>
            )}
        </>
    );
};

LevelSetting.propTypes = {
    setNewPreferences: PropTypes.func.isRequired,
    tabPreferences: PropTypes.object.isRequired,
};
export default LevelSetting;
