import moment from 'moment';
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import { isNaN } from 'lodash';
import { Empty, Skeleton } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Customized,
    // Curve,
    // Area,
} from 'recharts';
// import { PlanningContext } from '../../contexts/needs/PlanningContext';
import { generateString } from '../../helpers/string-helper';
import { NeedChartContext } from '../../contexts/chart/ChartContext';
import { xAxisDateFormat, xAxisDateScaleFunction } from './CustomHistogramDateXaxis';
import { dateToTimestamp, dateToUTCDate } from '../../helpers/planning';
import { NEED_CHART_DATA, NEED_CHART_TYPE, NEED_HISTOGRAM_TYPE, SLICE_DATA_TYPE } from '../../constants/Needs';
import CustomizedActiveDot from './customized/CustomizedActiveDot';
import CustomizedLines from './customized/CustomizedLines';
import CustomizedDot from './customized/CustomizedDot';
import CustomizedBar from './customized/CustomizedBar';
import { TabContext } from '../../contexts/app/TabContext';
import { TooltipContent, TooltipLabelDate } from './customized/CustomizedTooltip';
import CustomDateRectangles from './customized/CustomDateRectangles';

moment.defineLocale(window.localStorage.getItem('lng') || 'fr', {
    week: {
        dow: 1, // First day of week is Monday
        doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
    },
});

const SCALE_HEIGHT = 35;
const SCALE_FONT_SIZE = 11;

const StyledContainer = styled.div`
    .recharts-curve.recharts-line-curve {
        display: none;
    }
`;

const GanttHistogramChart = () => {
    let showDefaultTooltip;
    let activeDotDataKey;

    const { chartData, needList, chartOptions, scale, setChartRef, durationUnit } = useContext(NeedChartContext);
    const realChartRef = useRef(null);
    const [viewPort, setViewPort] = useState({});
    const [loadingChart, setLoadingChart] = useState(false);
    const { tabPreferences } = useContext(TabContext);

    // const [showDefaultTooltip, setShowDefaultTooltip] = useState(true);
    const isHourScale = scale === 'hours';
    const getXAxisArgsForTimeBasedGraph = useCallback(
        (scaleFunction, isSubScale) => {
            setLoadingChart(true);
            if (chartData.length > 0 && scale) {
                const timeUnit = isSubScale
                    ? xAxisDateFormat[scale].subScaleUnit
                    : xAxisDateFormat[scale].mainScaleUnit;
                const startTime = isHourScale
                    ? dateToUTCDate(moment.utc(chartData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf())
                    : moment.utc(chartData[0].date).startOf(timeUnit).valueOf();
                const endTime = isHourScale
                    ? dateToUTCDate(
                          moment
                              .utc(chartData[chartData.length - 1].date)
                              .endOf(timeUnit)
                              .valueOf()
                      )
                    : moment
                          .utc(chartData[chartData.length - 1].date)
                          .endOf(timeUnit)
                          .valueOf();
                const timeScale = d3.scaleTime().domain([startTime, endTime]);
                console.log('🚀 ~ file: GanttHistogramChart.jsx:68 ~ GanttHistogramChart ~ [startTime, endTime]:', [
                    startTime,
                    endTime,
                ]);
                const dateFormat = isSubScale ? xAxisDateFormat[scale].subScale : xAxisDateFormat[scale].mainScale;
                return {
                    // domain: [startTime, endTime],
                    // type: 'date',
                    tickFormatter: (date) =>
                        isHourScale ? moment.utc(date).format(dateFormat) : moment(date).format(dateFormat),
                    ticks: timeScale.ticks(scaleFunction()).map((date) => dateToTimestamp(date)),
                };
            }
            setLoadingChart(false);
            return {};
        },
        [chartData, scale]
    );

    useEffect(() => {
        setLoadingChart(true);
    }, [tabPreferences?.gantt_scales]);

    const mainAxisConfig = useMemo(() => {
        if (chartData.length > 0) {
            return getXAxisArgsForTimeBasedGraph(xAxisDateScaleFunction[xAxisDateFormat[scale]?.mainScaleUnit], false);
        }
        return {};
    }, [chartData]);

    const loadViewport = useCallback(
        (currentViewPort, from) => {
            // console.log("🚀 ~ file: GanttHistogramChart.jsx:91 ~ GanttHistogramChart ~ from:", from)
            if (chartData.length > 0) {
                const viewPortInit = { ...currentViewPort };
                if (!viewPortInit.minScope) viewPortInit.minScope = 0;
                if (!viewPortInit.maxScope) viewPortInit.maxScope = 60;
                viewPortInit.chartSize = parseInt(
                    document.getElementById('gantt-histogram-container')?.offsetWidth,
                    10
                );
                viewPortInit.chartAreaElement = document.getElementById('gantt-histogram-area');
                viewPortInit.visibleWidthChart = parseInt(
                    document.getElementById('gantt-histogram-area')?.style?.width,
                    10
                );
                viewPortInit.step = viewPortInit.chartSize / chartData.length;
                // viewPortInit.marginLeft = viewPortInit.step * viewPortInit.minScope;
                viewPortInit.marginLeft = window.ganttInstance.posFromDate(
                    new Date(dateToUTCDate(chartData[viewPortInit.minScope]?.date))
                );
                // viewPortInit.marginRight = viewPortInit.step * Math.max(0, chartData.length - viewPortInit.maxScope);
                const lastDatePosition = window.ganttInstance.posFromDate(
                    new Date(dateToUTCDate(chartData[Math.min(viewPortInit.maxScope, chartData.length - 1)]?.date))
                );
                viewPortInit.marginRight = viewPortInit.chartSize - lastDatePosition;
                viewPortInit.chartWidth = lastDatePosition - viewPortInit.marginLeft;

                return viewPortInit;
            }
            return {};
        },
        [chartData]
    );

    // if(Object.keys(viewPort).length <= 0) loadViewport();

    const computeViewPort = useCallback(() => {
        setViewPort((oldViewPort) => {
            let newViewPort = oldViewPort.chartAreaElement
                ? oldViewPort
                : loadViewport({}, 'from totally new viewport');
            const currentChartSize = parseInt(document.getElementById('gantt-histogram-container')?.offsetWidth, 10);
            // console.log("🚀 Before compute", cloneDeep(newViewPort), currentChartSize)
            if (
                newViewPort &&
                (newViewPort?.chartAreaElement.scrollLeft < newViewPort?.marginLeft ||
                    newViewPort?.marginLeft + newViewPort?.chartWidth <
                        newViewPort?.chartAreaElement.scrollLeft + newViewPort?.visibleWidthChart) &&
                currentChartSize === newViewPort?.chartSize
            ) {
                newViewPort.minScope = Math.round(
                    Math.max(0, newViewPort.chartAreaElement.scrollLeft / newViewPort.step - 1)
                );
                newViewPort.maxScope = newViewPort.minScope + 60;
                newViewPort = loadViewport(newViewPort, 'from after compute scope');
            }
            // console.log("🚀 ~ file: GanttHistogramChart.jsx:133 ~ setViewPort ~ newViewPort:", newViewPort)
            setLoadingChart(false);

            return newViewPort;
        });
    }, [viewPort, chartData]);

    // update yaxis when container size change
    const handleOnResize = useCallback(() => {
        if (chartData.length > 0) {
            // console.log('ONRESIZE');
            const newViewPort = loadViewport({}, 'on resize');
            setViewPort(newViewPort);
            setTimeout(() => {
                setChartRef({ ...realChartRef?.current?.state });
                // console.log('compute before resize')
                computeViewPort();
            }, 500);
        }
    }, [realChartRef?.current?.state, chartData]);
    // add processing gantt to wait for width change

    useEffect(() => {
        if (chartData.length > 0) {
            handleOnResize();
            const chartAreaElement = document.getElementById('gantt-histogram-area');
            if (chartAreaElement) {
                // console.log('ADDING EVENT LISTENER');
                chartAreaElement.addEventListener('scrollend', computeViewPort);
            }
        }
        return () => {
            // console.log('REMOVING EVENT LISTENER');
            const chartAreaElement = document.getElementById('gantt-histogram-area');
            chartAreaElement?.removeEventListener('scrollend', computeViewPort);
        };
    }, [chartData]);

    const cumulativeChartData = useMemo(() => {
        const result = [];
        chartData.forEach((data, index) => {
            const cumulativeData = { ...data };
            if (index > 0) {
                Object.keys(data).forEach((key) => {
                    if (key !== 'date') {
                        cumulativeData[key] += result[index - 1][key];
                    }
                });
            }
            result.push(cumulativeData);
        });
        return result;
    }, [chartData]);

    const displayedData = useMemo(
        () =>
            chartOptions && chartOptions.dataType === NEED_CHART_DATA.INSTANTANEOUS
                ? chartData.slice(viewPort?.minScope, viewPort?.maxScope)
                : cumulativeChartData.slice(viewPort?.minScope, viewPort?.maxScope),

        [viewPort?.minScope, viewPort?.maxScope, chartOptions?.dataType, chartData, cumulativeChartData]
    );

    // update yaxis when viewport change
    useEffect(() => {
        setChartRef({ ...realChartRef?.current?.state });
    }, [viewPort, needList, chartOptions?.dataType]);

    const handleActiveDotMouseHover = (lineDataKey) => {
        showDefaultTooltip = false;
        activeDotDataKey = lineDataKey;
    };
    const handleActiveDotMouseLeave = () => {
        showDefaultTooltip = true;
        activeDotDataKey = null;
    };
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && activeDotDataKey && !showDefaultTooltip) {
            const activeDotPayload = payload.find((i) => i.name === activeDotDataKey);
            return (
                <div className="bg-white border p-2">
                    <TooltipLabelDate label={label} />
                    <TooltipContent payload={activeDotPayload} />
                </div>
            );
        }
        return (
            <div className="bg-white border p-2">
                <TooltipLabelDate label={label} />
                {(payload || []).map((currentPayload) => {
                    const payloadToShow = {...currentPayload};
                    if (!isNaN(currentPayload?.value)) {
                        payloadToShow.value = Number(currentPayload?.value).toFixed(2);
                        payloadToShow.realValue = +Number(currentPayload?.value).toFixed(2);
                    } else {
                        payloadToShow.realValue = 0;
                    }
                    if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
                        payloadToShow.value = ''.concat(payloadToShow.value).concat('/').concat(durationUnit(chartOptions.effortTimeUnitId).name);
                    }
                    return <TooltipContent key={generateString(5)} payload={payloadToShow} />;
                })}
            </div>
        );
    };
    const computeScaleDates = (data, tickFunction, scaleUnit) => {
        if (data.length > 0) {
            const currentData = data;
            const timeUnit = xAxisDateFormat[scale][scaleUnit];
            const startTime =
                timeUnit === 'hours'
                    ? dateToUTCDate(moment.utc(currentData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf())
                    : currentData[0].date;
            const endTime = moment
                .utc(currentData[currentData.length - 1].date)
                .endOf(xAxisDateFormat[scale].mainScaleUnit)
                .valueOf();
            const domain = [startTime, endTime];
            const timeScale = d3.scaleTime().domain(domain);
            const dates = [currentData[0].date];
            // const dates = [];
            dates.push(...timeScale.ticks(tickFunction()).map((date) => dateToTimestamp(date)));
            dates.push(
                moment
                    .utc(currentData[currentData.length - 1].date)
                    .endOf(xAxisDateFormat[scale].mainScaleUnit)
                    .valueOf()
            );
            return dates;
        }
        return [];
    };
    const mainScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat[scale]?.mainScaleUnit],
                'mainScaleUnit'
            ),
        [displayedData]
    );
    const subScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat[scale]?.subScaleUnit],
                'subScaleUnit'
            ),
        [displayedData]
    );

    const computeScaleCoordinates = (dates, scaleType, isSubScale = false) => {
        if (dates.length > 0) {
            let dateFormat;
            if (isSubScale) {
                const stringFormat = xAxisDateFormat[scale].dhtmlxSubScaleFormat;
                if (stringFormat) {
                    dateFormat = { formatter: 'dhtmlx', format: stringFormat };
                } else {
                    dateFormat = { formatter: 'moment', format: xAxisDateFormat[scale][scaleType] };
                }
            } else {
                dateFormat = { formatter: 'dhtmlx', format: xAxisDateFormat[scale].dhtmlxMainScaleFormat };
            }
            // const dateFormat = xAxisDateFormat[scale][scaleType];
            const timeUnit = xAxisDateFormat[scale][`${scaleType}Unit`];
            let initialX = viewPort?.marginLeft;
            const rectCoordinates = dates.flatMap((date, index) => {
                if (index > 0) {
                    // const lastDatePosition = window.ganttInstance.posFromDate(new Date((dates[index - 1])));
                    const lastDatePosition = window.ganttInstance.posFromDate(
                        new Date(dateToUTCDate(dates[index - 1]))
                    );
                    // const datePosition = window.ganttInstance.posFromDate(new Date(date));
                    const datePosition = window.ganttInstance.posFromDate(new Date(dateToUTCDate(date)));
                    let widthRect = datePosition - lastDatePosition;
                    // fix mainScale date when hours
                    if (date === chartData[0].date && timeUnit === 'hours') {
                        widthRect = viewPort.step;
                    }
                    if ((timeUnit === 'hours' && widthRect <= 0) || widthRect < 0) {
                        return [];
                    }
                    let text = '';
                    if (dateFormat.formatter === 'dhtmlx') {
                        text =
                            timeUnit === 'hours'
                                ? window.ganttInstance.date.date_to_str(dateFormat.format, true)(new Date(date))
                                : window.ganttInstance.date.date_to_str(dateFormat.format)(new Date(dates[index - 1]));
                    } else {
                        text =
                            timeUnit === 'hours'
                                ? moment.utc(date).format(dateFormat.format)
                                : moment(dates[index - 1]).format(dateFormat.format);
                    }
                    // ADJUSTMENT OF TIMELINE AND CHART
                    // if (timeUnit !== 'hours') {
                    //     initialX -= 2;
                    // }
                    const res = {
                        widthRect,
                        heightRect: SCALE_HEIGHT,
                        text,
                        x: initialX,
                        isCentered: true,
                        // isCentered: index !== 1 && index !== dates.length - 1,
                    };
                    initialX += widthRect;
                    return res;
                }
                return [];
            });
            return rectCoordinates;
        }
        return [];
    };

    const mainScaleCoordinates = useMemo(
        () => computeScaleCoordinates(mainScaleDates, 'mainScale'),
        [mainScaleDates, viewPort?.step]
    );
    const subScaleCoordinates = useMemo(
        () => computeScaleCoordinates(subScaleDates, 'subScale', true),
        [subScaleDates, viewPort?.step]
    );

    /* eslint-disable react/prop-types, react/no-unknown-property */
    return chartData.length > 0 ? (
        <StyledContainer className="w-full h-full">
            {loadingChart ? (
                <Skeleton active title={false} paragraph={{ rows: 10 }} size="large" />
            ) : (
                <ResponsiveContainer
                    debounce={800}
                    height="100%"
                    width="100%"
                    className="w-full h-full"
                    onResize={() => handleOnResize()}
                >
                    <ComposedChart
                        width={1000}
                        barCategoryGap={0}
                        barGap={0}
                        margin={{ left: viewPort?.marginLeft, right: viewPort?.marginRight, bottom: 2 * SCALE_HEIGHT }}
                        data={displayedData}
                        ref={realChartRef}
                    >
                        <CartesianGrid
                            stroke="#828282"
                            id="gantt-histogram-grid"
                            verticalCoordinatesGenerator={() => mainScaleCoordinates.map((c) => c.x)}
                        />
                        <YAxis hide domain={['auto', 'dataMax + 10']} />
                        <XAxis
                            dataKey="date"
                            scale={`${chartOptions.chartType === NEED_CHART_TYPE.BAR ? 'time' : undefined}`}
                            interval={0}
                            fontSize={SCALE_FONT_SIZE}
                            {...mainAxisConfig}
                            dx={viewPort?.step / 2}
                            dy={-(SCALE_HEIGHT + SCALE_FONT_SIZE) / 2}
                            height={SCALE_HEIGHT}
                            padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                            tickSize={SCALE_HEIGHT}
                            hide
                        />

                        <Tooltip isAnimationActive={false} cursor={false} content={CustomTooltip} offset={10} />
                        {chartOptions.chartType === NEED_CHART_TYPE.BAR &&
                            needList
                                .filter((i) => i.selected)
                                .map((need, index, filteredList) => (
                                    <Bar
                                        key={generateString(2)}
                                        dataKey={need.name}
                                        stackId={
                                            chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED ? 'bar' : null
                                        }
                                        name={need.name}
                                        isAnimationActive={false}
                                        fill={need.color}
                                        shape={
                                            <CustomizedBar
                                                scaleCoordinates={mainScaleCoordinates}
                                                dataOrder={index}
                                                dataCount={filteredList.length}
                                                isStacked={chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED}
                                            />
                                        }
                                    />
                                ))}
                        {chartOptions.chartType === NEED_CHART_TYPE.LINE &&
                            needList
                                .filter((i) => i.selected)
                                .map((need) => (
                                    <Line
                                        key={generateString(2)}
                                        activeDot={
                                            <CustomizedActiveDot
                                                scaleCoordinates={mainScaleCoordinates}
                                                handleActiveDotMouseHover={() => handleActiveDotMouseHover()}
                                                handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                            />
                                        }
                                        onMouseLeave={() => handleActiveDotMouseLeave(need.name)}
                                        dataKey={need.name}
                                        dot={
                                            <CustomizedDot
                                                scaleCoordinates={mainScaleCoordinates}
                                                handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                            />
                                        }
                                        stroke={need.color}
                                        name={need.name}
                                        isAnimationActive={false}
                                    />
                                ))}
                        {chartOptions.chartType === NEED_CHART_TYPE.LINE && (
                            <Customized component={<CustomizedLines coordinates={mainScaleCoordinates} />} />
                        )}
                        <Customized
                            component={<CustomDateRectangles isMainScale coordinates={mainScaleCoordinates} />}
                        />
                        <Customized component={<CustomDateRectangles coordinates={subScaleCoordinates} />} />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </StyledContainer>
    ) : (
        <Empty />
    );
};

export default GanttHistogramChart;
