import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Select, Row, Col, Divider } from 'antd';
import { SketchPicker } from 'react-color';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import FolderService from '../../services/folder.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { notificationError, requestError } from '../../helpers/notification';
import { ReactComponent as HatchLeftSvg } from '../../assets/hatch/left.svg';
import { ReactComponent as HatchRightSvg } from '../../assets/hatch/right.svg';
import { ReactComponent as HatchHorizontalSvg } from '../../assets/hatch/horizontal.svg';
import { ReactComponent as HatchVerticalSvg } from '../../assets/hatch/vertical.svg';
import { ReactComponent as PlainSvg } from '../../assets/hatch/plain.svg';
import CustomFieldService from '../../services/custom-field.service';
import { JOB_FIELD } from '../../constants/Jobs';
// import { UserContext } from '../../contexts/app/UserContext';

const InputColor = styled.div`
    padding: 5px;
    width: 100%;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`;
const ColorPicker = styled.div`
    position: absolute;
    z-index: 2;
`;

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ColorCard = styled.div`
    width: 100%;
    height: 20px;
    border-radius: 2px;
    background-color: ${(props) => props.bgColor || '#fff'};
    vertical-align: sub;
`;
const defaultJobValues = {
    name: null,
    hachure: 'PLEIN',
    color: '#fff',
    trame: '#fff',
};
const AddPlanningModal = forwardRef(({ parent }, ref) => {
    const { t } = useTranslation();
    const [isVisible, setVisible] = useState(false);
    const [newJob, setNewJob] = useState(defaultJobValues);
    const [showJobColorPicker, setShowJobColorPicker] = useState(false);
    const [showJobTramePicker, setShowJobTramePicker] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jobList, setJobList] = useState([]);
    const { setFolderSelected, folderSelected, modeSelected, updatePlanning } = useContext(PlanningContext);
    // const {userPreferences, updateUserPreferences} = useContext(UserContext)
    const [form] = Form.useForm();

    const typeHachurage = [
        {
            value: 'HORIZONTAL',
            text: t('modal_custom_fields.hatching_type_horizontal'),
            label: <HatchHorizontalSvg />,
        },
        {
            value: 'VERTICAL',
            text: t('modal_custom_fields.hatching_type_vertical'),
            label: <HatchVerticalSvg />,
        },
        {
            value: 'BIAI',
            text: t('modal_custom_fields.hatching_type_biai1'),
            label: <HatchLeftSvg />,
        },
        {
            value: 'BIAI1',
            text: t('modal_custom_fields.hatching_type_biai2'),
            label: <HatchRightSvg />,
        },
        {
            value: 'PLEIN',
            text: t('modal_custom_fields.hatching_type_full'),
            label: <PlainSvg />,
        },
    ];
    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        console.log('on cancel');
        setVisible(false);
    };

    const onFinish = (values) => {
        if (jobList.length === 0) {
            notificationError(t('modal_add_planning.add_planning'), t('modal_add_planning.at_least_one_job'));
            return;
        }
        setLoading(true);
        const createPlanning =
            modeSelected === 'sandbox'
                ? FolderService.createSandboxPlanning(values.name)
                : FolderService.createPlanning(values.name, parent);
        createPlanning
            .then(async (planning) => {
                try {
                    const newPlanningCustomFields = await CustomFieldService.planningCustomFields(planning.id);
                    const jobCustomField = newPlanningCustomFields.find((i) => i.name === JOB_FIELD);
                    console.log("🚀 ~ file: AddPlanningModal.jsx:119 ~ .then ~ jobCustomField:", jobCustomField)
                    if (jobCustomField) {
                        const choicePromises = jobList.map((job) =>
                            CustomFieldService.addChoice(
                                jobCustomField.id,
                                job.name,
                                job.hachure === 'PLEIN' ? null : job.hachure,
                                job.color,
                                job.trame, 
                                planning.rootActivityId
                            )
                        );
                        Promise.all(choicePromises).then(() => {
                            if (folderSelected) {
                                setFolderSelected({ ...folderSelected });
                                updatePlanning(planning)
                                // updateUserPreferences({
                                //     open_planning: { ...userPreferences.open_planning, [modeSelected]: planning },
                                // }).then(() => {
                                //     window.location.reload();
                                // });
                            }

                            form.resetFields();
                            setJobList([]);
                            setLoading(false);
                            setVisible(false);
                        });
                    }
                } catch (error) {
                    requestError(error, t('modal_add_planning.add_job'));
                    setLoading(false);
                }
            })
            .catch((error) => {
                requestError(error, t('modal_add_planning.error_create_planning'));
                setLoading(false);
            });
    };

    const handleAddJob = () => {
        if (!newJob.name) {
            notificationError(t('modal_add_planning.new_job'), t('modal_add_planning.job_name_required'));
            return;
        }
        setJobList((old) => [...old, { ...newJob, trame: newJob.hachure === 'PLEIN' ? newJob.color : newJob.trame }]);
        setNewJob(defaultJobValues);
    };

    return (
        <Modal
            title={t('modal_add_planning.add_planning')}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
            width={720}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label={t('general.name')}
                    rules={[{ required: true, message: t('general.required_field') }]}
                >
                    <Input type="text" />
                </Form.Item>
                <Row>
                    <Col span={24}>
                        <label>{t('modal_add_planning.jobs')}</label>
                    </Col>
                </Row>
                <Row className="justify-between mb-2">
                    <Col span={6}>
                        <p className="text-sm">{t('general.name')}</p>
                        <Input
                            type="text"
                            value={newJob.name}
                            onChange={(e) => setNewJob((old) => ({ ...old, name: e.target.value }))}
                        />
                    </Col>
                    <Col span={8}>
                        <p className="text-sm">{t('modal_custom_fields.hatching_type')}</p>

                        <Select
                            style={{ boxShadow: 'inset 2px 3px 3px rgba(130, 130, 130, 0.15)' }}
                            dropdownClassName="rounded"
                            value={newJob.hachure}
                            onChange={(val) => setNewJob((old) => ({ ...old, hachure: val }))}
                            className="w-full"
                        >
                            {typeHachurage.map((type) => (
                                <Select.Option
                                    key={`custom-field-newplanning-choice-select-type-hachurage${type.value}`}
                                    value={type.value}
                                >
                                    <div className="flex items-center">
                                    <div style={{width: '24px', height: '24px'}}>{type.label}</div>  <span className="ml-2 pr-2">{type.text}</span>
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={3}>
                        <p className="text-sm">{t('modal_add_planning.principal_color')}</p>

                        <InputColor title="Couleur" onClick={() => setShowJobColorPicker(true)}>
                            <ColorCard bgColor={newJob.color} className="border border-gray" />
                        </InputColor>
                        {showJobColorPicker && (
                            <ColorPicker>
                                <ColorCover onClick={() => setShowJobColorPicker(false)} />
                                <SketchPicker
                                    color={newJob.color}
                                    onChange={(value) => setNewJob((old) => ({ ...old, color: value.hex }))}
                                />
                            </ColorPicker>
                        )}
                    </Col>
                    <Col span={3}>
                        <p className={`${newJob.hachure === 'PLEIN' ? 'hidden' : ''} text-sm`}>
                            {t('modal_add_planning.secondary_color')}
                        </p>

                        <InputColor
                            title="Trame"
                            onClick={() => setShowJobTramePicker(true)}
                            className={`${newJob.hachure === 'PLEIN' ? 'hidden' : ''}`}
                        >
                            <ColorCard bgColor={newJob.trame} className="border border-gray" />
                        </InputColor>
                        {showJobTramePicker && (
                            <ColorPicker>
                                <ColorCover onClick={() => setShowJobTramePicker(false)} />
                                <SketchPicker
                                    color={newJob.trame}
                                    onChange={(value) => setNewJob((old) => ({ ...old, trame: value.hex }))}
                                />
                            </ColorPicker>
                        )}
                    </Col>
                    <Col span={3} className="flex justify-end items-center">
                        <Button type="primary" onClick={() => handleAddJob()} icon={<PlusOutlined />} />
                    </Col>
                </Row>
                <Divider />
                {jobList.map((job, index) => (
                    <Row className="justify-between mb-2">
                        <Col span={8}>
                            <p>{job.name}</p>
                        </Col>
                        <Col span={7}>
                            <div className="flex items-center">
                                <span className="pr-2">{job.hachure}</span>{' '}
                                {typeHachurage.find((i) => i.value === job.hachure).label}
                            </div>
                        </Col>
                        <Col span={3}>
                            <InputColor>
                                <ColorCard bgColor={job.color} className="border border-gray" />
                            </InputColor>
                        </Col>
                        <Col span={3}>
                            <InputColor className={`${job.hachure === 'PLEIN' ? 'hidden' : ''}`}>
                                <ColorCard bgColor={job.trame} className="border border-gray" />
                            </InputColor>
                        </Col>
                        <Col span={2} className="flex justify-end">
                            <Button
                                type="dashed"
                                onClick={() =>
                                    setJobList((old) => {
                                        const newVal = [...old];
                                        newVal.splice(index, 1);
                                        return newVal;
                                    })
                                }
                                block
                                icon={<DeleteOutlined />}
                            />
                        </Col>
                    </Row>
                ))}
                <Divider />
                <Form.Item className="mb-0 flex justify-end">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {t('general.add')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

AddPlanningModal.defaultProps = {
    parent: false,
};

AddPlanningModal.propTypes = {
    parent: PropTypes.number,
};

export default AddPlanningModal;
