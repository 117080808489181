import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal } from 'antd';
import FolderExplorer from './FolderExplorer';
import { notificationError } from '../../helpers/notification';

const ButtonToggle = styled.button`
    width: 80px;
    height: 30px;
    font-size: 10px;
`;
const activeClasses = 'bg-primary text-secondary-dark font-weight-bold ml-2';
const defaultClasses = 'bg-secondary-light text-secondary-dark';

const BaseLineExplorerModal = forwardRef((props, ref) => {
    const [isVisible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedMode, setSelectedMode] = useState('archive');
    const { t } = useTranslation('translation', { keyPrefix: 'baseline_explorer_modal' });

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = useCallback(async () => {
        if (selectedItem) {
            if (!selectedItem.rootActivityId) {
                notificationError(t('planning_choice'), t('please_select_a_planning_not_a_folder'))
            } else {
                props.onPlanningChosen(selectedItem)
                setVisible(false);
            }
        }
    }, [selectedItem]);

    return (
        <Modal
            title={t('chose_a_specific_planning')}
            className="explorer-modal"
            centered
            open={isVisible}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
            width={912}
            bodyStyle = {{padding: '0.5rem'}}
        >
            <div className="flex w-full justify-center items-center mb-2">
                <ButtonToggle
                    type="button"
                    className={selectedMode === 'live' ? activeClasses : defaultClasses}
                    onClick={() => setSelectedMode('live')}
                >
                    {t('live')}
                </ButtonToggle>
                <ButtonToggle
                    type="button"
                    className={selectedMode === 'archive' ? activeClasses : defaultClasses}
                    onClick={() => setSelectedMode('archive')}
                >
                    {t('archives')}
                </ButtonToggle>
                <ButtonToggle
                    type="button"
                    className={selectedMode === 'sandbox' ? activeClasses : defaultClasses}
                    onClick={() => setSelectedMode('sandbox')}
                >
                    {t('sandbox')}
                </ButtonToggle>
            </div>
            <FolderExplorer mode={selectedMode} onSelected={(item) => setSelectedItem(item)} />
        </Modal>
    );
});

export default BaseLineExplorerModal;
