import * as Sentry from '@sentry/browser';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, Row, Select } from 'antd';
import styled from 'styled-components';
import { ReactComponent as CheckCircleSvg } from '../../assets/icons/check-circle.svg';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { UserContext } from '../../contexts/app/UserContext';
import ActivityService from '../../services/activity.service';
// import CustomFieldService from '../../services/custom-field.service';
import FolderService from '../../services/folder.service';
import { requestError } from '../../helpers/notification';
import { generateString } from '../../helpers/string-helper';

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
`;

const ValidatePlanningModal = () => {
    const {t} = useTranslation('translation',   {keyPrefix: 'modal_validate_planning'})
    const generalTranslation = useTranslation('translation',   {keyPrefix: 'general'}).t
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { planningSelected, refreshPlanningSelected, modeSelected } = useContext(PlanningContext);
    const { teamList, currentUser, refreshUserPreferences } = useContext(UserContext);
    const [invalidActivities, setInvalidActivities] = useState([]);
    const [multipleTeams, setMultipleTeams] = useState([]);
    const [singleTeams, setSingleTeams] = useState([]);
    // eslint-disable-next-line
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const validatePlanning = async () => {
        try {
            setLoading(true)
            const response = await ActivityService.checkInvalidActivities(planningSelected);
            setInvalidActivities(response.notValidate);
            setLoading(false)
            setIsModalVisible(true);
        } catch (error) {
            Sentry.captureException(error);
            requestError(error, t('error_checking_activities'));
        }
    };

    const submitForm = async (values) => {
        try {
            setLoading(true)
            const archivedPlanning = await FolderService.archivePlanning(planningSelected.id, values.name);
            // notification
            if (selectedTeams.length && archivedPlanning) {
                const archiveUrl = `${window.location.protocol}//${window.location.host}/program/${
                    JSON.parse(localStorage.getItem('prg')).id
                }/archive/planning/${archivedPlanning.id}`;
                await FolderService.notifyUsers(
                    [],
                    selectedTeams,
                    planningSelected.name,
                    `${currentUser.firstName} ${currentUser.lastName}`,
                    archiveUrl
                );
            }
            await refreshUserPreferences()
            setLoading(false)
            refreshPlanningSelected();
            setIsModalVisible(false);
        } catch (error) {
            console.log("🚀 ~ file: ValidatePlanningModal.jsx:98 ~ submitForm ~ error", error)
            requestError(error, t('error_archiving_planning'));
            setIsModalVisible(false);

}
    };
    // notification
    useEffect(() => {
        setMultipleTeams(teamList.filter((team) => !team.single));
        setSingleTeams(teamList.filter((team) => team.single));
    }, [teamList]);
    const handleChangeTeamSelection = (value) => {
        setSelectedTeams(value);
    };
    return (
        <>
            {modeSelected === 'live' ? (
                <div className="flex items-center py-2">
                    <Button
                        loading={loading}
                        type="primary"
                        className="inline-block text-center mr-4"
                        onClick={validatePlanning}
                    >
                        {t('archive_planning')}
                    </Button>
                </div>
            ) : null}
            <Modal
                title={`${t('archiving_planning')} : ${planningSelected ? planningSelected.name : ''}`}
                className="calendar-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={550}
                height="auto"
            >
                <Row gutter={[12, 0]} className="h-full">
                    <div className="flex flex-col h-full w-full">
                        <PanelTitle>
                            {invalidActivities.length ? (
                                <div>
                                   <CheckCircleSvg/> {t('validation_error')}
                                </div>
                            ) : 
                                t('all_activities_validated')
                            }
                        </PanelTitle>
                        {invalidActivities.length ? (
                            invalidActivities.map((invalidActivity) => (
                                <div key={generateString(5)}>- {invalidActivity}</div>
                            ))
                        ) : (
                            <Form className="mt-2" onFinish={submitForm}>
                                <Form.Item label={t('users_to_notify')} labelCol={{ span: 24 }} wrapperCol={24}>
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        filterOption={(input, option) =>
                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={handleChangeTeamSelection}
                                    >
                                        <Select.OptGroup label={generalTranslation('users_plural')}>
                                            {multipleTeams.map((team) => (
                                                <Select.Option key={`multiple${team.id}`} value={team.id}>
                                                    {team.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                        <Select.OptGroup label={generalTranslation('teams_plural')}>
                                            {singleTeams.map((team) => (
                                                <Select.Option key={`user${team.id}`} value={team.id}>
                                                    {team.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={t('archive_name')}
                                    name="name"
                                    rules={[{ required: true, message: generalTranslation('required_field') }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item className="mb-0 flex justify-end">
                                    <Button loading={loading} type="primary" htmlType="submit">
                                    {t('archive')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                </Row>
            </Modal>
        </>
    );
};

export default ValidatePlanningModal;
