import React, {useContext, useEffect, useState} from 'react';
import {Button, Popconfirm, Select, Space, Table, Switch, Badge} from 'antd';
import {WalletContext} from "../../../contexts/wallet/WalletContext";
import {VersionContext} from "../../../contexts/wallet/VersionContext";


const { Option } = Select;
const { Column } = Table;

const VersionTab = () => {
    const [loadingServiceTable] = useState(false);
    const [dataProgram, setDataProgram] = useState();
    const [dataServices, setDataServices] = useState({});
    const [allTags, setAllTags] = useState([]);
    const { selectedProgram } = useContext(WalletContext);

    const { dataProgramServices, doDeployement, doWalletDeployement, doFrontDeployement, loadLastVersionForTag } = useContext(VersionContext);


    const loading= () => {
        const nDataProgram = dataProgramServices[selectedProgram.name];
        setDataProgram(nDataProgram);
        const nDataServices = nDataProgram?.services;
        setDataServices(nDataServices);
        const nTags = !(nDataProgram) || nDataServices.length <= 0 ? [] :
            Object.values(nDataServices)
                .map(service => service.tags)
                .reduce((tags1,tags2)=>tags1.concat(tags2));
        setAllTags(nTags);
    }

    useEffect(()=>{
        loading();
    }, [dataProgramServices])



    const eventLoadLastVersionForTag = async (services, tag, record) => {
       await Promise.all(loadLastVersionForTag(selectedProgram, services, tag, record));
       // force reload
        loading();
    }

    return (
        <>
            <p>Il faut être connecté au VPN pour utiliser ces options. Il faut autoriser le contenu mixe http-https.<br/>
             Sous mozilla-firefox : ouvrir - about:config - puis modifier la clef security.mixed_content.block_active_content = false.</p>
            <Button type="primary" >Execute Bot.</Button>
                <Select
                    className="mr-2"
                    showSearch
                    style={{ width: 150 }}
                    placeholder="Choose a branch or a tag."
                    onSelect = {(tag) => eventLoadLastVersionForTag(dataServices, tag)}
                >
                    {
                        [...new Set(allTags)].map(tag=>
                            <Option key={tag} value={tag}>{tag}</Option>
                        )
                    }
                </Select>
            &emsp; &emsp; &emsp; Services update &emsp; &emsp; &emsp;
            <Switch disabled checked={ dataProgram ? dataProgram.counter===6 : false} />
            <Badge count={dataProgram ? 6-dataProgram.counter : 0} showZero color='#f5222d' />
            <Badge count={dataProgram ? dataProgram.counter : 0} showZero color='#52c41a' />
            <Table dataSource={dataServices? Object.values(dataServices) : []} rowKey="label" loading={loadingServiceTable}>
                <Column title="Service" dataIndex="label"  />
                <Column title="Version disponible"
                        // dataIndex="lastVersion"
                        key="version"
                        render={(reccord) => (
                            <ul>
                                <li><b> { reccord.lastVersion } </b></li>
                                <li><i> { reccord.timestampLastVersion } </i></li>
                            </ul>)}
                />
                <Column title="Version courante"
                        // dataIndex="version"
                        render={(reccord) => (
                            <ul>
                                <li><b> { reccord.version } </b></li>
                                <li><i> { reccord.timestampVersion } </i></li>
                            </ul>)}
                />

                <Column
                    title="Déployer"
                    key="action"
                    render={(record) => (
                        <Space size="middle">
                            <Select
                                className="mr-2"
                                showSearch
                                style={{ width: 150 }}
                                placeholder="Choose a branch or a tag."
                                value={record.tag}
                                onSelect = {(tag) => eventLoadLastVersionForTag(dataServices, tag, record)}
                            >
                                { record.tags.map(tag => (
                                        <Option key={record.label+tag} value={tag}>{tag}</Option>
                                ))}
                            </Select>
                            <Popconfirm
                                title="Etes-vous sûr de vouloir déployer la dernière version du programme ?"
                                onConfirm={() =>{
                                    if(record.label === 'FRONT')
                                        doFrontDeployement(selectedProgram, record)
                                    if(record.label === 'WALLET')
                                        doWalletDeployement(selectedProgram, record)
                                    else
                                        doDeployement(selectedProgram, record)
                                }}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button type="primary"
                                        disabled={!record.lastVersion || record.version===record.lastVersion}
                                >Mise à jour</Button>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
        </>
    );
};

export default VersionTab;