import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { union } from 'lodash';

import { Tooltip, Modal, Spin } from 'antd';
import styled from 'styled-components';
import { gantt } from 'dhtmlx-gantt';
import { notificationError, notificationInfo } from '../../helpers/notification';
import ActivityService from '../../services/activity.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';

const apiUrl = window._env_.REACT_APP_PRINT_URL;
/* eslint-disable camelcase */
const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;
const ExcelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const ImportMenu = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'modal_import'})
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t
    const { planningSelected } = useContext(PlanningContext);
    const hiddenFileInput = React.useRef(null);

    // useEffect(() => console.log('useEffect',window?.ganttInstance?.importFromExcel), [])


    let columns = [];

    const loadColumns = () => {
        columns = [];
        window.ganttInstance.config.fullColumns.filter(
            (column) => ['add', 'action_buttons', 'valid', 'predecessors', 'successors'].indexOf(column.id) < 0).
            forEach(val=> columns.push(val));

        // other columns
        columns.push({
            serverName: 'wbs',
            label: 'WBS',
        });
        columns.push({
            serverName: 'parentId',
            label: 'Parent',
        });
        columns.push({
            serverName: 'quantityUnit',
            label: 'Unité de quantité',
        });
        columns.push({
            serverName: 'nope',
            label: 'Non assigné',
        });
        columns.sort((a, b) => a.label.localeCompare(b.label));
    }

    const getOptions = (selectedValue) =>
        columns
            .map(
                (col) =>
                    /* eslint-disable-next-line */
                    "<option value='" +
                    col.serverName +
                    "' " +
                    (col.serverName === selectedValue ? 'selected' : '') +
                    '>' +
                    col.label +
                    '</option>'
            )
            .join('');

    const showModal = (project, existingMapping = []) => {
        loadColumns();
        if (project) {
            if (project[0] && Object.keys(project[0]).includes('undefined')) {
                notificationInfo(t('data_import'), t('column_validation'));
            }
            /* eslint-disable */
            let header = [];
            const headerControls = [];
            const body = [];
            // compute header
            project.forEach((task) => {
                header = union(header, Object.keys(task));
            })
            console.log("🚀 ~ file: ImportMenu.jsx:84 ~ project.forEach ~ header:", header)
            let cols = [];
            header.forEach((col) => {
                cols.push('<th ><div class="w-40 text-ellipsis overflow-hidden">' + col + '</div></th>');
                const value = columns.filter(val=>val.label==col);
                const options = existingMapping.length>0?
                                    getOptions(existingMapping[col] ?? 'nope'):
                                    getOptions(value[0]? value[0].serverName : 'nope');
                headerControls.push(
                    "<td><select style={width: 94%;} data-column-mapping='" +
                        col +
                        `'>` +
                        options +
                        '</select>'
                );
            });
            body.push('<tr>' + cols.join('') + '</tr>');
            body.push('<tr>' + headerControls.join('') + '</tr>');
            project.forEach((task) => {
                cols = [];
                header.forEach((col) => {
                    cols.push('<td>' + task[col] + '</td>');
                });
                body.push('<tr>' + cols.join('') + '</tr>');
            });
            const div = window.ganttInstance.modalbox({
                title: t('assign_column'),
                type: 'excel-form',
                text: '<table>' + body.join('') + '</table>',
                buttons: [
                    { label: generalTranslation('save'), css: 'link_save_btn', value: 'saveIt' },
                    { label: generalTranslation('cancel'), css: 'link_cancel_btn', value: 'cancel' },
                ],
                /* eslint-enable */
                callback: (result) => {
                    switch (result) {
                        case 'saveIt': {
                            const selects = div.querySelectorAll('[data-column-mapping]');
                            const mapping = {};
                            selects.forEach((select) => {
                                mapping[select.getAttribute('data-column-mapping')] = select.value;
                            });
                            /* eslint-disable-next-line */
                            processImport(mapping, project, div);
                            break;
                        }
                        case 'cancel':
                            // Cancel
                            break;
                        default:
                            break;
                    }
                },
                /* eslint-enable */
            });
        } else {
            notificationError(t('data_import'), generalTranslation('generic_error'));
        }
    };
    /* eslint-disable-next-line */
    const processImport = (mapping, data) => {
        let canImport = true;
        const selectedColumns = Object.values(mapping);
        if (selectedColumns.indexOf('parentId') !== -1 && selectedColumns.indexOf('wbs') !== -1) {
            canImport = false;
            gantt.message({
                type: 'error',
                text: t('parent_error'),
            });
        }
        const columnsToTest = selectedColumns.filter((i) => i !== 'nope');
        if (new Set(columnsToTest).size !== columnsToTest.length) {
            canImport = false;
            gantt.message({
                type: 'error',
                text: t('column_duplicate_error'),
            });
        }
        if (!canImport) {
            showModal(data, mapping);
            return;
        }
        const formattedData = [];
        data.forEach((item) => {
            const customFields = [];
            const activityObj = {};
            Object.keys(item).forEach((i) => {
                if (mapping[i] && mapping[i] !== 'nope') {
                    if (mapping[i].indexOf('customField') !== -1) {
                        customFields.push({
                            id: Number(mapping[i].split('#')[1]),
                            value: item[i],
                        });
                    } else if (['startDate', 'endDate'].includes(mapping[i])) {
                        activityObj[mapping[i]] = moment(item[i]).utc(true).valueOf();
                    } else {
                        activityObj[mapping[i]] = item[i];
                    }
                }
            });
            activityObj.customFields = customFields;
            formattedData.push(activityObj);
        });
        const modal = Modal.info();

        modal.update((prevConfig) => ({
            ...prevConfig,
            title: t('import_progress'),
            content: (<Spin/>),
            okButtonProps: {loading: true},
            okText: t('reload'),
            centered: true
        }));

        ActivityService.importActivities(planningSelected.rootActivityId, formattedData)
            .then(() => {
                modal.update((prevConfig) => ({
                    ...prevConfig,
                    title: t('data_import'),
                    content: t('data_import_success'),
                    okButtonProps: {loading: false},
                    onOk: () => window.location.reload()
                }));
            })
            .catch(() => {
                // TODO show summary of errors
                modal.destroy()
                showModal(data, mapping);
                // refreshPlanningSelected();
                notificationError(t('data_import'), generalTranslation('generic_error'));
            });
    };

    const importExcel = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded.type !== ExcelFileType) {
            notificationError(t('data_import'), t('wrong_file_type'));

            hiddenFileInput.current.value = '';
            return;
        }
        if (gantt.importFromExcel) {
            gantt.importFromExcel({
                server: apiUrl,
                data: fileUploaded,
                callback: showModal,
            });
        } else {
            notificationError(t('data_import'), t('module_loading'));
        }

        hiddenFileInput.current.value = '';
    };
    return (
        <>
            <Tooltip title={t('data_import')}>
                <QuickButton
                    type="button"
                    className="inline-block bg-primary text-center align-top"
                    title="Importer"
                    onClick={() => {
                        hiddenFileInput.current.click()
                    }}
                >
                    XLS
                </QuickButton>
            </Tooltip>

            <input
                ref={hiddenFileInput}
                onChange={importExcel}
                type="file"
                style={{ display: 'none' }}
                multiple={false}
                accept={ExcelFileType}
            />
        </>
    );
};

export default ImportMenu;
