import React, { createContext, useEffect, useState, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import resourceJSON from '../../mocks/resources/resources.json';
import NeedService from '../../services/need.service';
import { UserContext } from '../app/UserContext';
import { findParentObject } from '../../helpers/tree-helper';
// import { requestError } from '../../helpers/notification';

const NeedsContext = createContext({
    selectedNeed: null,
    NeedList: null,
    setSelectedNeed: () => {},
    setNeedList: () => {},
    refreshNeedList: () => {},
});

const NeedsContextProvider = ({ children }) => {
    // const {t} = useTranslation('translation', {keyPrefix: 'resource_context'})
    const [selectedNeed, setSelectedNeed] = useState(null);

    const [NeedList, setNeedList] = useState(null);
    const { canFetchData } = useContext(UserContext);

    const formatNeeds = (list, parent) =>
        list.map((el) => {
            const formattedValue = { ...el, key: el.id.toString(), parent, open: true };
            if (el.isLevel) {
                formattedValue.children = formatNeeds(el.children, el.id);
            }
            return formattedValue;
        });

    useEffect(() => {
        const fetchData = async () => {
            if (window._env_.REACT_APP_ACTIVITY_API) {
                try {
                    const list = await NeedService.getNeedsTreeByParent(-1);
                    setNeedList(formatNeeds([list], null));
                } catch (e) {
                    // je ne comprends pas TODO
                }
            }
        };
        fetchData();
    }, [canFetchData]);

    const refreshNeedList = (action, object) => {
        let oldNeeds = [];
        setNeedList((old) => {
            oldNeeds = old;
            return old;
        });
        // should always have parent object
        const parentObject = findParentObject(object.parent, oldNeeds);
        if (parentObject) {
            switch (action) {
                case 'new': {
                    parentObject.children.push(object);
                    break;
                }
                case 'edit': {
                    const oldParentObject = findParentObject(selectedNeed.parent, oldNeeds);
                    if (oldParentObject?.id !== parentObject?.id) {
                        const oldChildIndex = oldParentObject.children.findIndex((i) => i.id === object.id);
                        oldParentObject.children.splice(oldChildIndex, 1);
                        parentObject.children.push(object);
                    } else {
                        const childIndex = parentObject.children.findIndex((i) => i.id === object.id);
                        parentObject.children[childIndex] = object;
                    }
                    break;
                }
                case 'delete': {
                    const childIndex = parentObject.children.findIndex((i) => i.id === object.id);
                    parentObject.children.splice(childIndex, 1);
                    break;
                }
                default:
            }
        }
        if (object.isDefault)
        {
            oldNeeds = [object];
        }
        setNeedList(JSON.parse(JSON.stringify(formatNeeds(oldNeeds, null))));
    };

    return (
        <NeedsContext.Provider
            value={{
                selectedNeed,
                setSelectedNeed,
                NeedList,
                refreshNeedList,
            }}
        >
            {children}
        </NeedsContext.Provider>
    );
};

NeedsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { NeedsContext, NeedsContextProvider };
