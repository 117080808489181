import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as FolderSvg } from '../../assets/icons/folder.svg';
import { ReactComponent as FileSvg } from '../../assets/icons/file.svg';
import { ReactComponent as ChevronRightSvg } from '../../assets/icons/chevron-right.svg';
import FolderService from '../../services/folder.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { requestError } from '../../helpers/notification';
import {
    buildParentDirectoryStructure,
    clearExplorerSelected,
    setExplorerItemSelected,
    getPlanningAccess,
    USER_ACCESS,
} from '../../helpers/planning';
import { UserContext } from '../../contexts/app/UserContext';
import { generateString } from '../../helpers/string-helper';

const ExplorerRow = styled.div``;
const ExplorerCol = styled.div`
    flex: 0 0 204px;
    background-color: #f5f5f5;
    height: 406px;
    color: #828282;
`;

const ExplorerLine = styled.div``;
const FolderIcon = styled(FolderSvg)``;
const FileIcon = styled(FileSvg)``;
const ChevronRightIcon = styled(ChevronRightSvg)``;

const FolderExplorer = ({ mode, onSelected }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [loadingFolder, setLoadingFolder] = useState(null);
    const [explorerItems, setExplorerItems] = useState([]);
    const {
        requestPlanningsInFolderForMode,
        rootFolders,
        subFoldersList,
        updateSubFoldersList,
        updatedFolderBroadcast,
    } = useContext(PlanningContext);
    const { userPreferences, currentUser } = useContext(UserContext);

    const errorMessageTranslation = useTranslation('translation', { keyPrefix: 'error_message' }).t;

    useEffect(() => {
        const onUpdateMode = async () => {
            setLoadingData(true);

            setExplorerItems(rootFolders);

            if (userPreferences?.open_folder && userPreferences?.open_folder.id) {
                await buildParentDirectoryStructure(userPreferences.open_folder.id).then(async (result) => {
                    if (!result.length) {
                        setLoadingData(false);
                        return;
                    }
                    const loadFolders = async (index) => {
                        if (index < result.length) {
                            // eslint-disable-next-line no-use-before-define
                            await onSelectExplorerItem(result[index], index + 1)
                                .then(() => {
                                    loadFolders(index + 1);
                                })
                                .catch(() => setLoadingData(false));
                        }
                    };

                    await loadFolders(0);
                    setLoadingData(false);
                });
            } else {
                setLoadingData(false);
            }
        };
        onUpdateMode();
    }, [mode]);

    useEffect(() => {
        const onUpdatedFolder = async () => {
            if (updatedFolderBroadcast && !loadingData) {
                const { updatedData } = updatedFolderBroadcast;
                setLoadingData(true);

                // eslint-disable-next-line no-use-before-define
                const currentSelected = getExplorerItem(explorerItems);
                let selectedFolder = null;
                if (currentSelected && !currentSelected.rootActivityId && updatedData) {
                    selectedFolder = currentSelected;
                } else {
                    selectedFolder = userPreferences.open_folder ? userPreferences.open_folder : null;
                }
                if (selectedFolder) {
                    await buildParentDirectoryStructure(selectedFolder.id).then(async (result) => {
                        if (!result.length) {
                            setLoadingData(false);
                            return;
                        }
                        const loadFolders = async (index) => {
                            if (index < result.length) {
                                // eslint-disable-next-line no-use-before-define
                                await onSelectExplorerItem(result[index], index + 1)
                                    .then(() => {
                                        loadFolders(index + 1);
                                    })
                                    .catch(() => setLoadingData(false));
                            }
                        };

                        await loadFolders(0);
                        setLoadingData(false);
                    });
                } else {
                    setLoadingData(false);
                }
            }
        };
        onUpdatedFolder();
    }, [updatedFolderBroadcast]);

    const getExplorerItem = (list) => {
        let selected = null;

        list.forEach((item) => {
            if (selected) {
                return;
            }

            if (item.selected) {
                selected = item;
                if (item.childFolders || item.plannings) {
                    const selectedChild = getExplorerItem([...(item.childFolders || []), ...(item.plannings || [])]);

                    if (selectedChild) {
                        selected = selectedChild;
                    }
                }
            }
        });

        return selected;
    };

    const onSelectExplorerItem = (item, depth) =>
        new Promise((resolve, reject) => {
            setLoadingFolder(item.id);
            if (item.disabled) {
                setLoadingData(false);
                return;
            }
            if (item.isFile) {
                setExplorerItems((origin) => {
                    const newItems = setExplorerItemSelected(clearExplorerSelected(origin, depth), item.id, [], []);
                    onSelected(getExplorerItem(newItems));
                    return newItems;
                });
                setLoadingFolder(null);
                resolve();
            } else if (subFoldersList[item.id]) {
                console.log('=> USING SUBFOLDER CACHE');
                const folder = subFoldersList[item.id];
                requestPlanningsInFolderForMode(item.id, mode)
                    .then((plannings) => {
                        const planningList = plannings.map((p) => {
                            const planningAccess = getPlanningAccess(p, currentUser);

                            return {
                                ...p,
                                access: planningAccess,
                                disabled: planningAccess === USER_ACCESS.NONE,
                            };
                        });
                        setExplorerItems((origin) => {
                            const newItems = setExplorerItemSelected(
                                clearExplorerSelected(origin, depth),
                                item.id,
                                folder.childFolders,
                                planningList
                            );
                            onSelected(getExplorerItem(newItems));
                            return newItems;
                        });
                        setLoadingFolder(null);
                        resolve();
                    })
                    .catch((error) => {
                        requestError(error, errorMessageTranslation('error_when_retrieving_plannings'));
                        setLoadingFolder(null);
                        reject();
                    });
            } else {
                FolderService.listSubFolders(item.id)
                    .then((folder) => {
                        updateSubFoldersList(item.id, folder);
                        requestPlanningsInFolderForMode(item.id, mode)
                            .then((plannings) => {
                                const planningList = plannings.map((p) => {
                                    const planningAccess = getPlanningAccess(p, currentUser);

                                    return {
                                        ...p,
                                        access: planningAccess,
                                        disabled: planningAccess === USER_ACCESS.NONE,
                                    };
                                });
                                setExplorerItems((origin) => {
                                    const newItems = setExplorerItemSelected(
                                        clearExplorerSelected(origin, depth),
                                        item.id,
                                        folder.childFolders,
                                        planningList
                                    );
                                    onSelected(getExplorerItem(newItems));
                                    return newItems;
                                });
                                setLoadingFolder(null);
                                resolve();
                            })
                            .catch((error) => {
                                requestError(error, errorMessageTranslation('error_when_retrieving_plannings'));
                                setLoadingFolder(null);
                                reject();
                            });
                    })
                    .catch((error) => {
                        requestError(error, errorMessageTranslation('error_when_retrieving_folders'));
                        setLoadingFolder(null);
                        reject();
                    });
            }
        });

    const renderExplorerCol = (list, depth = 0) => (
        <>
            <ExplorerCol key={`explorer-col-${generateString(5)}`} className="rounded mx-2 p-2 overflow-auto">
                {list.length ? (
                    list.map((explorerItem) => (
                        <ExplorerLine
                            className={`flex items-center px-2 py-1 mb-2 cursor-pointer rounded-sm transition transition-colors ${
                                explorerItem.selected
                                    ? 'bg-primary text-secondary-dark'
                                    : 'hover:bg-primary hover:bg-opacity-80 hover:text-secondary-dark'
                            }
                            ${explorerItem.disabled ? 'bg-transparent text-gray-300 cursor-not-allowed' : ''}
                            `}
                            onClick={() => onSelectExplorerItem(explorerItem, depth)}
                            key={(explorerItem.isFile ? 'planning-' : 'folder-') + explorerItem.id}
                        >
                            {explorerItem.isFile ? (
                                <FileIcon className="mr-2 stroke-current" />
                            ) : (
                                <FolderIcon className="mr-2 stroke-current" />
                            )}
                            {explorerItem.name}

                            {!explorerItem.isFile && explorerItem.id === loadingFolder && (
                                <LoadingOutlined spin className="ml-auto stroke-current" />
                            )}
                            {!explorerItem.isFile && explorerItem.id !== loadingFolder && (
                                <ChevronRightIcon className="ml-auto stroke-current" />
                            )}
                        </ExplorerLine>
                    ))
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </ExplorerCol>
            {list.map(
                (explorerItem) =>
                    explorerItem.selected &&
                    !explorerItem.isFile &&
                    renderExplorerCol(
                        [
                            ...('childFolders' in explorerItem ? explorerItem.childFolders : []),
                            ...(explorerItem.plannings
                                ? explorerItem.plannings.map((planning) => ({ ...planning, isFile: true }))
                                : []),
                        ],
                        depth + 1
                    )
            )}
        </>
    );
    return (
        <Spin spinning={loadingData} size="large">
            <ExplorerRow className="flex -mx-2 pb-2 overflow-x-auto">{renderExplorerCol(explorerItems)}</ExplorerRow>
        </Spin>
    );
};

export default FolderExplorer;
