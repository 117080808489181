import * as moment from 'moment';
import { AntDValueEditor } from '@react-querybuilder/antd';
import { DatePicker, Input, InputNumber, Select } from 'antd';
// import { isNil } from 'lodash';
import { JOB_FIELD } from '../../../constants/Jobs';
import {conditionEnum} from '../FilterUtils';
import { generateString } from '../../../helpers/string-helper';
/* eslint-disable */

const CustomValueEditor = (props) => {
    if (props.inputType === 'startDate') {
        return (
            <div>
                <DatePicker
                    className="w-full"
                    defaultValue={!props.value ? null : moment(props.value)}
                    format={window.dateStringFormat.concat(' HH:mm')}
                    allowClear={false}
                    showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        format: 'HH:mm',
                    }}
                    onChange={(value) => props.handleOnChange(value.clone().valueOf())}
                />
            </div>
        );
    }
    if (props.inputType === 'endDate') {
        return (
            <div>
                <DatePicker
                    className="w-full"
                    defaultValue={!props.value ? null : moment(props.value)}
                    format={window.dateStringFormat.concat(' HH:mm')}
                    allowClear={false}
                    showTime={{
                        defaultValue: moment('23:59:59', 'HH:mm:ss'),
                        format: 'HH:mm',
                    }}
                    onChange={(value) => props.handleOnChange(value.clone().valueOf())}
                />
            </div>
        );
    }
    if (props.inputType === 'searchSelect') {
        const defaultValue = props.value;

        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = props.values[0].value;
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div key={generateString(5)}>
                <Select
                    style={{width: '200px'}}
                    showSearch
                    optionFilterProp="label"
                    defaultValue={defaultValue}
                    onChange={(value) => props.handleOnChange(value)}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={props.values}
                />
            </div>
        );
    }
    if (props.inputType === 'numberMinMax') {
        const defaultValue = props.value;
        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = 0;
        //     props.handleOnChange(0);
        // }
        
        return (
            <div>
                <InputNumber
                    defaultValue={defaultValue}
                    min={props.fieldData.min}
                    max={props.fieldData.max}
                    onChange={(value) => props.handleOnChange(value)}
                />
            </div>
        );
    }
    if (props.inputType === 'numberMinMaxAndSelect') {
        const defaultValue = props.value
        // // save on first time
        // if (typeof props.value !== "object") {
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div className="flex">
                <InputNumber
                    className="mr-2"
                    defaultValue={defaultValue.value}
                    min={props.fieldData.min}
                    max={props.fieldData.max}
                    onChange={(value) => props.handleOnChange({
                        value,
                        unit: props.value.unit
                    })}
                />
                <Select
                    style={{minWidth: '100px'}}
                    defaultValue={defaultValue.unit}
                    options={props.fieldData.selectChoices}
                    onChange={(val) => props.handleOnChange({
                        value: props.value.value,
                        unit: val
                    })}
                />
            </div>
        );
    }
    if (props.fieldData.label === JOB_FIELD && (props.operator === conditionEnum.LIKE.name || props.operator === conditionEnum.NOT_LIKE.name)) {
        return (
            <div>
                <Input
                    defaultValue={props.value}
                    onChange={(e) => props.handleOnChange(e.target.value)}
                />
            </div>
        );
    }
    if (props.fieldData.label === JOB_FIELD && (props.operator === conditionEnum.EQUAL.name || props.operator === conditionEnum.DIFFERENT.name)) {
        const defaultValue = props.value;

        // if (isNil(props.value) || props.value === '' || !choiceValidator(props, props.fieldData.selectChoices, 'value')) {
        //     defaultValue = props.fieldData.selectChoices[0].value;
        //     props.handleOnChange(props.fieldData.selectChoices[0].value)
        // }
        return (
            <div key={generateString(5)}>
                <Select
                    style={{minWidth: '100px'}}
                    defaultValue={defaultValue}
                    options={props.fieldData.selectChoices}
                    onChange={(val) => props.handleOnChange(val)}
                />
            </div>
        );
    }
    const originalProps = { ...props };
    delete originalProps.hideHours;
    return <AntDValueEditor {...originalProps} />;
};
export default CustomValueEditor;
