
import React, { useEffect, useRef,  memo } from 'react';
import { RegularPolygon, Transformer } from 'react-konva';

const Polygon = memo(({ id, sides, fill, stroke, x, y, width, height,  isSelected, onChangePosition, onChange, onSelect }) => {
    const polygonRef = useRef();
    const transformerRef = useRef();

    const handleDragEnd = (e) => {
        onChangePosition({ id, x: e.target.x(), y: e.target.y() });
    };

    const handleTransformEnd = () => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node = polygonRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
        onChange({
            id,
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            x: node.x(), 
            y: node.y()
        });
        // onChangePosition({ id, x: node.x(), y: node.y() });
    };
    // const isSelected = useMemo(() => selectedShapeId === id, [selectedShapeId])

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            transformerRef.current.nodes([polygonRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <>
            <RegularPolygon
              onClick={onSelect}
              onTap={onSelect}
                ref={polygonRef}
                x={x}
                y={y}
                width={width}
                height={height}
                sides={sides}
                fill={fill}
                stroke={stroke}
                draggable
                onDragEnd={handleDragEnd}
                onTransformEnd={handleTransformEnd}
                perfectDrawEnabled={false}
            />
            {isSelected && (
                <Transformer
                    ref={transformerRef}
                    flipEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
});

export default Polygon;
