import { createSlice,  createSelector, createEntityAdapter } from '@reduxjs/toolkit';

const shapesAdapter = createEntityAdapter({
  // Define entity properties (type, x, y, size, color, etc.)
  // Define unique identifier key (e.g., id)
});

const initialState = shapesAdapter.getInitialState({selectedShapeId: null});

const shapesSlice = createSlice({
  name: 'shapes',
  initialState,
  reducers: {
    addShape: shapesAdapter.addOne,
    updateShape: shapesAdapter.upsertOne,
    // updateShape: (state, action) => {
    //   const { id, updates } = action.payload;
    //   // Use the adapter's updateOne method to create a new state
    //   const newState = shapesAdapter.updateOne(state, updates);
    //   // Return the updated state
    //   return newState;
    // },
    shapesReceived: (state, action) => {
      state.selectedShapeId = null;
      shapesAdapter.setAll(state, action.payload.shapes)
    },
    selectShape: (state, action) => {
      // Update selected shape state based on ID
      const {id} = action.payload;
      // state.ids.forEach((currentId) => {
      // })
      // remove old selection
      if (state.selectedShapeId && state.entities[state.selectedShapeId]) {
        state.entities[state.selectedShapeId].isSelected = false;
      }
      state.entities[id].isSelected = true;
      state.selectedShapeId = id;

    },
    unselectShape: (state, action) => {
      // Reset selected shape state
      const {id} = action.payload;
      state.entities[id].isSelected = false;
    },
    moveShape: (state, action) => {
      // Update shape entity with new X and Y coordinates using adapter methods
      const {id, x, y} = action.payload;
      state.entities[id].x = x;
      state.entities[id].y = y;
    },
    resizeShape: (state, action) => {
      // Update shape entity with new size using adapter methods
      const {id, height, width} = action.payload;
      state.entities[id].height = height;
      state.entities[id].width = width;
    },
    clearShapes: (state) => {
      shapesAdapter.removeAll(state);
    },
  },
});

export const { addShape, updateShape, shapesReceived, selectShape, unselectShape, moveShape, resizeShape, clearShapes } = shapesSlice.actions;

export const selectAllShapes = createSelector(
  (state) => state.shapes,
  shapesAdapter.getSelectors().selectAll
);
export const selectedShapeId = (state) => 
  state.shapes.selectedShapeId




// Add other selectors as needed (e.g., selectedShape)

export default shapesSlice.reducer;