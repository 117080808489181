/* eslint-disable */
import React, {useState, useRef, useEffect} from 'react';
import { InputNumber, Row } from 'antd';
import { Stage, Layer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import Polygon from './shapes/Polygon';
import { moveShape, selectAllShapes, selectShape, shapesReceived, updateShape } from '../../../store/slices/shape.slice';
import { generateRegularPolygonMockData } from '../../../store/utils/ShapeUtils';

const PerformanceTest = () => {
    const [shapeCount, setShapeCount] = useState(100);
    const dispatch = useDispatch()
    const shapes = useSelector(selectAllShapes)
    const [containerSize, setShapeContainerSize] = useState({w: 1000, h: 1000});
    const konvaContainer = useRef(null)
    useEffect(() => {
        const mockData = [...Array(shapeCount).keys()].map(() => {
            const data = generateRegularPolygonMockData()
            // dispatch(addShape(data))
            return data
        })
        dispatch(shapesReceived({shapes: mockData}))
    }, [shapeCount, containerSize])

    
    
    return (
        <div className="flex flex-grow h-full w-full flex-col">
            <Row className=" my-2 flex w-full flex justify-center items-center">
                <label>Shape count :</label>
                <InputNumber value={shapeCount} onChange={(val) => setShapeCount(val)} />
                <label>Canvas width :</label>
                <InputNumber
                    value={containerSize.w}
                    onChange={(val) => setShapeContainerSize((oldval) => ({ ...oldval, w: val }))}
                />
                <label>Canvas Height :</label>
                <InputNumber
                    value={containerSize.h}
                    onChange={(val) => setShapeContainerSize((oldval) => ({ ...oldval, h: val }))}
                />
            </Row>
            <Row className="flex-grow w-full" ref={konvaContainer}>
                <div
                    className="border overflow-auto"
                    style={{ width: `${containerSize.w}px`, height: `${containerSize.h}px`, borderColor: 'red' }}
                >
                    <Stage width={window.innerWidth} height={window.innerHeight}>
                        <Layer>
                            {shapes.map((shape) => (
                                <Polygon key={shape.id} {...shape}  onSelect={() => dispatch(selectShape({id: shape.id}))} onChangePosition={(data) => dispatch(moveShape(data))} onChange={(data) => dispatch(updateShape(data))} />
                            ))}
                        </Layer>
                    </Stage>
                </div>
            </Row>
        </div>
    );
};

export default PerformanceTest;
