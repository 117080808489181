import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { detailedDiff } from 'deep-object-diff';
import { clone, isNil } from 'lodash';
import FolderService from '../../services/folder.service';
import CustomFieldService from '../../services/custom-field.service';
import ActivityService from '../../services/activity.service';
import CalendarService from '../../services/calendar.service';
import { notificationError, requestError } from '../../helpers/notification';
import {
    clearExplorerSelected,
    setExplorerItemSelected,
    formatGanttTask,
    USER_ACCESS,
    getPlanningAccess,
    ACTIVITY_STATE,
    flattenGanttNodesOpt,
    generateGroupedGanttNodes,
    generateFilteredGanttNodes,
} from '../../helpers/planning';
import { UserContext } from './UserContext';
import authService from '../../services/auth.service';
// import DefaultPlanningField from '../../constants/DefaultPlanningField';
// import { getFilterFieldIds } from '../../helpers/filters';
import userService from '../../services/user.service';
import { toMap } from '../../helpers/array-filter';
import taskStripes from '../../helpers/task-stripes';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { toCalendar, toSlice } from '../../helpers/slicing-helper';
import { queryBuilderToUserFilter } from '../../components/filters/FilterUtils';
import { saveScrollState } from '../../components/plannings/gantt_events';

const PlanningContext = createContext({
    folders: [],
    folderSelected: null,
    planningSelected: null,
    modeSelected: null,
    planningCustomFields: [],
    allCustomFields: [],
    loadingGantt: false,
    processingGantt: false,
    activitiesDictionary: [],
    calendarsDictionary: [],
    calendarsDictionaryLoaded: 0,
    zoomLevel: null,
    tasksStripesStyles: [],
    setFolders: () => {},
    setFolderSelected: () => {},
    setModeSelected: () => {},
    updatePlanning: () => {},
    refreshPlanningSelected: () => {},
    updatePlanningCustomFields: () => {},
    setLoadingGantt: () => {},
    setZoomLevel: () => {},
    setTasksStripesStyles: () => {},
    jobs: [],
});

// const REFRESH_ACTIVITIES_DICTIONARY = 5 * 60 * 1000;

const PlanningContextProvider = ({ children }) => {
    const [folders, setFolders] = useState([]);
    const [folderSelected, setFolderSelected] = useState(null);
    const [planningSelected, setPlanningSelected] = useState(null);
    const [modeSelected, setModeSelected] = useState('');
    const [planningCustomFields, setPlanningCustomFields] = useState([]);
    const [allCustomFields, setAllCustomFields] = useState([]);
    const [loadingGantt, setLoadingGantt] = useState(false);
    const [processingGantt, setProcessingGantt] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [activitiesDictionary, setActivitiesDictionary] = useState({});
    const [filteredFlattenActivities, setFilteredFlattenActivities] = useState({});
    const [baselineDictionary, setBaselineDictionary] = useState([]);
    // const [activitiesDictionaryInterval, setActivitiesDictionaryInterval] = useState();
    const [calendarsDictionary, setCalendarsDictionary] = useState({});
    const [tasksStripesStyles, setTasksStripesStyles] = useState([]);
    const { updateUserPreferences, userPreferences, canFetchData, currentUser } = useContext(UserContext);
    const [planningPreferences, setPlanningPreferences] = useState(null); // set in TabContext, like planningPreferences (other state because of cycle dependency)

    const [tabFilterData, setTabFilterData] = useState([]);
    const [refreshGantt, setRefreshGantt] = useState(0);
    const [reRenderGantt, setReRenderGantt] = useState(0);
    const [spinProgress, setSpinProgress] = useState(0);
    const [jobs, setJobs] = useState([]);
    // only for chart, we need to have a copy here because the chart context is only for chart components but we need selected items in printing
    const [ganttHistogramChartNeedList, setGanttHistogramChartNeedList] = useState([]);

    const [planningPreferencesGot, setPlanningPrefrencesGot] = useState(false); // state to check if can fetch data only when tabPreferences got
    const { t } = useTranslation();

    const loadUserJobs = async (planning) => {
        const jobsList = await ActivityService.listUserJobs(planning?.rootActivityId);
        setJobs(jobsList.map((job) => job.value));
    };

    const userJobsWithoutMulti = useCallback(() => (jobs || []).filter((job) => job !== ALL_JOBS), [jobs]);

    const isUserJob = useCallback(
        (job) =>
            (jobs || []).filter((userJob) => (job || []).filter((i) => i === userJob).length > 0).length > 0 ||
            (job || []).findIndex((j) => j === ALL_JOBS) !== -1,
        [jobs]
    );

    const isMultiJobUser = useCallback(() => (jobs || []).filter((job) => job === ALL_JOBS).length > 0, [jobs]);

    const canAccess = useCallback(() => {
        if (currentUser.readOnly) return false;
        return [USER_ACCESS.ADMIN, USER_ACCESS.OWNER, USER_ACCESS.READWRITE].includes(
            getPlanningAccess(planningSelected, currentUser)
        );
    }, [planningSelected, currentUser]);

    const canAccessToPlanning = (planning) =>
        [USER_ACCESS.ADMIN, USER_ACCESS.OWNER, USER_ACCESS.READWRITE, USER_ACCESS.READ].includes(
            getPlanningAccess(planning, currentUser)
        );

    const canEditActivity = (id, status, jobId) => {
        // Activity editable rule, not editable if :
        // - Archive planning
        // - Validated activity state
        // - User haven't write access
        // - Not project and User job isn't included and user isn't MultiJobUser and activity isn't Multijob
        const isProject = +id === planningSelected.rootActivityId;
        const archived = modeSelected === 'archive';
        const validated = status !== ACTIVITY_STATE.UNVALID;
        const noWriteAccess = !canAccess();
        const isMultiJobActivity = jobId.filter((job) => job === ALL_JOBS).length > 0;
        const noJobRule = !isProject && !isUserJob(jobId) && !isMultiJobUser() && !isMultiJobActivity;
        const noEdit = archived || validated || noWriteAccess || noJobRule;
        return !noEdit;
    };

    // cache time units
    const [timeUnits, setTimeUnits] = useState([]);
    useEffect(() => {
        if (window._env_.REACT_APP_CALENDAR_API && canFetchData) {
            CalendarService.listTimeUnits()
                .then((data) => setTimeUnits(data))
                .catch((error) => requestError(error, t('planning_context.error_get_time_units')));
        }
    }, [canFetchData]);

    const updatePlanningCustomFields = async (planning) => {
        const currentPlanning = planning || planningSelected;
        return new Promise((resolve) => {
            if (currentPlanning) {
                CustomFieldService.planningCustomFields(currentPlanning.id)
                    .then((customFieldsOfPlanning) => {
                        setPlanningCustomFields(customFieldsOfPlanning);
                        console.log(
                            '🚀 ~ file: PlanningContext.jsx:154 ~ .then ~ customFieldsOfPlanning:',
                            customFieldsOfPlanning
                        );
                        CustomFieldService.defaultsCustomFields()
                            .then((customFields) => {
                                setAllCustomFields([
                                    ...customFields.map((field) => ({
                                        ...field,
                                        name: t('default_fields.'.concat(field.name)),
                                    })),
                                    ...customFieldsOfPlanning,
                                ]);
                                resolve(customFieldsOfPlanning);
                            })
                            .catch((error) => {
                                requestError(error, t('planning_context.error_get_planning_fields'));
                                resolve([]);
                            });
                    })
                    .catch((error) => {
                        requestError(error, t('planning_context.error_get_planning_fields'));
                        resolve([]);
                    });
            }
        });
    };

    const updatePlanningPreferences = useCallback(
        (newPreferences, updateServer = true) =>
            new Promise((resolve) => {
                const updatedPreference = Object.assign(planningPreferences || {}, newPreferences);
                if (updateServer) {
                    authService
                        .updatePlanningTabWithPreferences(userPreferences.activeTab?.[planningSelected.id], updatedPreference)
                        .then((tabPreferencesUpdated) => {
                            resolve(tabPreferencesUpdated);
                        })
                        .catch((error) => {
                            requestError(error, t('tab_context.error_update_tab_preferences'));
                            resolve(planningPreferences);
                        });
                } else {
                    resolve(updatedPreference);
                }
            }),
        [userPreferences, planningSelected, planningPreferences]
    );

    const updatePlanning = async (newPlanning, refetch = true) => {
        if (newPlanning) {
            try {
                console.log('XXX2');
                await saveScrollState(updatePlanningPreferences);

                setLoadingGantt(true);
                // to avoid activity duplicated
                setReRenderGantt(0);
                setRefreshGantt(0);
                setSpinProgress(0);
                /* eslint no-use-before-define:off */
                setDisableReloading(true);

                if (refetch) {
                    flushSync(() => {
                        setActivitiesDictionary({});
                    });
                    // setCalendarCacheDates([]);
                }
                // setPlanningSelected(null);
                if (Object.keys(userPreferences).length === 0) {
                    await updateUserPreferences({
                        open_planning: { ...userPreferences.open_planning, [modeSelected]: newPlanning },
                    });
                    // await updatePlanningCustomFields(newPlanning);
                } else if (
                    !userPreferences.open_planning ||
                    (userPreferences.open_planning &&
                        userPreferences.open_planning[modeSelected]?.id !== newPlanning.id)
                ) {
                    await updateUserPreferences({
                        open_planning: { ...userPreferences.open_planning, [modeSelected]: newPlanning },
                    });
                    // await updatePlanningCustomFields(newPlanning);
                }
                loadUserJobs(newPlanning);
                userService.updateCurrentPlanning(newPlanning.id, newPlanning.name).catch((err) => {
                    console.log('🚀 ~ file: PlanningContext.jsx:241 ~ updatePlanning ~ err:', err);
                });
            } catch (error) {
                requestError(error, t('planning_context.error_load_planning'));
            }
        }
        setPlanningSelected(() => {
            setPlanningPrefrencesGot(false);
            return newPlanning;
        });
    };

    const refreshPlanningSelected = async (refetchActivity = false) => {
        console.log('🚀 ~ file: PlanningContext.jsx:261 ~ refreshPlanningSelected ~ refetchActivity:', refetchActivity);
         await saveScrollState(updatePlanningPreferences);
        setLoadingGantt(true);
        // to avoid activity duplicated
        setReRenderGantt(0);
        setRefreshGantt(0);
        if (refetchActivity) {
            setActivitiesDictionary({});
        }
        // if (refetchCalendar) {
        //     setCalendarCacheDates([]);
        // }
        const planning =
            modeSelected === 'sandbox'
                ? FolderService.showSandboxPlanning(planningSelected.id)
                : FolderService.showPlanning(planningSelected.id);
        planning.then((planningUpdated) => {
            setPlanningSelected((origin) => {
                setPlanningPrefrencesGot(false);
                return { ...origin, planningUpdated };
            });
            // updatePlanningCustomFields(planningUpdated);
            setDisableReloading(true);
        });
    };

    const requestPlanningsInFolderForMode = (folderId, mode) => {
        switch (mode) {
            case 'archive':
                return FolderService.listArchives(folderId);
            case 'sandbox':
                return FolderService.listSandboxPlannings(folderId);
            case 'live':
                return FolderService.listPlannings(folderId);
            default:
                return FolderService.listPlannings(folderId);
        }
    };

    const generateFilteredFlattenActivities = useCallback(
        async (activityDict, customFields) => {
            const filteredGroup = planningPreferences?.filtered_group;
            console.log('🚀 ~ file: PlanningContext.jsx:261 ~ tabFilterData:', tabFilterData, timeUnits);
            const filterDataToSend = tabFilterData?.rawData.map((i) => queryBuilderToUserFilter(i.data, timeUnits, customFields, modeSelected === 'archive'));
            // adding dateFilter
            if (tabFilterData?.dateFilter) {
                filterDataToSend.push(queryBuilderToUserFilter(tabFilterData?.dateFilter, timeUnits));
            }
            console.log(
                '🚀 ~ file: PlanningContext.jsx:319 ~ returnnewPromise ~ tabFilterData?.rawData[0].query:',
                filterDataToSend
            );
            // test color field if exists
            const isColorFieldExist =
                customFields.find((field) => field.id === planningPreferences?.planning_color?.id) !== undefined;
            console.log('🚀 ~ file: PlanningContext.jsx:283 ~ planningPreferences:', planningPreferences);
            if (!isColorFieldExist && !isNil(planningPreferences?.planning_color)) {
                console.log('SETTING COLOR TO NULL');
                authService.updatePlanningTabWithPreferences(userPreferences.activeTab?.[planningSelected.id], {
                    ...planningPreferences,
                    planning_color: null,
                });
            }
            return new Promise((resolve) => {
                if (filteredGroup?.groupingType === 'custom_fields') {
                    generateGroupedGanttNodes(
                        activityDict,
                        planningSelected.summaryId ?? planningSelected.rootActivityId,
                        isColorFieldExist ? planningPreferences?.planning_color : null,
                        filteredGroup.simpleActivityOnly,
                        filteredGroup.sortOptions,
                        filterDataToSend
                    )
                        .then((activities) => {
                            setFilteredFlattenActivities(flattenGanttNodesOpt(activities));
                            resolve(true);
                        })
                        .catch(() => {
                            notificationError('Gantt', t('gantt.error_grouping'), { duration: 0 });
                            setFilteredFlattenActivities([]);
                            resolve(true);
                        });
                } else {
                    generateFilteredGanttNodes(
                        activityDict,
                        planningSelected.summaryId ?? planningSelected.rootActivityId,
                        isColorFieldExist ? planningPreferences?.planning_color : null,
                        filterDataToSend
                    )
                        .then((activities) => {
                            setFilteredFlattenActivities(flattenGanttNodesOpt(activities));
                            resolve(true);
                        })
                        .catch((eroor) => {
                            console.log('🚀 ~ file: PlanningContext.jsx:297 ~ ).then ~ eroor:', eroor);
                            notificationError('Gantt', t('gantt.error_filtering'), { duration: 0 });

                            setFilteredFlattenActivities([]);
                            resolve(true);
                        });
                }
            });
        },
        [planningPreferences, tabFilterData, planningSelected, timeUnits, modeSelected]
    );

    const fetchActivities = useCallback(
        async (customFields) => {
            let baseLineActivities = [];
            const baseLinePreference = planningPreferences?.gantt_baseline;
            console.log(
                '🚀 ~ file: PlanningContext.jsx:257 ~ fetchActivities ~ baseLinePreference',
                baseLinePreference
            );

            if (baseLinePreference?.showBaseLine && baseLinePreference?.baseLinePlanning) {
                baseLineActivities = (
                    await ActivityService.generateFlatten(baseLinePreference.baseLinePlanning.rootActivityId)
                ).reduce((activitiesDict, activity) => ({ ...activitiesDict, [activity.identity]: activity }), {});
                setBaselineDictionary(baseLineActivities);
            }
            return new Promise((resolve) => {
                if (!planningSelected) {
                    resolve(null);
                }

                // test if should update dictionnary (fetch when activity not in current dictionnary)
                if (activitiesDictionary[planningSelected.rootActivityId]) {
                    console.log('=> Using flatten activities cache');
                    generateFilteredFlattenActivities(activitiesDictionary, customFields).then(() => {
                        resolve(true);
                    });
                } else {
                    ActivityService.generateFlatten(planningSelected.rootActivityId)
                        .then((activitiesList) => {
                            const dict = {};
                            activitiesList.forEach((act) => {
                                const activity = act;
                                const durationUnit = timeUnits.find(
                                    (timeUnit) => timeUnit.id === Number(activity.dayDefinitionId)
                                );
                                activity.baseLineStartDate = baseLineActivities[activity.identity]?.startDate || null;
                                activity.baseLineEndDate = baseLineActivities[activity.identity]?.endDate || null;
                                activity.baseLineProgress = baseLineActivities[activity.identity]?.avancement || 0;
                                activity.taskDuration = activity.duration / (durationUnit?.duration || 1);
                                // activity.affectedResources = [];
                                if (modeSelected === 'archive') {
                                    activity.unifiedfields = act.unifiedfields.map((unifiedField) => {
                                        const newUnifiedField = unifiedField;
                                        const customField = customFields.find((i) => i.realId === unifiedField.id);
                                        newUnifiedField.id = customField.id;
                                        return newUnifiedField;
                                    });
                                }
                                dict[activity.id] = activity;
                            });
                            generateFilteredFlattenActivities(dict, customFields).then(() => {
                                setActivitiesDictionary(dict);
                                console.log('=> Got flatten activities :');
                                setSpinProgress(40);
                                resolve(true);
                            });
                        })
                        .catch(async (error) => requestError(error, t('planning_context.error_get_activities')));
                }
            });
        },
        [planningSelected, activitiesDictionary, planningPreferences, timeUnits, tabFilterData, modeSelected]
    );
    const activityDictionnaryRef = useRef(null);
    useEffect(() => {
        activityDictionnaryRef.current = activitiesDictionary;
    }, [activitiesDictionary]);
    // using array to be able to change values in functions (reference passing)
    // eslint-disable-next-line
    const [calendarCacheDates, setCalendarCacheDates] = useState([]);
    const dateToString = (d) => moment.utc(d).local(true).format('YYYY-MM-DD HH:mm:ss');
    const fetchCalendars = useCallback(
        async (debut, fin) => {
            // let calendarCacheDate;
            // setCalendarCacheDates((val) => {
            //     calendarCacheDate = val;
            //     return val;
            // });
            console.log('=> Fetching calendars', planningPreferences);
            return new Promise((resolve) => {
                if (!planningSelected) {
                    resolve(null);
                }
                const getCalendars = (activity) => {
                    let rootActivityStartDate = debut || moment(activity.startDate).subtract(1, 'day').valueOf();
                    let rootActivityEndDate = fin || moment(activity.endDate).add(1, 'day').valueOf();
                    rootActivityStartDate = moment(rootActivityStartDate).subtract(1, 'day').valueOf();
                    rootActivityEndDate = moment(rootActivityEndDate).add(1, 'day').valueOf();
                    let needUpdate = false;
                    if (calendarCacheDates?.length === 0) {
                        needUpdate = true;
                        setCalendarCacheDates([rootActivityStartDate, rootActivityEndDate]);
                    }
                    if (
                        calendarCacheDates &&
                        (calendarCacheDates[0] > rootActivityStartDate || calendarCacheDates[1] < rootActivityEndDate)
                    ) {
                        needUpdate = true;
                        setCalendarCacheDates([rootActivityStartDate, rootActivityEndDate]);
                    }
                    console.log('🚀 ~ file: PlanningContext.jsx:443 ~ getCalendars ~ needUpdate:', needUpdate);

                    if (needUpdate) {
                        // updating
                        if (calendarsDictionary['-2']) {
                            console.log('updating existing cache');
                            const calendarPromises = [];
                            Object.values(calendarsDictionary).forEach((calendar) => {
                                const promiseCalendar = new Promise((resolveFct) => {
                                    const noWorkingReals = CalendarService.listNoWorkingReal(
                                        calendar.id,
                                        rootActivityStartDate,
                                        rootActivityEndDate
                                    );
                                    // );
                                    Promise.allSettled([noWorkingReals])
                                        .then((noWorkingTime) => {
                                            setCalendarsDictionary((origin) => ({
                                                ...origin,
                                                [calendar.id]: {
                                                    ...calendar,
                                                    noWorkingReals: toSlice(noWorkingTime[0].value) ?? [],
                                                    noWorkingHours: toMap(
                                                        toCalendar(toSlice(noWorkingTime[0].value), 'hours'),
                                                        dateToString
                                                    ),
                                                    noWorkingDays: toMap(
                                                        toCalendar(toSlice(noWorkingTime[0].value), 'days'),
                                                        dateToString
                                                    ),
                                                    noWorkingWeek: toMap(
                                                        toCalendar(toSlice(noWorkingTime[0].value), 'week'),
                                                        dateToString
                                                    ),
                                                    noWorkingMonth: toMap(
                                                        toCalendar(toSlice(noWorkingTime[0].value), 'month'),
                                                        dateToString
                                                    ),
                                                },
                                            }));
                                            resolveFct('ok');
                                        })
                                        .catch((error) => {
                                            requestError(
                                                error,
                                                t('planning_context.error_get_calendar_no_working_days', {
                                                    calendar: calendar.name,
                                                })
                                            );
                                            resolveFct('ko');
                                        });
                                    // }
                                });
                                calendarPromises.push(promiseCalendar);
                            });
                            Promise.all(calendarPromises).then(() => {
                                // setLoadingGantt(false);
                                console.log('=> Got calendars');
                                setSpinProgress(50);
                                resolve(true);
                            });
                        } else {
                            CalendarService.listCalendars()
                                .then(async (calendars) => {
                                    const calendarPromises = [];
                                    calendars.forEach((calendar) => {
                                        const promiseCalendar = new Promise((resolveFct) => {
                                            const noWorkingReals = CalendarService.listNoWorkingReal(
                                                calendar.id,
                                                rootActivityStartDate,
                                                rootActivityEndDate
                                            );
                                            Promise.allSettled([noWorkingReals])
                                                .then((noWorkingTime) => {
                                                    setCalendarsDictionary((origin) => ({
                                                        ...origin,
                                                        [calendar.id]: {
                                                            ...calendar,
                                                            noWorkingReals: toSlice(noWorkingTime[0].value) ?? [],
                                                            noWorkingHours: toMap(
                                                                toCalendar(toSlice(noWorkingTime[0].value), 'hours'),
                                                                dateToString
                                                            ),
                                                            noWorkingDays: toMap(
                                                                toCalendar(toSlice(noWorkingTime[0].value), 'days'),
                                                                dateToString
                                                            ),
                                                            noWorkingWeek: toMap(
                                                                toCalendar(toSlice(noWorkingTime[0].value), 'week'),
                                                                dateToString
                                                            ),
                                                            noWorkingMonth: toMap(
                                                                toCalendar(toSlice(noWorkingTime[0].value), 'month'),
                                                                dateToString
                                                            ),
                                                        },
                                                    }));
                                                    resolveFct('ok');
                                                })
                                                .catch((error) => {
                                                    requestError(
                                                        error,
                                                        t('planning_context.error_get_calendar_no_working_days', {
                                                            calendar: calendar.name,
                                                        })
                                                    );
                                                    resolveFct('ko');
                                                });
                                            // }
                                        });
                                        calendarPromises.push(promiseCalendar);
                                    });
                                    Promise.all(calendarPromises).then(() => {
                                        // setLoadingGantt(false);
                                        console.log('=> Got calendars');
                                        setSpinProgress(50);

                                        resolve(true);
                                    });
                                })
                                .catch((error) => requestError(error, t('planning_context.error_get_calendars')));
                        }
                    } else {
                        resolve(true);
                    }
                };
                ActivityService.showActivity(planningSelected.rootActivityId).then(async (activity) => {
                    getCalendars(activity);
                });
                // }
            });
        },
        [calendarCacheDates, planningPreferences, planningSelected]
    );

    const refreshPlanningCustomFields = (action, object) => {
        const oldCustomFields = [];
        flushSync(() => {
            setAllCustomFields((old) => {
                oldCustomFields.push(...JSON.parse(JSON.stringify(old)));
                return old;
            });
        });
        switch (action) {
            case 'new': {
                oldCustomFields.push(object);
                break;
            }
            case 'edit': {
                const indexFilter = oldCustomFields.findIndex((i) => i.id === object.id);
                if (indexFilter > -1) {
                    oldCustomFields[indexFilter] = object;
                }
                break;
            }
            case 'broadcast': {
                const indexFilter = oldCustomFields.findIndex((i) => i.id === object.id);
                if (indexFilter > -1) {
                    oldCustomFields[indexFilter] = object;
                } else {
                    oldCustomFields.push(object);
                }
                break;
            }
            case 'delete': {
                const indexFilter = oldCustomFields.findIndex((i) => i.id === object.id);
                if (indexFilter > -1) {
                    oldCustomFields.splice(indexFilter, 1);
                }
                break;
            }
            default:
        }
        // update user jobs
        if (object.name === JOB_FIELD) {
            loadUserJobs(planningSelectedRef.current);
        }
        setPlanningCustomFields(oldCustomFields.filter((customField) => customField.id > 0));
        setAllCustomFields([...oldCustomFields]);
    };

    useEffect(() => {
        if (userPreferences) {
            const openMode = userPreferences.open_mode;
            if (!openMode) {
                setModeSelected('live');
                // updateUserPreferences({open_mode: 'live'})
                return;
            }
            setModeSelected(openMode);
        }
    }, [userPreferences]);

    const [rootFolders, setRootFolders] = useState([]);
    const [subFoldersList, setSubFoldersList] = useState({});
    // using local variable for broacast function because it is not having current value of state
    const updateSubFoldersList = (folderId, data) =>
        setSubFoldersList((origin) => {
            const newObject = { ...origin, [folderId]: data };
            return newObject;
        });

    useEffect(() => {
        const onModeChange = async () => {
            if (userPreferences) {
                let folderList = [];
                if (rootFolders.length === 0) {
                    folderList = await FolderService.listFolders();
                    setRootFolders(folderList);
                } else {
                    folderList = rootFolders;
                }
                let openFolder;
                if (userPreferences?.open_folder) {
                    try {
                        openFolder = await FolderService.showFolder(userPreferences?.open_folder.id);
                    } catch (e) {
                        openFolder = null;
                    }
                }
                const openPlanning = userPreferences?.open_planning?.[modeSelected];
                console.log('🚀 ~ file: PlanningContext.jsx:766 ~ onModeChange ~ openPlanning:', openPlanning);

                setFolders(() => folderList.map((folder) => ({ key: folder.id, title: folder.name })));

                const firstFolder = openFolder || folderList?.[0] || null;

                if (firstFolder) {
                    if (openPlanning) {
                        setSpinProgress(20);
                        try {
                            const planningData = await FolderService.showPlanning(openPlanning.id);
                            if (canAccessToPlanning(planningData) && firstFolder.id === openPlanning.folderId) {
                                updatePlanning(planningData);
                            } else {
                                throw new Error(t('planning_context.no_access_planning'));
                            }
                        } catch (e) {
                            console.log('🚀 ~ file: PlanningContext.jsx:784 ~ onModeChange ~ e:', e);
                            requestPlanningsInFolderForMode(firstFolder.id, modeSelected)
                                .then((firstFolderPlannings) => {
                                    const planningWithAccess = firstFolderPlannings.filter((p) =>
                                        canAccessToPlanning(p)
                                    );
                                    if (planningWithAccess.length) {
                                        updatePlanning(planningWithAccess.first());
                                    } else {
                                        notificationError(t('planning_context.no_access_folder_planning'), '');
                                        updatePlanning(null);
                                    }
                                })
                                .catch((error) => {
                                    requestError(error, t('planning_context.error_get_plannings'));
                                });
                        }
                    } else {
                        updatePlanning(null);
                    }

                    FolderService.listSubFolders(firstFolder.id)
                        .then((subFolders) => {
                            updateSubFoldersList(firstFolder.id, subFolders);
                            if (firstFolder) {
                                setFolderSelected(firstFolder);
                            } else {
                                setFolderSelected({
                                    ...setExplorerItemSelected(
                                        clearExplorerSelected([firstFolder]),
                                        firstFolder.id,
                                        subFolders.childFolders
                                    ).first(),
                                    plannings: null,
                                });
                            }
                        })
                        .catch((error) => {
                            requestError(error, t('planning_context.error_get_subfolders'));
                        });
                }
            }
        };
        onModeChange();
    }, [modeSelected]);

    useEffect(() => {
        const onPlanningSelectedChange = async () => {
            console.log(
                '5🚀 ~ file: PlanningContext.jsx:789 ~ onPlanningSelectedChange ~ planningSelected',
                planningSelected,
                planningPreferences,
                planningPreferencesGot
            );
            if (planningSelected && timeUnits.length && planningPreferencesGot) {
                setLoadingGantt(true);
                const customFields = await updatePlanningCustomFields();
                setSpinProgress(30);
                await Promise.all([fetchActivities(customFields)]).then(async () => {
                    setSpinProgress(60);
                    const isFiltered = tabFilterData?.dateFilter;
                    console.log('🚀 ~ file: PlanningContext.jsx:849 ~ isFiltered:', isFiltered);
                    if (isFiltered) {
                        const fin = isFiltered.rules[0];
                        const debut = isFiltered.rules[1];
                        await fetchCalendars(+debut?.value, +fin?.value);
                    } else {
                        await fetchCalendars();
                    }
                    setLoadingGantt(false);
                });
            }
        };
        onPlanningSelectedChange();
    }, [timeUnits, planningPreferencesGot]);

    useEffect(() => {
        console.log('4. planning pref', planningPreferences);
        if (planningPreferences) {
            setPlanningPrefrencesGot(true);
        } else {
            setPlanningPrefrencesGot(false);
        }
    }, [planningPreferences]);

    const [disableReloading, setDisableReloading] = useState(true);

    const updateActivitiesDictionary = (activityId, updatedInfo) => {
        // flushSync(() =>
        setActivitiesDictionary((prevState) => {
            const durationUnit = timeUnits.find(
                (timeUnit) => timeUnit.id === Number((prevState[activityId] ?? updatedInfo).dayDefinitionId)
            );
            const taskDuration =
                (updatedInfo?.duration ?? prevState[activityId]?.duration) / (durationUnit?.duration || 1);

            const newValue = {
                ...prevState,
                [activityId]: {
                    ...(prevState[activityId] ?? {}),
                    ...updatedInfo,
                    taskDuration,
                },
            };
            return newValue;
        });
        // );
    };

    const removeActivityFromDictionary = (activityId) => {
        setActivitiesDictionary((prevState) => {
            const newValue = {
                ...prevState,
            };
            delete newValue[activityId];
            return newValue;
        });
    };

    const [listeningBroadcastFolder, setListeningBroadcastFolder] = useState(false);
    const [updatedFolderBroadcast, setUpdatedFolderBroadcast] = useState(null);
    const [updatedFolderBroadcastData, setUpdatedFolderBroadcastData] = useState(null);
    let folderList = [];
    // broadcast
    const handleFolderBroadcastMsg = useCallback(
        async (event) => {
            const data = JSON.parse(event.data);
            console.log('🚀 ~ file: PlanningContext.jsx:915 ~ handleFolderBroadcastMsg ~ data:', data);
            folderList = await FolderService.listFolders();
            setRootFolders(folderList);
            if (data.type === 'Folder') {
                let updatedFolder;
                try {
                    updatedFolder = await FolderService.showFolder(data.id);
                } catch (e) {
                    updatedFolder = null;
                }
                setUpdatedFolderBroadcast({ ...data, updatedData: updatedFolder });
            }
            if (data.type === 'Planning') {
                let updatedPlanning;
                try {
                    console.log(
                        '🚀 ~ file: PlanningContext.jsx:931 ~ handleFolderBroadcastMsg ~ planningSelected:',
                        planningSelected
                    );
                    console.log('send 1');
                    updatedPlanning = await FolderService.showPlanning(data.id);
                    const isFavorite = await userService.isFavoritePlanning(data.id);
                    updatedPlanning.isFavorite = isFavorite;
                    if (+planningSelected?.id === +data.id) {
                        // update planningSelected state
                        updatePlanning(updatedPlanning);
                    }
                    setUpdatedFolderBroadcast({ ...data, updatedData: updatedPlanning });
                } catch (e) {
                    updatedPlanning = null;
                    console.log('send2', e);
                    setUpdatedFolderBroadcast({ ...data, updatedData: updatedPlanning });
                }
            }
        },
        [planningSelected]
    );

    useEffect(() => {
        if (updatedFolderBroadcastData) {
            handleFolderBroadcastMsg(updatedFolderBroadcastData);
        }
    }, [updatedFolderBroadcastData]);

    let eventSourceFolder;
    useEffect(() => {
        (async () => {
            if (!listeningBroadcastFolder && window._env_.REACT_APP_FOLDER_API && canFetchData) {
                eventSourceFolder = new EventSource(`${window._env_.REACT_APP_FOLDER_API}/broadcast`);

                /// getting rootFolders
                if (rootFolders.length === 0) {
                    folderList = await FolderService.listFolders();
                    setRootFolders(folderList);
                }

                eventSourceFolder.onmessage = (e) => setUpdatedFolderBroadcastData(e);
                eventSourceFolder.onerror = (event) => {
                    if (event.target.readyState === EventSource.CLOSED) {
                        console.log('SSE folder closed ()');
                    }
                    eventSourceFolder.close();
                    // reconnect
                    setListeningBroadcastFolder(false);
                };

                eventSourceFolder.onopen = (event) => {
                    console.log('connection folder opened', event);
                };
                setListeningBroadcastFolder(true);
            }
        })();
    }, [listeningBroadcastFolder, canFetchData]);

    // useEffect for updating subfolders
    useEffect(() => {
        if (updatedFolderBroadcast && updatedFolderBroadcast.type === 'Folder') {
            const updatedFolder = updatedFolderBroadcast.updatedData;
            if (updatedFolder && subFoldersList[updatedFolder.parentId] && updatedFolder.parentId) {
                const cloneSubfolders = { ...subFoldersList };
                const indexSubfolder = subFoldersList[updatedFolder.parentId].childFolders.findIndex(
                    (i) => i.id === updatedFolder.id
                );
                if (indexSubfolder !== -1) {
                    // update
                    cloneSubfolders[updatedFolder.parentId].childFolders[indexSubfolder] = updatedFolder;
                    setSubFoldersList(cloneSubfolders);
                } else {
                    // new
                    cloneSubfolders[updatedFolder.parentId].childFolders.push(updatedFolder);
                    setSubFoldersList(cloneSubfolders);
                }
            }
            if (updatedFolder === null) {
                // delete
                const cloneSubfolders = { ...subFoldersList };
                const keysSubfolder = Object.keys(subFoldersList);
                keysSubfolder.forEach((item) => {
                    if (subFoldersList[item].childFolders) {
                        const indexSubFolder = subFoldersList[item].childFolders.findIndex(
                            (i) => i.id === +updatedFolderBroadcast.id
                        );
                        if (indexSubFolder !== -1) {
                            cloneSubfolders[item].childFolders.splice(indexSubFolder, 1);
                            setSubFoldersList(cloneSubfolders);
                        }
                    }
                });
            }
        }
    }, [updatedFolderBroadcast, canFetchData]);
    // broadcast activity
    const [listeningBroadcastActivity, setListeningBroadcastActivity] = useState(false);
    const [updatedDataActivityBroadcast, setUpdatedDataActivityBroadcast] = useState(null);
    const [updatedPlanningTreeBroadcast, setUpdatedPlanningTreeBroadcast] = useState(null);
    const [activityBroadcastData, setActivityBroadcastData] = useState(null);
    const planningSelectedRef = useRef(null);
    useEffect(() => {
        planningSelectedRef.current = planningSelected;
    }, [planningSelected]);

    let eventSourceActivity;

    const handleActivityBroadcastMsg = useCallback(
        async (event) => {
            const data = JSON.parse(event.data);
            console.log('event', data);
            // const currentUserInfo = authService.getCurrentUser();
            if (planningSelected && data.type === 'Activity' && window.ganttInstance) {
                // should update
                setTimeout(async () => {
                    const isTaskExist = window.ganttInstance.getTaskByServerId(+data.id) !== null;
                    console.log('🚀 ~ file: PlanningContext.jsx:1064 ~ setTimeout ~ isTaskExist:', isTaskExist);
                    const isProjectTaskExist = window.ganttInstance.getTaskByServerId(data.rootId) !== null;
                    if (isTaskExist || isProjectTaskExist) {
                        let updatedActivity;
                        try {
                            const planningColor = planningPreferences?.planning_color;
                            if (planningColor) {
                                updatedActivity = await ActivityService.showActivityWithColors(
                                    data.id,
                                    planningColor.id
                                );
                            } else {
                                updatedActivity = await ActivityService.showActivity(data.id);
                            }
                        } catch (e) {
                            updatedActivity = null;
                        }
                        let diffFields = [];
                        if (updatedActivity) {
                            // const affectedResources = [];
                            const oldActivityInfo = clone(activityDictionnaryRef.current[data.id]);
                            // delete oldActivityInfo?.resourceNeeds;
                            delete oldActivityInfo?.taskDuration;
                            console.log(
                                '🚀 ~ file: PlanningContext.jsx:1018 ~ setTimeout ~ oldActivityInfo',
                                oldActivityInfo
                            );
                            updatedActivity = clone({
                                ...updatedActivity,
                                baseLineStartDate: baselineDictionary[updatedActivity.identity]?.startDate || null,
                                baseLineEndDate: baselineDictionary[updatedActivity.identity]?.endDate || null,
                                baseLineProgress: baselineDictionary[updatedActivity.identity]?.avancement || 0,
                                // affectedResources,
                            });

                            console.log(
                                '🚀 ~ file: PlanningContext.jsx:1020 ~ setTimeout ~ updatedActivity',
                                updatedActivity
                            );
                            // diff old values accoording to new values
                            const diff = detailedDiff(oldActivityInfo, updatedActivity);
                            console.log('🚀 ~ file: PlanningContext.jsx:1027 ~ setTimeout ~ diff', diff);
                            diffFields = [
                                ...new Set([
                                    ...Object.keys(diff.added),
                                    ...Object.keys(diff.updated),
                                    ...Object.keys(diff.deleted),
                                ]),
                            ];
                            console.log('🚀 ~ file: PlanningContext.jsx:1028 ~ setTimeout ~ diffFields', diffFields);
                        }
                        if (diffFields.length > 0) {
                            flushSync(() => {
                                setUpdatedDataActivityBroadcast({
                                    id: +data.id,
                                    isTaskExist,
                                    context: data.context,
                                    isNewTask: activityDictionnaryRef.current[data.id] === undefined,
                                    updatedData: updatedActivity,
                                    diffFields,
                                });
                            });
                        }
                    }
                }, 200);
            }
            // if (data.type === 'Assignment') {
            //     // eslint-disable-next-line

            // }
            if (data.type === 'Activity') {
                setUpdatedPlanningTreeBroadcast({
                    ...data,
                });
            }
            if (planningSelected && data.type === 'UnifiedField' && currentUser.userId !== data.user) {
                let customField;
                try {
                    customField = await CustomFieldService.getCustomFieldById(data.id);
                } catch (error) {
                    customField = null;
                }
                if (customField) {
                    refreshPlanningCustomFields('broadcast', customField);
                } else {
                    refreshPlanningCustomFields('delete', { id: Number(data.id) });
                }
            }
        },
        [planningSelected, activitiesDictionary, baselineDictionary, planningPreferences]
    );

    useEffect(() => {
        if (activityBroadcastData) {
            handleActivityBroadcastMsg(activityBroadcastData);
        }
    }, [activityBroadcastData]);

    useEffect(() => {
        if (!listeningBroadcastActivity && window._env_.REACT_APP_ACTIVITY_API && canFetchData) {
            eventSourceActivity = new EventSource(`${window._env_.REACT_APP_ACTIVITY_API}/broadcast`);

            eventSourceActivity.onmessage = (e) => {
                flushSync(() => {
                    setActivityBroadcastData(e);
                });
            };

            eventSourceActivity.onerror = (event) => {
                if (event.target.readyState === EventSource.CLOSED) {
                    console.log('SSE folder closed ()');
                }
                eventSourceActivity.close();
                // reconnect
                setListeningBroadcastActivity(false);
            };

            // eventSourceActivity.onopen = (event) => {
            //     console.log('connection activity opened', event);
            // };
            setListeningBroadcastActivity(true);
        }
    }, [listeningBroadcastActivity, canFetchData]);

    // simulate broadcast
    const updateActivityByBroadcast = useCallback(
        (id) =>
            flushSync(() => {
                setActivityBroadcastData({
                    data: JSON.stringify({
                        context: 'ACTIVITY',
                        id,
                        rootId: planningSelected?.rootActivityId,
                        type: 'Activity',
                        origin: 'FRONT',
                    }),
                });
            }),
        [planningSelected]
    );

    useEffect(() => {
        if (updatedDataActivityBroadcast && planningSelected) {
            console.log(
                '🚀 ~ file: PlanningContext.jsx:1014 ~ useEffect ~ updatedDataActivityBroadcast',
                updatedDataActivityBroadcast
            );
            const { updatedData } = updatedDataActivityBroadcast;

            if (updatedData) {
                const isGroupement = planningPreferences?.filtered_group?.groupingType === 'custom_fields';
                // update
                if (updatedDataActivityBroadcast.isTaskExist) {
                    console.log('update');
                    const oldData = window.ganttInstance.getTaskByServerId(updatedDataActivityBroadcast.id);
                    updateActivitiesDictionary(updatedData.id, clone(updatedData));
                    // do not update parent in Gantt if activities grouped
                    const parentServerId = updatedData.activityParentId;
                    if (isGroupement) {
                        updatedData.activityParentId = oldData.parent;
                    } else {
                        updatedData.activityParentId = window.ganttInstance.getTaskByServerId(
                            updatedData.activityParentId
                        )?.id;
                    }
                    // only update valid property for project activity
                    // if (updatedData.id === planningSelected.rootActivityId) {
                    //     const toUpdate = window.ganttInstance.getTask(updatedData.id);
                    //     toUpdate.status = updatedData.activityState;
                    //     window.ganttInstance.updateTask(updatedData.id);
                    // } else {
                    const durationUnit = timeUnits.find(
                        (timeUnit) => timeUnit.id === Number(updatedData.dayDefinitionId)
                    );
                    const taskDuration = updatedData.duration / (durationUnit?.duration || 1);
                    const task = formatGanttTask(updatedData, {
                        durationApi: taskDuration,
                        roundedDuration: taskDuration ? Number(taskDuration).toFixed(1) : 0,
                        realChildren: updatedData.subActivitiesId,
                        parentId: parentServerId,
                    });
                    if (isGroupement) {
                        task.type = oldData.type;
                    }
                    window.ganttInstance.updateTaskByServerId(updatedData.id, task);
                }
                if (updatedDataActivityBroadcast.isNewTask) {
                    // add
                    updateActivitiesDictionary(updatedData.id, updatedData);
                    if (!isGroupement) {
                        const durationUnit = timeUnits.find(
                            (timeUnit) => timeUnit.id === Number(updatedData.dayDefinitionId)
                        );
                        const taskDuration = updatedData.duration / (durationUnit?.duration || 1);
                        const parentFakeId = window.ganttInstance.getTaskByServerId(updatedData.activityParentId)?.id;
                        console.log('add');
                        window.ganttInstance.addTask(
                            formatGanttTask(updatedData, {
                                durationApi: taskDuration,
                                realChildren: [],
                                parent: parentFakeId,
                                parentId: updatedData.activityParentId,
                            })
                        );
                    }
                }
                // colors
                setTasksStripesStyles(
                    window.ganttInstance
                        .getTaskByTime()
                        .filter((flattenActivity) => taskStripes.hasStripes(flattenActivity))
                        .map((flattenActivity) => taskStripes.formatForState(flattenActivity))
                );
            } else {
                // delete
                /* eslint no-lonely-if: "off" */
                if (updatedDataActivityBroadcast.isTaskExist) {
                    window.ganttInstance.deleteTaskByServerId(updatedDataActivityBroadcast.id);
                }
            }
        }
    }, [updatedDataActivityBroadcast]);

    return (
        <PlanningContext.Provider
            value={{
                folders,
                setFolders,
                folderSelected,
                setFolderSelected,
                planningSelected,
                updatePlanning,
                refreshPlanningSelected,
                planningCustomFields,
                allCustomFields,
                updatePlanningCustomFields,
                modeSelected,
                setModeSelected,
                requestPlanningsInFolderForMode,
                loadingGantt,
                setLoadingGantt,
                zoomLevel,
                setZoomLevel,
                activitiesDictionary,
                updateActivitiesDictionary,
                calendarsDictionary,
                tasksStripesStyles,
                setTasksStripesStyles,
                processingGantt,
                setProcessingGantt,
                rootFolders,
                subFoldersList,
                updateSubFoldersList,
                updatedFolderBroadcast,
                updatedDataActivityBroadcast,
                refreshGantt,
                setRefreshGantt,
                disableReloading,
                setDisableReloading,
                timeUnits,
                fetchCalendars,
                reRenderGantt,
                setReRenderGantt,
                setUpdatedFolderBroadcast,
                updatedPlanningTreeBroadcast,
                canEditActivity,
                userJobsWithoutMulti,
                isUserJob,
                isMultiJobUser,
                refreshPlanningCustomFields,
                spinProgress,
                setSpinProgress,
                canAccess,
                canAccessToPlanning,
                removeActivityFromDictionary,
                jobs,
                setPlanningPreferences,
                planningPreferences,
                ganttHistogramChartNeedList,
                updateActivityByBroadcast,
                setGanttHistogramChartNeedList,
                setTabFilterData,
                tabFilterData,
                filteredFlattenActivities,
                generateFilteredFlattenActivities,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};
PlanningContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PlanningContext, PlanningContextProvider };
