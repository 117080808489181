import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Empty, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { CalendarsTabContext, CalendarsTabContextProvider } from '../../../contexts/calendar/CalendarsTabContext';
import CalendarAddExclusionForm from './CalendarAddExclusionForm';
import LoadingSpin from '../../utils/LoadingSpin';
import { requestError } from '../../../helpers/notification';
import AuthService from '../../../services/auth.service';

const ListRow = styled.li`
    padding: 0;
    margin-bottom: 2px;
`;

const ListHeaders = styled.li`
    padding: 0 0 0 8px;
    margin-bottom: 2px;
`;

const ListFooters = styled.li`
    margin-top: 2px;
`;

const ListDetails = styled.span`
    display: inline-block;
    padding: 8px;
    font-size: 12px;
    width: 90%;
`;

const ListActions = styled.span`
    display: inline-block;
    padding: 0 3px;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    display: inline-block;
    width: 60%;
`;

const StartColumn = styled.span`
    display: inline-block;
    width: 40%;
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const StartColumnHeader = styled(StartColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const CalendarExclusionsList = () => {
    const { t } = useTranslation();
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { selectedCalendar, setSelectedCalendar } = useContext(CalendarsTabContext);
    const { setModalContent, refreshCalendars } = useContext(CalendarMenuContext);
    const [calendarExclusions, setCalendarExclusions] = useState([]);
    const { isAdmin } = AuthService.getCurrentUser();

    const addExclusion = () => {
        if (!isAdmin) {
            return;
        }
        setModalContent({
            key: 'calendar-add-exclusion',
            title: t('calendars_calendars.calendar_add_exception_calendar'),
            body: <CalendarsTabContextProvider><CalendarAddExclusionForm calendar={selectedCalendar} /></CalendarsTabContextProvider>,
        });
    };

    useEffect(() => {
        setCalendarExclusions(selectedCalendar ? selectedCalendar.specificPeriods ?? [] : []);
    }, [selectedCalendar]);

    const removeExclusion = (exclusion) => {
        if (!isAdmin) {
            return;
        }

        setLoading(true);
        CalendarService.removeExclusion(selectedCalendar, exclusion)
            .then((updatedCalendar) => {
                setLoading(false);
                setSelectedCalendar(updatedCalendar);
                refreshCalendars();
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendars_period.day_delete_error'));
            });
    };

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <ul className="pb-8">
            <ListHeaders>
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
                <StartColumnHeader>{generalTranslation('range')}</StartColumnHeader>
            </ListHeaders>
            {calendarExclusions.length ? (
                calendarExclusions.map((exclusion) => (
                    <ListRow key={`calendar-${selectedCalendar.id}-exclusion-${exclusion.id}`}>
                        <div className="flex items-center">
                            <ListDetails className="bg-white">
                                <div className="flex items-center">
                                    <NameColumn>{exclusion.name}</NameColumn>
                                    <StartColumn>
                                        {exclusion.startDate.format('DD/MM/YYYY')}
                                        <br />à {exclusion.endDate.format('DD/MM/YYYY')}
                                    </StartColumn>
                                </div>
                            </ListDetails>
                            {isAdmin && (
                                <ListActions>
                                    <Popconfirm
                                        title={t('calendars_calendars.calendar_exception_delete_warning')}
                                        okText={generalTranslation('yes')}
                                        cancelText={generalTranslation('no')}
                                        onConfirm={() => removeExclusion(exclusion)}
                                    >
                                        <CloseSvg
                                            width="16"
                                            height="16"
                                            style={{ position: 'relative', top: 3 }}
                                            className="cursor-pointer"
                                        />
                                    </Popconfirm>
                                </ListActions>
                            )}
                        </div>
                    </ListRow>
                ))
            ) : (
                <Empty description={t('calendars_calendars.no_exception_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    {selectedCalendar && (
                        <div className="bg-secondary-light px-2 pb-2">
                            <Button
                                type="secondary"
                                className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                                onClick={addExclusion}
                            >
                                <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                            </Button>
                        </div>
                    )}
                </ListFooters>
            )}
        </ul>
    );
};

export default CalendarExclusionsList;
