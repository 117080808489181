import React, { createContext, useEffect, useState, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import resourceJSON from '../../mocks/resources/resources.json';
import NeedService from '../../services/need.service';
import { PlanningContext } from '../app/PlanningContext';
import { findParentObject } from '../../helpers/tree-helper';
import activityService from '../../services/activity.service';
// import { requestError } from '../../helpers/notification';

const PlanningNeedsContext = createContext({
    selectedNeed: null,
    NeedList: null,
    setSelectedNeed: () => {},
    setNeedList: () => {},
    refreshNeedList: () => {},
});

const PlanningNeedsContextProvider = ({ children }) => {
    // const {t} = useTranslation('translation', {keyPrefix: 'resource_context'})
    const [selectedNeed, setSelectedNeed] = useState(null);

    const [NeedList, setNeedList] = useState(null);
    const {planningSelected} = useContext(PlanningContext)


    const formatNeeds = (list, parent) =>
        list.map((el) => {
            const formattedValue = { ...el, key: el.id.toString(), parent, open: true };
            if (el.isLevel) {
                formattedValue.children = formatNeeds(el.children, el.id);
            }
            return formattedValue;
        });


    useEffect(() => {
        const fetchData = async () => {
            if (planningSelected) {
                const rootActivityConfig = await activityService.getRootActivityConfig(planningSelected.rootActivityId);
                if (rootActivityConfig.defaultLevelId) {
                    const list = await NeedService.getNeedsTreeByParent(rootActivityConfig.defaultLevelId);
                    // console.log("🚀 ~ file: PlanningNeedsContext.jsx:68 ~ fetchData P ~ list", list)
                    setNeedList(formatNeeds([list], null));
                }
            }
        };
        fetchData();
    }, [planningSelected]);

    
    const refreshNeedList = (action, object) => {
        let oldNeeds = [];
        setNeedList((old) => {
            oldNeeds = old;
            return old;
        });
        // should always have parent object
        const parentObject = findParentObject(object.parent, oldNeeds);
        console.log("🚀 ~ file: PlanningNeedsContext.jsx:60 ~ refreshNeedList ~ parentObject:", parentObject)
        if (parentObject) {
            switch (action) {
                case 'new': {
                    parentObject.children.push(object);
                    break;
                }
                case 'edit': {
                    const oldParentObject = findParentObject(selectedNeed.parent, oldNeeds);
                    console.log("🚀 ~ file: PlanningNeedsContext.jsx:69 ~ refreshNeedList ~ oldParentObject:", oldParentObject)
                    if (oldParentObject.id !== parentObject.id) {
                        const oldChildIndex = oldParentObject.children.findIndex((i) => i.id === object.id);
                        oldParentObject.children.splice(oldChildIndex, 1);
                        parentObject.children.push(object);
                    } else {
                        const childIndex = parentObject.children.findIndex((i) => i.id === object.id);
                        parentObject.children[childIndex] = object;
                    }
                    break;
                }
                case 'delete': {
                    const childIndex = parentObject.children.findIndex((i) => i.id === object.id);
                    parentObject.children.splice(childIndex, 1);
                    break;
                }
                default:
            }
        }
        if (object.isDefault)
        {
            oldNeeds = [object];
        }
        setNeedList(JSON.parse(JSON.stringify(formatNeeds(oldNeeds, null))));
    };

    return (
        <PlanningNeedsContext.Provider
            value={{
                selectedNeed,
                setSelectedNeed,
                NeedList,
                refreshNeedList,
            }}
        >
            {children}
        </PlanningNeedsContext.Provider>
    );
};

PlanningNeedsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PlanningNeedsContext, PlanningNeedsContextProvider };
