/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react';
import '@dhx/scheduler-enterprise';
// import '@dhx/scheduler-enterprise/codebase/ext/dhtmlxscheduler_timeline';
// import '@dhx/scheduler-enterprise/codebase/ext/dhtmlxscheduler_treetimeline';
import '@dhx/scheduler-enterprise/codebase/locale/locale_fr';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Collapse, Checkbox, Card, Tree } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import moment from 'moment';
import ActivityService from '../../services/activity.service';
import CustomFieldService from '../../services/custom-field.service';
import { UserContext } from '../../contexts/app/UserContext';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { ResourceContext } from '../../contexts/resource/ResourceContext';
import DrawerActivity from '../plannings/DrawerActivity';
import { notificationError, requestError } from '../../helpers/notification';
const { Panel } = Collapse;
import {
    flattenGanttNodes,
    formatGanttTask,
    generateGroupedGanttNodes,
    generateLinkNodes,
    generateFilteredGanttNodes,
    dateToTimestamp,
    dateToJsDate,
    ACTIVITY_STATE,
} from '../../helpers/planning';
import {
    deleteForever,
    ganttSvg,
    warningBase64,
    lockBase64,
    circleBase64,
    checkCircleBase64,
} from '../../helpers/icons';
import TaskStripes from '../../helpers/task-stripes';
import GanttConfigurations from '../plannings/gantt_config';
import { union, difference } from 'lodash';
import { extractPropertyInTreeChildren } from '../../helpers/array-filter';
import { findObjectInTree } from '../../helpers/tree-helper';

// import LoadingSpin from '../utils/LoadingSpin';
const { scaleConfigs, zoomLevels, GANTT_MIN_COLUMN_WIDTH, DEFAULT_COMUMNS } = GanttConfigurations;

// let zoomScrollLock = false;

const LoadingScreen = styled.div`
    height: 100%;

    .ant-spin-nested-loading {
        height: 100%;
    }

    .ant-spin-container {
        height: 100%;
    }
`;

const SchedulerWrapper = styled.div`
    height: 100%;
    width: 75% !important;
    .dhx_matrix_cell div.load-marker {
        position: absolute;
        width: 40%;
        height: 25px;
        transform: translate(70%, 20%);
        line-height: 25px;
        text-align: center;
        border-radius: 7px;
        color: white;
    }

    .load-marker-no {
        background: #e0e0e0;
    }
    .load-marker-light {
        background: #aed581;
    }
    .load-marker-high {
        background: #ff8a65;
    }
`;

const ResourcesCalendar = () => {
    let schedulerContainer = useRef(null);
    const { resourceList } = useContext(ResourceContext);

    const [selectedResources, setSelectedResources] = useState([]);
    const [allResources, setAllResources] = useState([]);
    const [selectedNode, setSelectedNode] = useState();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const {
        planningSelected,
        refreshPlanningSelected,
        planningCustomFields,
        loadingGantt,
        modeSelected,
        activitiesDictionary,
        calendarsDictionary,
        tasksStripesStyles,
        setTasksStripesStyles,
        processingGantt,
        setProcessingGantt,
        updateActivitiesDictionary,
        refreshGantt,
        setDisableReloading,
        timeUnits,
        reRenderGantt,
        fetchCalendars,
        setReRenderGantt,
        planningFilter,
        userJobsWithoutMulti,
        isUserJob,
        isMultiJobUser,
    } = useContext(PlanningContext);
    const { userPreferences, updateUserPreferences } = useContext(UserContext);
    const cloneActivityDictionary = useRef(activitiesDictionary);
    // loading dhtmlx api for import and export
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/dhtmlx_api.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const formatResourceList = (list) =>
        list.reduce((old, current) => {
            let newValue = old;
            newValue.push({
                title: current.label,
                key: current.id,
                color: current.color,
                checkable: current.isLevel !== true,
                isLevel: current.isLevel,
                children: current.children?.length ? formatResourceList(current.children) : null,
            });
            return newValue;
        }, []);

    useEffect(() => {
        if (resourceList) {
            const formattedResourceList = formatResourceList(resourceList);
            const defaultSelected = extractPropertyInTreeChildren(formattedResourceList, 'key', 'children');
            setExpandedKeys(defaultSelected);
            setSelectedResources(defaultSelected);
            setAllResources(formattedResourceList);
            setSelectedNode(formattedResourceList[0]);
        }
    }, [resourceList]);

    const schedulerConfigs = () => {
        // window.schedulerInstance.locale.labels.timeline_tab = 'Timeline';
        window.schedulerInstance.locale.labels.section_custom = 'Section';
        window.schedulerInstance.config.details_on_create = true;
        window.schedulerInstance.config.details_on_dblclick = true;

        window.schedulerInstance.config.lightbox.sections = [
            { name: 'description', height: 50, map_to: 'text', type: 'textarea', focus: true },
            { name: 'custom', height: 30, type: 'timeline', options: null, map_to: 'section_id' }, //type should be the same as name of the tab
            { name: 'time', height: 72, type: 'time', map_to: 'auto' },
        ];
    };

    const schedulerTemplates = () => {
        window.schedulerInstance.templates.timeline_cell_value = (evs, date, section) => {
            if (section.children) {
                const timeline = window.schedulerInstance.getView();

                const events = timeline.selectEvents({
                    section: section.key,
                    date: date,
                    selectNested: true,
                });

                let className = '';
                if (!events.length) {
                    className = 'load-marker-no';
                } else if (events.length < 3) {
                    className = 'load-marker-light';
                } else {
                    className = 'load-marker-high';
                }

                return "<div className='load-marker " + className + "'>" + events.length + '</div>';
            }

            return '';
        };
    };

    const loadScheduler = async (dictionary, filter = true) => {
        if (planningSelected && dictionary[planningSelected.rootActivityId]) {
            setProcessingGantt(true);
            window.schedulerInstance.render();
            if (filter) {
                const filteredGroup = userPreferences?.filtered_group?.[planningSelected.rootActivityId];
                const ganttParameters = userPreferences?.gantt_parameters;
                if (filteredGroup?.groupingType === 'custom_fields') {
                    await generateGroupedGanttNodes(
                        dictionary,
                        planningSelected.summaryId ?? planningSelected.rootActivityId,
                        userPreferences?.planning_color,
                        filteredGroup.simpleActivityOnly,
                        filteredGroup.sortOptions
                    )
                        .then(async (activities) => {
                            let flattenActivities = flattenGanttNodes(activities);
                            // show links if only simple activities
                            const links = await generateLinkNodes(planningSelected.rootActivityId);
                            setTasksStripesStyles(
                                flattenActivities
                                    .filter((flattenActivity) => TaskStripes.hasStripes(flattenActivity))
                                    .map((flattenActivity) => TaskStripes.formatForState(flattenActivity))
                            );
                            // global parameter for split task

                            const splitTasks = userPreferences.gantt_split_task?.[planningSelected.id] || [];

                            if (ganttParameters?.splitTask) {
                                window.schedulerInstance.config.open_split_tasks = true;
                                flattenActivities = flattenActivities.map((i) => ({
                                    ...i,
                                    render: splitTasks.indexOf(Number(i.id)) !== -1 ? 'split' : '',
                                }));
                            }

                            // window.schedulerInstance.parse({ data: []    });
                            window.schedulerInstance.clearAll();
                            const randomIntFromInterval = (min, max) => {
                                // min and max included
                                return Math.floor(Math.random() * (max - min + 1) + min);
                            };

                            const tasksWithResource = flattenActivities.map((i) => ({
                                ...i,
                                owner: [
                                    {
                                        resource_id: randomIntFromInterval(0, 11).toString(),
                                        value: randomIntFromInterval(0, 10).toString(),
                                    },
                                ],
                            }));
                            console.log(
                                '🚀 ~ file: GanttResources.jsx ~ line 324 ~ .then ~ tasksWithResource',
                                tasksWithResource
                            );

                            window.schedulerInstance.parse({
                                data: [...tasksWithResource],
                                links: JSON.parse(JSON.stringify(links)),
                            });
                        })
                        .catch((error) => requestError(error, 'Erreur lors de la création du filtre groupé'));
                } else {
                    await generateFilteredGanttNodes(
                        dictionary,
                        planningSelected.summaryId ?? planningSelected.rootActivityId,
                        userPreferences?.planning_color
                    ).then(async (activities) => {
                        let flattenActivities = [...flattenGanttNodes(activities)];

                        // global parameter for split task

                        // const randomIntFromInterval = (min, max) => {
                        //     // min and max included
                        //     return Math.floor(Math.random() * (max - min + 1) + min);
                        // };
                        window.schedulerInstance.clearAll();
                        let tasksWithResource = flattenActivities.reduce((prev, current) => {
                            const newValue = prev;
                            const resources = (current.data_api.affectedResources || []).reduce((oldValue, data) => {
                                const newValue = [...oldValue];
                                if (selectedResources[data.supplyId]) {
                                    newValue.push({
                                        ...data,
                                        start_date: dateToJsDate(data.startDate, null, false),
                                        end_date: dateToJsDate(data.endDate, null, false),
                                        section_id: data.supplyId,
                                        text: current.text,
                                        color: findObjectInTree(data.supplyId).color,
                                    });
                                }
                                return newValue;
                            }, []);
                            newValue.push(...resources);
                            return newValue;
                        }, []);
                        console.log(
                            '🚀 ~ file: GanttResources.jsx ~ line 366 ~ .then ~ tasksWithResource',
                            tasksWithResource
                        );
                        window.schedulerInstance.setCurrentView(
                            tasksWithResource[0] ? tasksWithResource[0].start_date : new Date(),
                            'week'
                        );

                        window.schedulerInstance.parse([...tasksWithResource]);
                    });
                }

                window.schedulerInstance.render();
                setProcessingGantt(false);
            }
        }
    };

    useEffect(() => {
        const initScheduler = async () => {
            if (schedulerContainer && loadingGantt === false && planningSelected) {
                setProcessingGantt(true);

                window.schedulerInstance = window.Scheduler?.getSchedulerInstance();

                schedulerContainer.style.height = '90vh';
                // POC
                schedulerConfigs();
                schedulerTemplates();
                window.schedulerInstance.init('gantt-wrapper', new Date(), 'week');
                await loadScheduler(activitiesDictionary);
                setProcessingGantt(false);
            }
        };
        initScheduler();
    }, [loadingGantt, resourceList]);

    const checkSelectAll = (resourcesCheckArray) =>
        resourcesCheckArray.reduce((old, current) => {
            const allSelected = old && current;
            return allSelected;
        }, resourcesCheckArray[0]);

    const handleChangeSelectedResource = (id) => {
        setSelectedResources((old) => {
            const newValue = { ...old };
            newValue[id] = !newValue[id];
            const isAllSelection = checkSelectAll(Object.values(newValue));
            setAllResourcesSelected(isAllSelection);
            return newValue;
        });
    };

    const toogleSelectAll = (value) => {
        setSelectedResources((old) => {
            const newValue = { ...old };
            Object.keys(newValue).forEach((id) => {
                newValue[id] = value;
            });
            setAllResourcesSelected(value);
            return newValue;
        });
    };

    useEffect(() => {
        cloneActivityDictionary.current = { ...activitiesDictionary };
    }, [activitiesDictionary]);

    useEffect(() => {
        loadScheduler(cloneActivityDictionary.current);
    }, [selectedResources]);

    const handleCheckedResources = (keys) => {
        setSelectedResources(keys.checked);
    };

    const handleCheckChildren = (nodeData) => {
        const childIds = extractPropertyInTreeChildren(nodeData.children, 'key', 'children');
        setSelectedResources(union(selectedResources, childIds));
    };

    const handleUnCheckChildren = (nodeData) => {
        const childIds = extractPropertyInTreeChildren(nodeData.children, 'key', 'children');
        setSelectedResources(difference(selectedResources, childIds));
    };

    const expandSelectedNode = () => {
        if (selectedNode.children?.length) {
            const childIds = extractPropertyInTreeChildren(selectedNode.children, 'key', 'children');
            setExpandedKeys(union(expandedKeys, childIds));
        }
    };
    const collapseSelectedNode = () => {
        if (selectedNode.children?.length) {
            const childIds = extractPropertyInTreeChildren(selectedNode.children, 'key', 'children');
            setExpandedKeys(difference(expandedKeys, childIds));
        }
    };

    return (
        <>
            <div
                className="flex justify-between"
                style={{
                    fontFamily: userPreferences?.gantt_parameters?.fontFamily ?? 'inherit',
                }}
            >
                <SchedulerWrapper
                    id="gantt-wrapper"
                    ref={(element) => {
                        schedulerContainer = element;
                    }}
                    className={`h-full ${loadingGantt ? 'hidden' : ''} dhx_cal_container`}
                >
                    <div className="dhx_cal_navline">
                        <div className="dhx_cal_prev_button">&nbsp;</div>
                        <div className="dhx_cal_next_button">&nbsp;</div>
                        <div className="dhx_cal_today_button"></div>
                        <div className="dhx_cal_date"></div>
                        <div className="dhx_cal_tab" name="day_tab"></div>
                        <div className="dhx_cal_tab" name="week_tab"></div>
                        <div className="dhx_cal_tab" name="month_tab"></div>
                        {/* <div className="dhx_cal_tab" name="timeline_tab"></div> */}
                    </div>
                    <div className="dhx_cal_header"></div>
                    <div className="dhx_cal_data"></div>
                </SchedulerWrapper>
                <Card
                    title={<label>Ressources</label>}
                    size="small"
                    extra={
                        <div className="flex">
                            <Button
                                type="secondary"
                                className="flex items-center mr-2"
                                size="small"
                                icon={<ArrowUpOutlined />}
                                onClick={() => collapseSelectedNode()}
                            >
                                Tout replier
                            </Button>
                            <Button
                                type="secondary"
                                className="flex items-center"
                                size="small"
                                icon={<ArrowDownOutlined />}
                                onClick={() => expandSelectedNode()}
                            >
                                Tout déplier
                            </Button>
                        </div>
                    }
                    style={{ width: '23%' }}
                >
                    {allResources.length > 0 && (
                        <Tree
                            checkable
                            checkStrictly
                            selectable
                            treeData={allResources}
                            defaultExpandAll
                            showLine
                            selectedKeys={selectedNode ? [selectedNode.key] : []}
                            expandedKeys={expandedKeys}
                            checkedKeys={selectedResources}
                            onSelect={(a, e) => setSelectedNode(e.node)}
                            onExpand={(a, e) => setExpandedKeys(a)}
                            onCheck={handleCheckedResources}
                            titleRender={(node) =>
                                node.children?.length ? (
                                    <div className="flex items-center" style={{ minHeight: '24px' }}>
                                        <label className="mr-3">{node.title}</label>
                                        <div className="tree-actions">
                                            <Button
                                                title="Cocher les enfants"
                                                type="primary"
                                                onClick={() => handleCheckChildren(node)}
                                                size="small"
                                                icon={<CheckSquareOutlined />}
                                            />
                                            <Button
                                                title="Décocher les enfants"
                                                type="primary"
                                                onClick={() => handleUnCheckChildren(node)}
                                                size="small"
                                                icon={<CloseSquareOutlined />}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {node.isLevel ? (
                                            <label>{node.title}</label>
                                        ) : (
                                            <div className="flex items-center">
                                                <span class="mr-3">{node.title}</span>
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: node.color,
                                                    }}
                                                ></div>
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        />
                    )}
                </Card>
            </div>
        </>
    );
};
export default ResourcesCalendar;
