import {useTranslation} from "react-i18next";
import React, {createContext, useContext, useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import AuthService from "../../services/auth.service";
import {notificationError, requestError} from "../../helpers/notification";
import {WalletContext} from "./WalletContext";
import {UserContext} from "../app/UserContext";


const VersionContext = createContext({});

const VersionContextProvider = ({ children }) => {

    const { programList } = useContext(WalletContext);
    const { currentUser } = useContext(UserContext);
    const [dataProgramServices, setDataProgramServices] = useState({});
    const { t } = useTranslation();


/// /////////////////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////                                    /////////////////////////////////
/// ////////////////////////             Finder                 /////////////////////////////////
/// ////////////////////////                                    /////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////

    /** Recherche si le front est sur la version de production ou de developpement. */
    const prod = (program) => program.url.endsWith('ganttmanager.com')

    /** Recherche si un tag par defaut est indique dans la description du program */
    const findDefaultProgramTag = (program) => {
        const {description} = program
        if(description){
            const searchVersion = /version="([^"]*)"/;
            const defaultVersion = description.match(searchVersion);
            return defaultVersion ? defaultVersion[1] : "master";
        }
        return "master";
    }


    /** Retourne l'ensemble des tags existants pour un program et un micro-service. */
    const findTags = async (program, serviceName) => {
        try {
            const response = await AuthService.lastTagsPage(prod(program), serviceName);
            return response?.data?.tags;
        } catch (e) {
            return [];
        }
    };

    /** Retourne la derniere version disponible sur le registry pour un micro-service */
    const findLastVersion = async (program, serviceName, branch) => {
        try {
            const response = await AuthService.lastVersionRegistryPage(prod(program), serviceName, branch);
            const history = response?.data?.history[0]?.v1Compatibility;
            // La date de build du docker
            const searchCreatedDate = /created":"([^"]*)","/;
            // La variable d'environnement envVersion, plus fiable que la date de build docker
            // qui est differe de la date de build gradle et donc de la version courante
            const searchEnvVersion = /envVersion=([^"]*)"/;
            const createdDate = history.match(searchCreatedDate)[1];
            const envVersion = history.match(searchEnvVersion);
            if (envVersion)
                return envVersion[1];
            if (createdDate) {
                const timestamp = Number.parseInt(Date.parse(createdDate)/1000, 10);
                const minorVersion = prod ? timestamp : `${timestamp}-SNAPSHOT`;
                const majorVersion = "x.y.z";
                return `${branch}-${majorVersion}${minorVersion}`;
            }
            return "undefined";
        } catch (error) {
            return "undefined"
        }
    }

    /** Retourne la version en cours d'un micro-service */
    const findVersion = async (program, serviceName) => AuthService.version(`https://${serviceName.toLowerCase()}.${program.url}`)

    /** Retourne la version en cours du wallet */
    const findWalletVersion = async (program) => {
        const site = prod(program) ? 'ganttmanager.com' : 'ganttmanager.fr';
        return AuthService.version(`https://wallet.${site}`)
    }

    const findFrontVersion = async () => window._env_.VERSION;


/// /////////////////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////                                    /////////////////////////////////
/// ////////////////////////             Deployement            /////////////////////////////////
/// ////////////////////////                                    /////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////

    /** Envoi une demande de deploiement sur Jenkins pour le micro service concerné */
    const doDeployement = (program, record) => {
        const suffixDevProd = prod(program) ? 'prod' : 'dev';
        const domain = program.url.split('.')[0].toLowerCase();
        const domainArea = `domainArea: ${program.domainArea}`;
        const {tag} = record;
        const service = `gantt-manager-${record.label.toLowerCase()}`;
        AuthService.deployement(suffixDevProd, domain, domainArea, tag, service)
            .catch(() => notificationError(t('general.error'), t('error.unknown_error')));
    };

    /** Envoi une demande de deploiement sur Jenkins pour le micro service wallet */
    const doWalletDeployement = (program, record) => {
        const suffixDevProd = prod(program) ? 'prod' : 'dev';
        const domainArea = `domainArea: ${program.domainArea}`;
        const {tag} = record;
        AuthService.walletDeployement(suffixDevProd, domainArea, tag)
            .catch(() => notificationError(t('general.error'), t('error.unknown_error')));
    };

    /** Envoi une demande de deploiement sur Jenkins pour le front */
    const doFrontDeployement = (program, record) => {
        const suffixDevProd = prod(program) ? 'prod' : 'dev';
        AuthService.frontDeployement(suffixDevProd, record.tag)
            .catch(() => notificationError(t('general.error'), t('error.unknown_error')));
    };



/// /////////////////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////                                    /////////////////////////////////
/// ////////////////////////             Methods                /////////////////////////////////
/// ////////////////////////                                    /////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////////////////

    /** Charge les donnes de base des micro-services */
    const loadService = () => {
        const servicesObj = {};
        servicesObj.FRONT = ({
            label: 'FRONT',
            tag: "",
            job: 'gantt-manager-front-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.WALLET = ({
            label: 'WALLET',
            tag: "",
            job: 'gantt-manager-wallet-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.ACTIVITY = ({
            label: 'ACTIVITY',
            tag: "",
            job: 'gantt-manager-activity-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.USER = ({
            label: 'USER',
            tag: "",
            job: 'gantt-manager-user-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.TEAM = ({
            label: 'TEAM',
            tag: "",
            job: 'gantt-manager-team-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.FOLDER = ({
            label: 'FOLDER',
            tag: "",
            job: 'gantt-manager-folder-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.CALENDAR = ({
            label: 'CALENDAR',
            tag: "",
            job: 'gantt-manager-calendar-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        servicesObj.TEMPLATE = ({
            label: 'TEMPLATE',
            tag: "",
            job: 'gantt-manager-template-dpl',
            lastVersion: "",
            version: "",
            tags: []
        });
        return servicesObj;
    }


    /** Count the number of services had the last version
     * and return true all services are updated.
     * @param dataServices : services list.
     * @returns {boolean} return true if all services have the last version available on repository.
     */
    const countUpdateVersion = (dataServices) => Object.values(dataServices)
        .filter(service => service.label !== "FRONT" && service.label !== "WALLET")
        .filter(service => service.version !== "")
        .map(service => service.version === service.lastVersion)
        .filter(boolean => boolean).length

    /** Chargement de la version en cours de tous les micro-services
     *  dans la structure de donnée de maniere asynchrone,
     *  Retourne une liste de promises.  */
    /* eslint-disable no-param-reassign */
    const loadVersions = (program, dataServices) => {
        // Sauvegarde la version et le timestamp de la version.
        const saveVersionAndDate = (serviceName, version) => {
            dataServices[serviceName].version = version;
            const regex = /[0-9]*.[0-9]*.*([0-9]{10})/;
            const matches = dataServices[serviceName].version?.match(regex);
            dataServices[serviceName].timestampVersion = matches ? new Date(+matches[1] * 1000).toUTCString() : null;
        }

       return Object.keys(dataServices).map(serviceName => {
           let promise;
           if (serviceName === 'WALLET')
               promise = findWalletVersion(program).then(version => saveVersionAndDate(serviceName, version))
                    .catch(error => requestError(error, 'Erreur lors de la récupération des versions.'));
            else if (serviceName === 'FRONT')
               promise = findFrontVersion().then(version => saveVersionAndDate(serviceName, version))
                    .catch(error => requestError(error, 'Erreur lors de la récupération des versions.'));
            else
               promise = findVersion(program, serviceName).then(version => saveVersionAndDate(serviceName, version))
                    .catch(error => requestError(error, 'Erreur lors de la récupération des versions.'));
            return promise;
        });
    }


    /** Chargement de l'ensemble des tags disponibles dans le registry de tous les micro-services
     *  dans la structure de donnée de maniere asynchrone.
     *  Retourne une liste de promises.  */
    const loadTags = (program, dataServices) =>
        Object.keys(dataServices).map(serviceName =>
            findTags(program, serviceName).then(tag => { dataServices[serviceName].tags = tag }));



    /** Chargement de la derniere version disponible sur le registry pour un tag spécifique de tous les micro-services
     *  dans la structure de donnée de maniere asynchrone.
     *  Retourne une liste de promises.  */
    const loadLastVersionForTag = (program, dataServices, tag, dataService) => {
        // si record null activer pour tous les services sinon uniquement pour le service passe en parametre
        const listServices = dataService ? {[dataService.label]: dataService} : dataServices;
        // Sauvegarde la version et le timestamp de la version.
        const saveLastVersionAndDate = (serviceName, version) => {
            dataServices[serviceName].tag = tag;
            dataServices[serviceName].lastVersion = version;
            // Tranforme la version en date
            const regex = /[0-9]*.[0-9]*.*([0-9]{10})/;
            const matches = dataServices[serviceName].lastVersion.match(regex);
            dataServices[serviceName].timestampLastVersion = matches ? new Date(+matches[1] * 1000).toUTCString() : null;
        }

        return Object.keys(listServices).map(serviceName =>
                findLastVersion(program, serviceName, tag).then(version => saveLastVersionAndDate(serviceName, version)));
    }


    /**
     * Charge les données pour un programme spécifique.
     * Retourne une liste de promises.
     */
    const loadingVersion = (program) => {
        // Chargement de la structure de donnée initiale.
        if(!dataProgramServices[program.name])
            dataProgramServices[program.name] = { "services" : loadService()};
        const dataServices = dataProgramServices[program.name].services;

        // Chargement des versions
        const promises = [];
        // Chargement de la version courantes
        promises.push(...loadVersions(program,dataServices));
        // Chargement des tags disponibles
        promises.push(...loadTags(program, dataServices));
        // Chargement des versions pour le tag renseigné
        const defaultTag = findDefaultProgramTag(program);
        if (defaultTag) promises.push(...loadLastVersionForTag(program, dataServices, defaultTag));
        return promises;
    }


    /** Charge pour tous les programmes, les données sur les services associé et compte les services a jour */
    const load = async (forceUpdate) => {
        // Mise a jour des programmes non charger ou chargement force
        // Attendre la résolution de toutes les promises pour charger le compteur de mise a jour
        // Retourne sous forme de promises.
       const promises = programList.filter(program => !dataProgramServices[program.name] || forceUpdate)
                                   .map(program => Promise.all(loadingVersion(program, dataProgramServices)).then(
                                                        () => {dataProgramServices[program.name].counter = countUpdateVersion(dataProgramServices[program.name].services) }));
       // Attendre la résolution de tous les promises en attentes avant de mettre a jour la state.
       await Promise.all(promises);
        setDataProgramServices(cloneDeep(dataProgramServices));
    }

    /* eslint-enable no-param-reassign */



    useEffect( () => {
        if(currentUser?.isPlAdmin && programList.length > 0) {
            console.log("🚀 ~ useEffect ~ programList:", programList)
            load(false);
            // Recharge la fenetre toutes les 60 secondes pour voir si le déploiement a modifier la version
            const reloadVersion = setInterval(() => load(true), 60000);
            return () => clearInterval(reloadVersion)
        }
        return ()=>{};
    }, [programList]);

    return (
        <VersionContext.Provider value={{ dataProgramServices, doDeployement, doWalletDeployement, doFrontDeployement, loadLastVersionForTag }}>
            {children}
        </VersionContext.Provider>

    );
}

export { VersionContext, VersionContextProvider };