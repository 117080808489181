import randomstring from 'randomstring';
import axios from './api';


const API_URL = window._env_.REACT_APP_WALLET_API;

const showPrograms = async () => {
    const response = await axios.get(`${API_URL}/program`);

    return response.data;
};

const showAuthorizedPrograms = async () => {
    const response = await axios.get(`${API_URL}/program/authorized`);

    return response.data;
};

const showLogo = async (id) => {
    const response = await axios.get(`${API_URL}/program/${id}/logo`);

    return response.data
};

const getProgram = async (id) => {
    const response = await axios.get(`${API_URL}/program/${id}`);

    return response.data;
};

const createProgram = async (data) => {
    const response = await axios.post(`${API_URL}/program`, data);

    return response.data;
};

const updateProgram = async (id, data) => {
    const response = await axios.put(`${API_URL}/program/${id}/info`, data);

    return response.data;
};

const deleteProgram = async (id) => {
    const response = await axios.delete(`${API_URL}/program/${id}`);

    return response.data;
};


const updateLogo = async (id, data) => {
    const response = await axios.put(`${API_URL}/program/${id}/logo`, data);

    return response.data;
};

const deleteLogo = async (id) => {
    const response = await axios.delete(`${API_URL}/program/${id}/logo`);

    return response.data;
};

// PROGRAM MEMBERS
const getProgramUsers = async (id) => {
    const response = await axios.get(`${API_URL}/program/${id}/members`);

    return response.data;
};

const addUserToProgram = async (id, data) => {
    const response = await axios.put(`${API_URL}/program/${id}/subscribe-user`, data);

    return response.data;
};

const removeUserFromProgram = async (id, data) => {
    const response = await axios.put(`${API_URL}/program/${id}/unsubscribe-user`, data);

    return response.data;
};


// GLOBAL USER MANAGEMENT (Wallet)

const updateProfile = async (profileData) => {
    const response = await axios.put(`${window._env_.REACT_APP_WALLET_API}/sessions/profile`, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        phoneNumber: profileData.phoneNumber,
        company: profileData.company,
        notes: profileData.notes,
    });

    return response.data;
};

const updatePassword = async (passwordData) => {
    const response = await axios.put(`${window._env_.REACT_APP_WALLET_API}/sessions/password`, {
        newPassword1: passwordData.new_password,
        newPassword2: passwordData.new_password_confirmation,
        oldPassword: passwordData.current_password,
    });

    return response.data;
};

const listUsers = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_WALLET_API}/user/all`);
    return response.data;
};

const getUser = async (userId) => {
    const response = await axios.get(`${window._env_.REACT_APP_WALLET_API}/user/${userId}`);
    return response.data;
};

const createUser = async (userData) => {
    const password = `${randomstring.generate({
        length: 8,
        charset: 'alphanumeric',
    })}Gm4$`;
    const response = await axios.post(`${window._env_.REACT_APP_WALLET_API}/user`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        company: userData.company,
        password,
        jobId: userData.job,
        enable: userData.enable,
        readOnly: userData.readOnly
    });

    return response.data;
};

const updateUser = async (userId, userData) => {
    const response = await axios.put(`${window._env_.REACT_APP_WALLET_API}/user/${userId}`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        company: userData.company,
        enable: userData.enable,
        readOnly: userData.readOnly
    });

    return response.data;
};

const initUserJobToProgram = async (url, userId, jobId) => {
    const response = await axios.post(`https://user.${url}/users/user/init-user/${userId}`, { jobId });

    return response.data;
};

const updateUserJob = async (url, userId, jobId) => {
    const response = await axios.put(`https://user.${url}/users/user/${userId}/job`, { jobId });

    return response.data;
};

const deleteUser = async (userId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_WALLET_API}/users/user/${userId}`);

    return response.data;
};

const isPlAdmin = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_WALLET_API}/user/is-pl-admin`);

    return response.data;
};

export default {
    getProgram,
    showAuthorizedPrograms,
    showLogo,
    updateLogo,
    deleteLogo,
    showPrograms,
    createProgram,
    updateProgram,
    deleteProgram,
    getProgramUsers,
    addUserToProgram,
    removeUserFromProgram,
    updateProfile,
    updatePassword,
    listUsers,
    getUser,
    createUser,
    updateUser,
    updateUserJob,
    deleteUser,
    isPlAdmin,
    initUserJobToProgram
};
