import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { Select, Row, Col, Form, Input, Checkbox, DatePicker } from 'antd';
import { SketchPicker } from 'react-color';
import { PlanningContext } from '../../../contexts/app/PlanningContext';

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ColorPicker = styled.div`
    position: absolute;
    right: 1px;
    z-index: 2;
`;



const FieldSetting = ({
    title,
    field,
    colorField,
    showPicker,
    showPickerFunction,
    defaultColor = '#000000',
    isColumn = true,
    datePickerField = null,
    hasDatePicker = false,
    parameters,
    setParameters,
}) => {
    const { planningCustomFields } = useContext(PlanningContext);
    const {t} = useTranslation('translation', {keyPrefix: 'columns'})
    const baseColumnsOptions = [
        { value: 'id', label: t('id') },
        { value: 'description', label: t('description') },
        { value: 'text', label: t('name') },
        { value: 'startDate', label: t('start_date') },
        { value: 'endDate', label: t('end_date') },
        { value: 'duration', label: t('duration') },
        { value: 'duration_unit', label: t('duration_unit') },
        { value: 'calendar', label: t('calendar') },
        { value: 'empty', label: t('empty') },
        { value: 'valid', label: t('state') },
    ];

    const columnOptions = sortBy(baseColumnsOptions.concat(
        planningCustomFields.reduce(
            (agg, customField) => agg.concat({ value: `customField#${customField.id}`, label: customField.name }),
            []
        )
    ), o => o.label);
    return (
        <Row>
            <Col span={14}>{title}</Col>
            <Col span={10} className="flex justify-between">
                {isColumn ? (
                    <Form.Item className="w-2/3">
                        <Select
                            options={columnOptions}
                            value={parameters[field]}
                            className="w-full"
                            onChange={(e) => {
                                setParameters((old) => ({ ...old, [field]: e }));
                            }}
                        />
                    </Form.Item>
                ) : (
                    <p>
                        <Checkbox
                            checked={parameters[field]}
                            className="font-thin"
                            style={{ fontFamily: 'inherit !important' }}
                            onChange={(e) => {
                                setParameters((old) => ({ ...old, [field]: e.target.checked }));
                            }}
                        />
                    </p>
                )}
                {hasDatePicker && (
                    <Form.Item className="w-2/3">
                        <DatePicker
                            allowClear={false}
                            format={ window.dateStringFormat.concat(' HH:mm')
                            }
                            showTime={{ format: 'HH:mm', defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            value={parameters[datePickerField] ? moment(parameters[datePickerField]) : null}
                            onChange={(value) => {
                                setParameters((old) => ({ ...old, [datePickerField]: value }));
                            }}
                            onSelect={(value) => {
                                setParameters((old) => ({ ...old, [datePickerField]: value }));
                            }}
                        />
                    </Form.Item>
                )}
                <Form.Item className="w-1/6">
                    <Input
                        type="color"
                        value={parameters[colorField]}
                        onClick={(event) => {
                            showPickerFunction(true);
                            event.preventDefault();
                        }}
                    />
                    {showPicker && (
                        <ColorPicker>
                            <ColorCover onClick={() => showPickerFunction(false)} />
                            <SketchPicker
                                color={parameters[colorField] ?? defaultColor}
                                onChangeComplete={(color) => {
                                    setParameters((old) => ({ ...old, [colorField]: color.hex }));
                                }}
                                disableAlpha
                            />
                        </ColorPicker>
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
};
export default FieldSetting;
