import GanttConfigurations from '../components/plannings/gantt_config';

const { scaleConfigs } = GanttConfigurations;

export const applyConfig = (config, dates) => {
    window.ganttInstance.templates.date_scale = config.scales[0].date ? null : config.scales[0].template;
    window.ganttInstance.config.scales = config.scales;
    

    if (dates && dates.start_date) {
        // window.ganttInstance.config.start_date = window.ganttInstance.date.add(
        //     dates.start_date,
        //     -1,
        //     config.scales[0].subscale_unit
        // );
        window.ganttInstance.config.start_date = dates.start_date;
        window.ganttInstance.config.end_date = dates.end_date;
        // window.ganttInstance.config.end_date = window.ganttInstance.date.add(
        //     window.ganttInstance.date[`${config.scales[0].subscale_unit}_start`](dates.end_date),
        //     1,
        //     config.scales[0].subscale_unit
        // );
    } else {
        window.ganttInstance.config.start_date = null;
        window.ganttInstance.config.end_date = null;
    }
};

const getUnitsBetween = (from, to, unit, step) => {
    let start = new Date(from);
    const end = new Date(to);
    let units = 0;

    while (start.valueOf() < end.valueOf()) {
        units += 1;
        start = window.ganttInstance.date.add(start, step, unit);
    }

    return units;
};

// eslint-disable-next-line import/prefer-default-export
export const zoomGanttToFit = () => {
    const project = window.ganttInstance.getSubtaskDates();
    const areaWidth = window.ganttInstance.$task.offsetWidth;
    let scaleLevel;
    const scales = scaleConfigs.map((i) => {
        const obj = {scales: i.scales}
        return obj;
    });
    for (scaleLevel = 0; scaleLevel < scales.length; scaleLevel += 1) {
        const columnCount = getUnitsBetween(
            project.start_date,
            project.end_date,
            scales[scaleLevel].scales[0].subscale_unit,
            scales[scaleLevel].scales[0].step
        );

        if ((columnCount + 2) * window.ganttInstance.config.min_column_width <= areaWidth) {
            break;
        }
    }

    if (scaleLevel === scales.length) {
        scaleLevel -= 1;
    }
    const hasChanged = window.ganttInstance.config.scaleName !== scaleConfigs[scaleLevel].name;
    if (hasChanged) {
        applyConfig(scales[scaleLevel], project);
        window.ganttInstance.config.scaleName = scaleConfigs[scaleLevel].name;
        window.ganttInstance.render();
    }
    return {scaleLevel, hasChanged};
};
